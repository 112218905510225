/** Source of truth for layouts that are used throughout the application */

export const layout = {
	navBar: {
		border: 1,
		height: 75,
	},
	optsBar: {
		height: 45,
	},
	streamNav: {
		height: 64,
	},
};

export default layout;
