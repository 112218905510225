import { lazy } from "react";
import { Route, Routes } from "react-router-dom";
import AuthOutlet from "router/AuthOutlet";
import NotFound from "routes/404/Index";
import Authenticate from "routes/authenticate/Index";
import ChangeEmail from "routes/change_email/Index";
import ChooseAccount from "routes/choose_account/Index";
import Home from "routes/home/Index";
import NoTabs from "routes/home/NoTabs";
import ResetPassword from "routes/reset_password/Index";
import Settings from "routes/settings/Index";
import SettingsOutlet from "routes/settings/SettingsOutlet";
import AccountSettings from "routes/settings/account/Index";
import AssetSettings from "routes/settings/account/assets/Index";
import EverbridgeIntegration from "routes/settings/account/integrations/everbridge/Index";
import EverbridgeIntegrationCreate from "routes/settings/account/integrations/everbridge/create/Index";
import ProfileSettings from "routes/settings/profile/Index";
import Signin from "routes/signin/Index";
import SigninSSO from "routes/signin/sso/Index";
import Signup from "routes/signup/:verificationCode/Index";
import Unauthorized from "routes/unauthorized/Index";
import Snackbar from "ui-library/Snackbar";
import { withErrorBoundary } from "utils/ErrorBoundary";
import { useOfflineSnackbar } from "utils/useSnackbar";
import { useTimezone } from "utils/useTimezone";
import init from "../init";
import GlobalFetchingIndicator from "./GlobalFetchingIndicator";

const Alerts = lazy(() => import("routes/alerts/:tabKey/Index"));
const AlertSettings = lazy(() => import("routes/settings/alerts/Index"));
const APISettings = lazy(() => import("routes/settings/account/api/Index"));
const CollectionsSettings = lazy(
	() => import("routes/settings/account/assets/collections/Index")
);
const EverbridgeIntegrationEdit = lazy(
	() =>
		import(
			"routes/settings/account/integrations/everbridge/:integrationId/Index"
		)
);
const APIKeyEdit = lazy(
	() => import("routes/settings/account/api/:apiKeyId/Index")
);
const IntegrationsSettings = lazy(
	() => import("routes/settings/account/integrations/Index")
);
const MembersSettings = lazy(
	() => import("routes/settings/account/members/Index")
);
const SecuritySettings = lazy(
	() => import("routes/settings/account/security/Index")
);
const StreamBuilder = lazy(() => import("routes/streams/:streamId/Index"));
const StreamManager = lazy(() => import("routes/streams/Index"));
const Incident = lazy(() => import("routes/incident/:incidentId/Index"));
const TravelIntegration = lazy(
	() => import("routes/settings/account/integrations/travel/Index")
);
const Slack = lazy(
	() => import("routes/settings/account/integrations/slack/Index")
);
const MsTeams = lazy(
	() => import("routes/settings/account/integrations/ms_teams/Index")
);
const Resolver = lazy(
	() => import("routes/settings/account/integrations/resolver/Index")
);
const Salesforce = lazy(() =>
	import("routes/settings/account/integrations/salesforce/Index").then(
		(module) => ({ default: module.Index })
	)
);
const Webhook = lazy(
	() => import("routes/settings/account/integrations/webhook/Index")
);
const Hr = lazy(() => import("routes/settings/account/integrations/hr/Index"));
const Analytics = lazy(() => import("routes/analytics/Index"));
const IncidentResponseNotification = lazy(
	() => import("routes/irn/:uuid/Index")
);
const PrivateIncident = lazy(() => import("routes/private_incidents/Index"));

init();

const Index = () => {
	useOfflineSnackbar();
	useTimezone();

	return (
		<>
			<Routes>
				{/* unauthorized requires auth but isn't rendered into the home outlet */}
				<Route element={<Unauthorized />} path="unauthorized" />
				<Route element={<ChangeEmail />} path="change_email" />
				<Route element={<IncidentResponseNotification />} path="irn/:uuid" />
				{/* everything nested in the '/' route requires authorization */}
				<Route element={<Home />} path="/">
					{SignedInRoutes()}
					{/* allows for `u/:userAccountIndex` routes for multiple user accounts */}
					<Route path="u/:userAccountIndex">{SignedInRoutes()}</Route>
				</Route>
				<Route element={<AuthOutlet />} path="/">
					<Route element={<Signin />} path="signin" />
					<Route element={<SigninSSO />} path="signin/sso" />
					<Route element={<ResetPassword />} path="reset_password" />
					<Route element={<ChooseAccount />} path="choose_account" />
					<Route element={<Authenticate />} path="authenticate" />
					<Route element={<Signup />} path="signup/:verificationCode" />
				</Route>

				{/* Route Not Found -> show 404 page */}
				<Route element={<NotFound />} path="*" />
			</Routes>

			<Snackbar />
			<GlobalFetchingIndicator />
		</>
	);
};

export default withErrorBoundary(Index);

const SignedInRoutes = () => (
	<>
		<Route element={<NoTabs />} path="" />
		<Route element={<SettingsOutlet />} path="settings">
			<Route element={<Settings />} index />
			<Route path="account">
				<Route element={<AccountSettings />} index />
				<Route element={<APISettings />} path="api" />
				<Route element={<APIKeyEdit />} path="api/:apiKeyId" />
				<Route path="assets">
					<Route element={<AssetSettings />} index />
					<Route element={<CollectionsSettings />} path="collections" />
					<Route
						element={<CollectionsSettings />}
						path="collections/:collectionId"
					/>
				</Route>
				<Route path="integrations">
					<Route element={<IntegrationsSettings />} index />
					<Route element={<EverbridgeIntegration />} path="everbridge" />
					<Route
						element={<EverbridgeIntegrationCreate />}
						path="everbridge/create"
					/>
					<Route
						element={<EverbridgeIntegrationEdit />}
						path="everbridge/:integrationId"
					/>
					<Route element={<TravelIntegration />} path="travel" />
					<Route element={<Slack />} path="slack" />
					<Route element={<MsTeams />} path="msteams" />
					<Route element={<Resolver />} path="resolver" />
					<Route element={<Salesforce />} path="salesforce" />
					<Route element={<Webhook />} path="webhook" />
					<Route element={<Hr />} path="hr" />
				</Route>
				<Route element={<MembersSettings />} path="members" />
				<Route element={<SecuritySettings />} path="security" />
			</Route>
			<Route element={<AlertSettings />} path="alerts" />
			<Route path="profile">
				<Route element={<ProfileSettings />} index />
			</Route>
		</Route>
		<Route element={<Alerts />} path="alerts/:tabKey" />
		<Route element={<Incident />} path="incident/:incidentId" />
		<Route element={<StreamManager />} path="streams" />
		<Route element={<StreamBuilder />} path="streams/:streamId" />
		<Route element={<Analytics />} path="analytics" />
		<Route element={<PrivateIncident />} path="private_incidents" />
	</>
);
