import useAccountSettings from "utils/useAccountSettings";
import type { Flags } from "./types";

export function useHasFeature(flags: Flags[]): boolean[];
export function useHasFeature(flag: Flags): boolean;
export function useHasFeature(flags: Flags | Flags[]): boolean | boolean[] {
	const { flags: accountFlags } = useAccountSettings();

	if (flags instanceof Array) {
		return flags.map((flag) =>
			accountFlags.some(({ key, value }) => flag === key && value === "true")
		);
	}

	return accountFlags.some(
		({ key, value }) => flags === key && value === "true"
	);
}
