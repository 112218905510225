import SamHeadlineMediumWoff2 from "fonts/sam-headline-medium.woff2";
import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
    @font-face {
        font-family: 'SamFont';
        src: local('SamFont'), local('SamFont'),
        url(${SamHeadlineMediumWoff2}) format('woff2');
        font-weight: 400;
        font-style: normal;
    }
`;
