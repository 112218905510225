import { Stack, Typography } from "@mui/material";
import SettingsPageTitle from "ui-library/SettingsPageTitle";

import useCreateDeletePersonRequest from "api/mutations/useCreateDeletePersonRequest";
import { useGetPerson } from "api/queries/useGetPerson";
import CenteredCircularProgress from "ui-library/CenteredCircularProgress";
import EmptyStateBox from "ui-library/EmptyStateBox";
import useSnackbar from "utils/useSnackbar";
import Breadcrumbs from "../Breadcrumbs";
import DeleteProfile from "./DeleteProfile";
import { UpdateEmail } from "./UpdateEmail";
import { UpdateProfile } from "./UpdateProfile";
import { sectionTitleSx } from "./common.styles";
import dayjs from "dayjs";
import useGetUser from "api/queries/useGetUser";

const Index = () => {
	const { data: person, isLoading, isSuccess } = useGetPerson();
	const { data: user } = useGetUser();

	const { mutateAsync: createDeletePersonRequest } =
		useCreateDeletePersonRequest();

	const { handleError, handleSuccess } = useSnackbar();

	const handleCreateDeletePersonRequest = () => {
		createDeletePersonRequest()
			.then((variables) => {
				if (variables.error) {
					handleError(variables.error);
				} else if (variables.success) {
					handleSuccess("A request has been sent to delete your account.");
				}
			})
			.catch((error) => handleError(error));
	};

	if (isLoading) {
		return (
			<>
				<Breadcrumbs />
				<CenteredCircularProgress />
			</>
		);
	}

	if (isSuccess && person) {
		return (
			<>
				<Breadcrumbs />
				<SettingsPageTitle>Profile Settings</SettingsPageTitle>
				<Typography sx={descriptionSx}>
					Review and edit your profile information and data associated with your
					account.
				</Typography>

				<Stack direction="row" spacing={1.5} alignItems="center">
					<Typography sx={sectionTitleSx}>Date Joined:</Typography>
					<Typography>{dayjs(user?.created).format("MMMM D, YYYY")}</Typography>
				</Stack>

				<UpdateEmail person={person} />
				<UpdateProfile person={person} />

				<DeleteProfile
					handleCreateDeletePersonRequest={handleCreateDeletePersonRequest}
				/>
			</>
		);
	}

	return (
		<>
			<Breadcrumbs />
			<EmptyStateBox
				headerMessage="We were unable to load your profile settings"
				subMessage="Please refresh to try again"
			/>
		</>
	);
};

export default Index;

/** Style */

export const descriptionSx = {
	color: "text.secondary",
	marginBottom: "1.5rem",
};
