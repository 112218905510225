import { Box, Button, Tooltip } from "@mui/material";

interface Props {
	action: () => void;
	disabled: boolean;
	label: string;
	tooltipMessage: string;
}

const EverbridgeIntegrationButton = ({
	action,
	disabled,
	label,
	tooltipMessage,
}: Props) => {
	return (
		<Tooltip
			disableHoverListener={!disabled}
			disableFocusListener={!disabled}
			disableTouchListener={!disabled}
			placement="top"
			title={tooltipMessage}
		>
			<Box sx={everbridgeIntegrationButtonBoxSx}>
				<Button
					disabled={disabled}
					onClick={action}
					sx={everbridgeIntegrationButtonSx}
					variant="outlined"
				>
					{label}
				</Button>
			</Box>
		</Tooltip>
	);
};

export default EverbridgeIntegrationButton;

/** Styles */

const everbridgeIntegrationButtonSx = {
	alignSelf: "end",
	marginTop: "2rem",
	width: "fit-content",
};

const everbridgeIntegrationButtonBoxSx = {
	alignSelf: "end",
	width: "fit-content",
};
