import useGetDashboard from "api/queries/useGetDashboard";
import { useLocation, useParams } from "react-router-dom";
import { getTabPath } from "utils/tabUtils";
import useNavigateWithAccount from "utils/useNavigateWithAccount";

const useNavigateAfterTabDelete = () => {
	const { data: dashboard } = useGetDashboard();

	const navigate = useNavigateWithAccount();
	const { pathname } = useLocation();
	const { tabKey, incidentId } = useParams();

	const navigateAfterTabDelete = (deletedTabKey: string) => {
		if (!dashboard) return;
		/** the index of the tab being deleted (doesn't have to be selected) */
		const deletedTabIndex = dashboard.tabs.findIndex(
			(tab) => tab.key === deletedTabKey
		);
		/** total amount of tabs before deleting this tab */
		const tabCount = dashboard.tabs.length;
		const deletedTab = dashboard.tabs[deletedTabIndex];

		const deletingSelectedTab =
			(deletedTab.__typename === "SettingsTab" &&
				pathname.includes("settings")) ||
			(deletedTab.__typename === "StreamManagerTab" &&
				pathname.includes("streams")) ||
			(deletedTab.__typename === "AlertsTab" && deletedTab.key === tabKey) ||
			(deletedTab.__typename === "IncidentTab" &&
				deletedTab.incident_tab_data.incident_id === incidentId);
		const deletingOnlyTab = tabCount === 1;
		const isLastTab = deletedTabIndex + 1 === tabCount;

		if (deletingOnlyTab) {
			/** go back to home (and the no tabs screen) */
			navigate("/", { replace: true });
		} else if (deletingSelectedTab && isLastTab) {
			/** if deleting selected tab that is last, then change to the left of it */
			navigate(getTabPath(dashboard?.tabs[deletedTabIndex - 1]), {
				replace: true,
			});
		} else if (deletingSelectedTab && !isLastTab) {
			/** if deleting selected tab that isn't last, then go to the next tab to the right */
			navigate(getTabPath(dashboard?.tabs[deletedTabIndex + 1]), {
				replace: true,
			});
		}
	};

	return navigateAfterTabDelete;
};

export default useNavigateAfterTabDelete;
