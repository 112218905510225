import { AccountPasswordPolicy } from "gql/graphql";
import { Dispatch, SetStateAction, useEffect, useState } from "react";

/**
 * a hook to use for the state of password inputs.
 * It has standard data and setter, but also return an error message and passwordValidationData.
 * If you pass the optional primaryPassword Param then the passwordError will just be about if the passwords match
 *
 * @param passwordPolicy the accounts password policy (minimum password requirements)
 * @param primaryPassword optional param that is used only when this hook is used for the confirm password state
 * @returns an array [password, setPassword, passwordError, passwordValidationDetails]
 */
const usePasswordInputState = (
	passwordPolicy: Partial<AccountPasswordPolicy> | undefined,
	primaryPassword?: string
): [
	string,
	Dispatch<SetStateAction<string>>,
	string,
	Record<string, boolean> | null,
] => {
	const [password, setPassword] = useState("");
	const [passwordError, setPasswordError] = useState("");
	const [passwordValidationDetails, setPasswordValidationDetails] =
		useState<Record<string, boolean> | null>(null);

	/** Primary password only: Whenever the input is changed check against password policy to see if it is still valid, if there's no password, don't check */
	useEffect(() => {
		if (!passwordPolicy || primaryPassword) return;

		if (password) {
			setPasswordValidationDetails(isValidPassword(password, passwordPolicy));
		} else {
			setPasswordValidationDetails(null);
			setPasswordError("");
		}
	}, [password, passwordPolicy, primaryPassword]);

	/** Primary password only: check values of validatePassword and set an error if they're not all met */
	useEffect(() => {
		if (!passwordValidationDetails || primaryPassword) return;

		if (
			Object.values(passwordValidationDetails).every(
				(policy) => policy === true
			)
		) {
			setPasswordError("");
		} else setPasswordError("Please enter a valid password.");
	}, [passwordValidationDetails, primaryPassword]);

	/** Confirm password only: check if password values are equal and add helper text if they're not. */
	useEffect(() => {
		if (!primaryPassword) return;

		if (!password) setPasswordError("");
		else if (password !== primaryPassword) {
			setPasswordError("Your passwords must match");
		} else if (password === primaryPassword) setPasswordError("");
	}, [password, primaryPassword]);

	return [password, setPassword, passwordError, passwordValidationDetails];
};

export default usePasswordInputState;

/**
 * @param password - the password (string) to be validated
 * @param passwordPolicy - password policy object defined in account security settings
 * @returns an object containing each validation criterion against the password string as defined in the policy
 */
const isValidPassword = (
	password: string,
	passwordPolicy: Partial<AccountPasswordPolicy> | undefined
) => {
	if (!passwordPolicy) return null;

	const isMinLength = new RegExp(`^.{${passwordPolicy.min_length},}$`);
	const hasUppercase = /[A-Z]/;
	const hasLowercase = /[a-z]/;
	const hasNumber = /[0-9]/;
	const hasSpecial = /[ `~!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/;

	/** Conditionally return validation properties based on password policy */
	return {
		...(passwordPolicy?.min_length && {
			isMinLength: isMinLength.test(password),
		}),
		...(passwordPolicy?.require_uppercase && {
			hasUppercase: hasUppercase.test(password),
		}),
		...(passwordPolicy?.require_lowercase && {
			hasLowercase: hasLowercase.test(password),
		}),
		...(passwordPolicy?.require_number && {
			hasNumber: hasNumber.test(password),
		}),
		...(passwordPolicy?.require_special && {
			hasSpecial: hasSpecial.test(password),
		}),
	};
};
