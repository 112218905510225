import { useParams } from "react-router-dom";
import CenteredCircularProgress from "ui-library/CenteredCircularProgress";
import { ExistingUserJoinAccount } from "./ExistingUserJoinAccount";
import { NewUserSignup } from "./NewUserSignup";
import { useController } from "./useController";

interface Props {
	verificationCode: string;
}

const Index = ({ verificationCode }: Props) => {
	const {
		accountName,
		confirmPassword,
		confirmPasswordError,
		disableSignup,
		email,
		existingUser,
		handleJoinAccount,
		handleSignup,
		isInitialLoadingAccountInvitation,
		name,
		password,
		passwordError,
		passwordPolicy,
		passwordValidationDetails,
		setConfirmPassword,
		setPassword,
		setName,
		submitError,
		userName,
		samlIssuer,
	} = useController(verificationCode);

	if (isInitialLoadingAccountInvitation) return <CenteredCircularProgress />;

	if (existingUser) {
		return (
			<ExistingUserJoinAccount
				accountName={accountName}
				email={email}
				handleJoinAccount={handleJoinAccount}
				password={password}
				passwordError={passwordError}
				setPassword={setPassword}
				submitError={submitError}
				userName={userName}
			/>
		);
	} else {
		return (
			<NewUserSignup
				accountName={accountName}
				confirmPassword={confirmPassword}
				confirmPasswordError={confirmPasswordError}
				disableSignup={disableSignup}
				email={email}
				handleSignup={handleSignup}
				name={name}
				password={password}
				passwordError={passwordError}
				passwordPolicy={passwordPolicy}
				passwordValidationDetails={passwordValidationDetails}
				setConfirmPassword={setConfirmPassword}
				setName={setName}
				setPassword={setPassword}
				submitError={submitError}
				verificationCode={verificationCode}
				samlIssuer={samlIssuer ?? undefined}
			/>
		);
	}
};

const ParamInjector = () => {
	const { verificationCode } = useParams();

	if (!verificationCode) return null;
	return <Index key={verificationCode} verificationCode={verificationCode} />;
};

export default ParamInjector;
