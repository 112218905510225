// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
	--timing-quick: 100ms;
	--timing-base: 200ms;
	--timing-slow: 300ms;
	--timing-slower: 400ms;
	--timing-slowest: 500ms;
	--timing-loading-base: 1000ms;
	--timing-loading-slow: 2000ms;
}
`, "",{"version":3,"sources":["webpack://./../../packages/design/src/timings.css"],"names":[],"mappings":"AAAA;CACC,qBAAqB;CACrB,oBAAoB;CACpB,oBAAoB;CACpB,sBAAsB;CACtB,uBAAuB;CACvB,6BAA6B;CAC7B,6BAA6B;AAC9B","sourcesContent":[":root {\n\t--timing-quick: 100ms;\n\t--timing-base: 200ms;\n\t--timing-slow: 300ms;\n\t--timing-slower: 400ms;\n\t--timing-slowest: 500ms;\n\t--timing-loading-base: 1000ms;\n\t--timing-loading-slow: 2000ms;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
