import { Box, CircularProgress, Tabs } from "@mui/material";
import useGetDashboard from "api/queries/useGetDashboard";
import layout from "common/layoutDefinitions";
import { useLocation } from "react-router-dom";
import { withErrorBoundary } from "utils/ErrorBoundary";
import { getTabPath } from "utils/tabUtils";

import SeeAllTab from "./SeeAllTab";
import Tab from "./Tab";
import useManageDeepLinkedTabs from "./useManageDeepLinkedTabs";

interface Props {
	isOverflowComponent?: boolean;
	visibleTabs: number;
}

const NavTabs = ({ isOverflowComponent, visibleTabs }: Props) => {
	const { pathname } = useLocation();

	const { data: dashboard, isLoading: isLoadingDashboard } = useGetDashboard();

	useManageDeepLinkedTabs();

	if (isLoadingDashboard) return <CircularProgress />;

	/**
	 * resolves to:
	 * -1 -> when there are no tabs in the dashboard
	 * 0 or higher -> when there are tabs and one is currently active (based on url)
	 * 0 -> fallback default when there are tabs on the dashboard and nothing is set as current (based on url)
	 */
	const currentTabIndex =
		dashboard?.tabs.findIndex((tab) =>
			pathname.includes(`/${getTabPath(tab)}`)
		) || 0;
	const noTabs = currentTabIndex === -1;
	const selectedTabInOverflowTabs = currentTabIndex + 1 > visibleTabs;

	const getOverflowSelectedTab = () => {
		if (noTabs) return false;
		else return currentTabIndex;
	};

	const getSelectedTab = () => {
		if (noTabs || selectedTabInOverflowTabs) return false;
		else return currentTabIndex;
	};

	/**
	 * Tabs value must be an index since we are using a customized tab component. MUI Tabs cannot read property of children tabs
	 * unless they are a direct child of the Tabs component (in our case they are nested within multiple elements).
	 */
	return (
		<Box component="nav" sx={outerNavTabsSx}>
			{dashboard && (
				<Tabs
					TabIndicatorProps={tabIndicatorProps}
					orientation={isOverflowComponent ? "vertical" : "horizontal"}
					sx={tabContainerSx(!!isOverflowComponent)}
					value={
						isOverflowComponent ? getOverflowSelectedTab() : getSelectedTab()
					}
				>
					{dashboard.tabs.map((tab, index) => {
						/** On the NavBar, only show tabs that are visible based on the screen width  */
						if (!isOverflowComponent && index > visibleTabs - 1) return null;
						else {
							return (
								<Tab
									isCurrentTab={currentTabIndex === index}
									isOverflowComponent={isOverflowComponent}
									key={tab.key}
									tab={tab}
								/>
							);
						}
					})}
				</Tabs>
			)}
			{!isOverflowComponent && (
				<SeeAllTab
					highlightTab={selectedTabInOverflowTabs}
					visibleTabs={visibleTabs}
				/>
			)}
		</Box>
	);
};

export default withErrorBoundary(NavTabs);

/** Styles */

const outerNavTabsSx = {
	display: "flex",
	flexDirection: "row",
};

const tabContainerSx = (isOverflowComponent: boolean) => ({
	"& .MuiTabs-flexContainer": {
		alignItems: "flex-end",
		height: isOverflowComponent ? "fit-content" : "60px",
	},
	marginBottom: isOverflowComponent ? 0 : `-${layout.navBar.border}px`,
});

const tabIndicatorProps = {
	style: { display: "none" },
};
