import useSignout from "api/mutations/useSignout";
import { useGetPerson } from "api/queries/useGetPerson";
import { partialAccountFieldsFragment } from "gql/graphql";
import { MouseEvent } from "react";
import { useNavigate } from "react-router-dom";
import useRequiresSignin from "utils/useRequiresSignin";
import useSnackbar from "utils/useSnackbar";

const useController = () => {
	useRequiresSignin();

	const { data: person } = useGetPerson();
	const { mutateAsync: signout } = useSignout();

	const navigate = useNavigate();
	const { handleError } = useSnackbar();

	const handleAccountSelect = (
		e: MouseEvent<HTMLButtonElement>,
		partialAccount: partialAccountFieldsFragment
	) => {
		e.preventDefault();
		if (person?.mfaAuthenticated || !partialAccount.mfaRequired) {
			if (partialAccount.index === 0) {
				navigate("/");
			} else {
				window.location.assign(
					`${window.location.origin}/u/${partialAccount.index}`
				);
			}
		} else {
			navigate(`/authenticate?return_url=/u/${partialAccount.index}`);
		}
	};

	const handleSignout = () => {
		signout()
			.then((variables) => {
				if (variables.success) {
					navigate("/signin");
				} else if (variables.error) {
					handleError(variables.error);
				}
			})
			.catch((error) => handleError(error));
	};

	return { handleAccountSelect, handleSignout, person };
};

export default useController;
