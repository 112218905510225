import useCreateMFACode from "api/mutations/useCreateMFACode";
import useSubmitMFACode from "api/mutations/useSubmitMFACode";
import { useGetPerson } from "api/queries/useGetPerson";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import useRequiresSignin from "utils/useRequiresSignin";
import useSnackbar from "utils/useSnackbar";

const useController = () => {
	const { data: person } = useGetPerson();
	const [code, setCode] = useState("");

	const { mutateAsync: createMFACode } = useCreateMFACode();
	const { mutateAsync: submitMFACode } = useSubmitMFACode();

	useRequiresSignin();
	const [searchParams] = useSearchParams();
	const redirectUrl = searchParams.get("return_url") || "/";
	const navigate = useNavigate();
	const { handleError } = useSnackbar();

	/** send an mfa code by email if user lands on this page and they aren't mfa authenticated  */
	useEffect(() => {
		if (person && !person.mfaAuthenticated) {
			const controller = new AbortController();
			createMFACode(controller).catch((error) => handleError(error));

			return () => controller.abort();
		}
	}, [createMFACode, handleError, person]);

	/** force user forward once they have their mfa authenticated (deep links and successful submits) */
	useEffect(() => {
		if (person?.mfaAuthenticated) navigate(redirectUrl);
	}, [navigate, person?.mfaAuthenticated, redirectUrl]);

	return { code, setCode, submitMFACode };
};

export default useController;
