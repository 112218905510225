import { Box, Card, Grid, Typography } from "@mui/material";
import { ReactNode } from "react";
import { Link } from "react-router-dom";
import theme from "theme";

interface Props {
	content: string;
	destination?: string;
	heading: string;
	icon: ReactNode;
	onClick?: () => void;
	width?: number;
}

const SettingsCard = ({
	content,
	destination,
	heading,
	icon,
	onClick,
	width,
}: Props) => {
	return (
		<Grid item xs="auto">
			<Link onClick={onClick} style={wrapperSx} to={destination || ""}>
				<Card sx={cardSx(width)} variant="outlined">
					<Box sx={iconWrapperSx}>{icon}</Box>
					<Box sx={textWrapperSx}>
						<Typography sx={titleSx}>{heading}</Typography>
						<Typography sx={descriptionSx}>{content}</Typography>
					</Box>
				</Card>
			</Link>
		</Grid>
	);
};

export default SettingsCard;

/** STYLE */

const cardSx = (width?: number) => ({
	"&:hover": {
		borderColor: theme.palette.grey[100],
		transition: "border-color 0.3s",
	},
	background: "transparent",
	borderRadius: " 8px",
	display: "flex",
	height: "130px",
	marginBottom: "1rem",
	transition: "border-color 0.3s",

	width: width ? `${width}px` : "350px",
});

const descriptionSx = {
	color: "text.secondary",
	lineHeight: 1.25,
	marginTop: "0.5rem",
};

const iconWrapperSx = {
	alignItems: "center",
	display: "flex",
	justifyContent: "center",
	margin: "1.5rem",
	stroke: theme.palette.background.default,
	strokeWidth: 1,
	svg: {
		fontSize: 36,
	},

	width: "42px",
};

const textWrapperSx = {
	display: "flex",
	flexDirection: "column",
	padding: "1.5rem 1rem",
};

const titleSx = {
	fontWeight: 600,
};

const wrapperSx = {
	textDecoration: "inherit",
};
