// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
	--shadow-low: 0px var(--space-minuscule) var(--space-smallest)
			rgba(0, 0, 0, 0.3),
		0px 0px var(--space-smallest) rgba(0, 0, 0, 0.1);
	--shadow-base: 0px var(--space-minuscule) var(--space-smaller) 0px
			rgba(0, 0, 0, 0.3),
		0px var(--space-smaller) 12px 0px rgba(0, 0, 0, 0.1);
	--shadow-high: 0px var(--space-base) var(--space-base) 0px rgba(0, 0, 0, 0.3),
		0px 0px var(--space-small) 0px rgba(0, 0, 0, 0.1);
}
`, "",{"version":3,"sources":["webpack://./../../packages/design/src/shadows.css"],"names":[],"mappings":"AAAA;CACC;;kDAEiD;CACjD;;sDAEqD;CACrD;mDACkD;AACnD","sourcesContent":[":root {\n\t--shadow-low: 0px var(--space-minuscule) var(--space-smallest)\n\t\t\trgba(0, 0, 0, 0.3),\n\t\t0px 0px var(--space-smallest) rgba(0, 0, 0, 0.1);\n\t--shadow-base: 0px var(--space-minuscule) var(--space-smaller) 0px\n\t\t\trgba(0, 0, 0, 0.3),\n\t\t0px var(--space-smaller) 12px 0px rgba(0, 0, 0, 0.1);\n\t--shadow-high: 0px var(--space-base) var(--space-base) 0px rgba(0, 0, 0, 0.3),\n\t\t0px 0px var(--space-small) 0px rgba(0, 0, 0, 0.1);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
