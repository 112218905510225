import {
	Button,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from "@mui/material";
import useDeleteProximityAssetSet from "api/mutations/useDeleteProximityAssetSet";
import { ProximityAssetSet } from "autoGenSubTypes";
import dayjs from "dayjs";
import { FormatDistance } from "ui-library/FormatDistance";
import useNavigateWithAccount from "utils/useNavigateWithAccount";
import useSnackbar from "utils/useSnackbar";
import { showPrompt } from "@samdesk/components/Prompt";

type CollectionsTableParams = {
	proximityAssetSets: NonNullable<ProximityAssetSet>[];
};

const CollectionsTable = (params: CollectionsTableParams) => {
	const { mutateAsync: deleteProximityAssetSet } = useDeleteProximityAssetSet();

	const navigate = useNavigateWithAccount();
	const { handleError } = useSnackbar();

	const handleDeleteClicked = async (collectionId: string) => {
		const { state } = await showPrompt({
			promptType: "confirm",
			title: `Confirm Delete`,
			description: `Are you sure you want to delete this asset collection?`,
			confirmButton: { label: "Delete" },
		});

		if (state === "ok") {
			deleteProximityAssetSet({ _id: collectionId }).catch((error) =>
				handleError(error)
			);
		}
	};

	return (
		<>
			<TableContainer component={Paper}>
				<Table aria-label="simple table">
					<TableHead>
						<TableRow>
							<TableCell>Name</TableCell>
							<TableCell>Proximity</TableCell>
							<TableCell>Asset Count</TableCell>
							<TableCell>Created Date</TableCell>
							<TableCell>Updated Date</TableCell>
							<TableCell />
							<TableCell />
						</TableRow>
					</TableHead>
					<TableBody>
						{params.proximityAssetSets.map((collection) => (
							<TableRow key={collection._id} sx={tableBodyRowSx}>
								<TableCell component="th" scope="row">
									{collection.name}
								</TableCell>
								<TableCell>
									<FormatDistance value={collection.proximity} />
								</TableCell>
								<TableCell>{collection.asset_count}</TableCell>
								<TableCell>
									{dayjs(collection.created).format("MM/DD/YYYY")}
								</TableCell>
								<TableCell>
									{dayjs(collection.updated).format("MM/DD/YYYY")}
								</TableCell>
								<TableCell>
									<Button
										color="secondary"
										onClick={() =>
											navigate(
												`settings/account/assets/collections/${collection._id}`
											)
										}
										variant="outlined"
									>
										Edit
									</Button>
								</TableCell>
								<TableCell>
									<Button
										onClick={() => handleDeleteClicked(collection._id)}
										color="secondary"
										variant="warning"
									>
										Delete
									</Button>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		</>
	);
};

export default CollectionsTable;

/** Styles */

const tableBodyRowSx = {
	"&:last-child td, &:last-child th": { border: 0 },
};
