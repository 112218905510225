import { useGetPerson } from "api/queries/useGetPerson";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

/**
 * A custom hook that checkes if you're signed in
 * If you're not, it will navigate you to /signin
 * use case is for routes that you should be able to view only if you're signed in
 */
const useRequiresSignin = () => {
	const { isError } = useGetPerson();
	const navigate = useNavigate();

	useEffect(() => {
		if (isError) navigate("/signin");
	}, [isError, navigate]);
};

export default useRequiresSignin;
