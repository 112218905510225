export const isExternalLink = (urlString: string | undefined) => {
	if (urlString === undefined) return false;

	try {
		const url = new URL(urlString);

		if (
			url.origin === window.SAM._env_.REACT_APP_V2_BASE_URL ||
			url.hostname === "localhost"
		) {
			return false;
		}
	} catch (error) {
		return false;
	}

	return true;
};
