import { Check, Close } from "@mui/icons-material";
import { Typography } from "@mui/material";
import { AccountPasswordPolicy } from "gql/graphql";
import theme from "theme";

interface Props {
	newPassword: string;
	passwordPolicy: AccountPasswordPolicy | undefined;
	passwordValidationDetails: Record<string, boolean> | null;
}

export const PasswordCriteria = ({
	newPassword,
	passwordPolicy,
	passwordValidationDetails,
}: Props) => {
	return (
		<>
			<Typography color="text.secondary">
				Please choose a new password that satisfies the following criteria:
			</Typography>
			<PasswordCriteriaList
				newPassword={newPassword}
				passwordPolicy={passwordPolicy}
				passwordValidationDetails={passwordValidationDetails}
			/>
		</>
	);
};

export const PasswordCriteriaList = ({
	newPassword,
	passwordPolicy,
	passwordValidationDetails,
}: Props) => {
	const {
		min_length,
		require_lowercase,
		require_uppercase,
		require_number,
		require_special,
	} = passwordPolicy || {};
	const validIcon = <Check color={"success"} fontSize="small" sx={iconSx} />;
	const invalidIcon = <Close color={"error"} fontSize="small" sx={iconSx} />;

	return (
		<ul style={wrapperSx}>
			{min_length && (
				<li>
					Minimum length of {min_length} characters
					{newPassword ?
						(
							passwordValidationDetails &&
							passwordValidationDetails?.isMinLength
						) ?
							validIcon
						:	invalidIcon
					:	null}
				</li>
			)}
			{require_lowercase && (
				<li>
					Must contain at least one lowercase character (a-z)
					{newPassword ?
						(
							passwordValidationDetails &&
							passwordValidationDetails?.hasLowercase
						) ?
							validIcon
						:	invalidIcon
					:	null}
				</li>
			)}
			{require_uppercase && (
				<li>
					Must contain at least one uppercase character (A-Z)
					{newPassword ?
						(
							passwordValidationDetails &&
							passwordValidationDetails?.hasUppercase
						) ?
							validIcon
						:	invalidIcon
					:	null}
				</li>
			)}
			{require_number && (
				<li>
					Must contain at least one number (0-9)
					{newPassword ?
						passwordValidationDetails && passwordValidationDetails?.hasNumber ?
							validIcon
						:	invalidIcon
					:	null}
				</li>
			)}
			{require_special && (
				<li>
					Must contain at least one symbol (!@#$%^&*()_+-=[]{}|')
					{newPassword ?
						passwordValidationDetails && passwordValidationDetails?.hasSpecial ?
							validIcon
						:	invalidIcon
					:	null}
				</li>
			)}
		</ul>
	);
};

/** Styles */

const iconSx = {
	left: "5px",
	position: "relative",
	top: "5px",
};

const wrapperSx = {
	color: theme.palette.text.secondary,
};
