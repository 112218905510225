const emailRegEx =
	/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export function isValidEmail(email: string) {
	return emailRegEx.test(email);
}

export function sanitizePhoneNumber(phone: string): PhoneNumber {
	return `+${phone.replaceAll(/[-_.()\s+]/g, "")}`;
}

const slackWorkspaceRegEx = /^.+$/;
export function isValidSlackContact(slackContact: string) {
	const [workspaceName, targetEmail] = slackContact.split(":");

	return (
		Boolean(workspaceName) &&
		slackWorkspaceRegEx.test(workspaceName) &&
		Boolean(targetEmail) &&
		isValidEmail(targetEmail)
	);
}

/**
 * Format a phone number to a human-readable format.
 *
 * This function is pretty primitive and doesn't handle all edge cases. It will
 * fallback to returning the input if it doesn't match the expected format.
 */
// TODO: why is there a `+` sometimes? Are we not always using this method?
export function formatPhoneNumber(phone: string) {
	return phone.replace(/(\d{1,3})(\d{3})(\d{3})(\d{4})/, "$1-$2-$3-$4");
}

// To be in sync with backend we have stolen this from:
// https://github.com/Urigo/graphql-scalars/blob/master/src/scalars/PhoneNumber.ts#L4C28-L4C48
const phoneRegEx = /^\+[1-9]\d{6,14}$/;

export function isValidPhoneNumber(phone: string) {
	return phoneRegEx.test(sanitizePhoneNumber(phone));
}
