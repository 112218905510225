import { useGetPerson } from "api/queries/useGetPerson";
import useLegacyRedirect from "router/useLegacyRedirect";
import { useIntercomStore } from "stores/intercomStore";
import { useActiveAccount } from "utils/useActiveAccount";
import useGlobalState from "utils/useGlobalState";
import useRequiresAuth from "utils/useRequiresAuth";
import useSetupMixpanel from "utils/useSetupMixpanel";

const useController = () => {
	const { isInitialLoading, status } = useGetPerson();
	const { active, intercom, user, account, personName } = useActiveAccount();
	const { setShowIntercom } = useIntercomStore();

	useLegacyRedirect();
	useRequiresAuth();
	useGlobalState();
	useSetupMixpanel();

	return {
		account,
		active,
		intercom,
		isInitialLoading,
		personName,
		setShowIntercom,
		status,
		user,
	};
};

export default useController;
