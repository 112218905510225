/** correctly ignored: the function should accept anything and only return true if it's a JSON string */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isJSONString = (str: any) => {
	if (typeof str !== "string") return false;

	try {
		JSON.parse(str);
	} catch (e) {
		return false;
	}
	return true;
};

export default isJSONString;
