import { CssBaseline, ThemeProvider } from "@mui/material";
import { ErrorBoundary as RollbarErrorBoundary } from "@rollbar/react";
import "@samdesk/design/foundation.css";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { ReactQueryProvider } from "api/ReactQueryProvider";
import { ApolloProvider } from "api/apollo/ApolloProvider";
import GlobalFonts from "fonts/fonts";
import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { MixpanelProvider } from "react-mixpanel-browser";
import { BrowserRouter } from "react-router-dom";
import Router from "router/Index";
import { theme } from "theme";
import { ErrorComponent } from "utils/ErrorComponent";
import { RollbarProvider } from "utils/RollbarProvider";

const container = document.getElementById("root");
/** correctly ignored: we need a non-null assertion for React 18's entry point */
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);

const mixpanel_token = window.SAM._env_.MIXPANEL_TOKEN;

/** global react entry point for wrappers that affect the whole app */
root.render(
	<StrictMode>
		<RollbarProvider>
			<RollbarErrorBoundary fallbackUI={ErrorComponent}>
				<MixpanelProvider token={mixpanel_token}>
					<ThemeProvider theme={theme}>
						<CssBaseline />
						<GlobalFonts />
						<ApolloProvider>
							<ReactQueryProvider>
								<BrowserRouter>
									<Router />
								</BrowserRouter>
								<ReactQueryDevtools initialIsOpen={false} />
							</ReactQueryProvider>
						</ApolloProvider>
					</ThemeProvider>
				</MixpanelProvider>
			</RollbarErrorBoundary>
		</RollbarProvider>
	</StrictMode>
);
