import {
	Box,
	Button,
	Checkbox,
	FormControlLabel,
	Stack,
	TextField,
	Tooltip,
	Typography,
} from "@mui/material";
import { AccountPasswordPolicy } from "gql/graphql";
import { Dispatch, SetStateAction, useState } from "react";
import theme from "theme";
import { PasswordCriteria } from "ui-library/PasswordCriteria";
import { useCsrf } from "./useCsrf";

interface Props {
	accountName: string;
	confirmPassword: string;
	confirmPasswordError: string;
	disableSignup: boolean;
	email: string;
	handleSignup: () => void;
	name: string;
	password: string;
	passwordError: string;
	passwordPolicy: AccountPasswordPolicy | undefined;
	passwordValidationDetails: Record<string, boolean> | null;
	setConfirmPassword: Dispatch<SetStateAction<string>>;
	setName: Dispatch<SetStateAction<string>>;
	setPassword: Dispatch<SetStateAction<string>>;
	submitError: string;
	verificationCode: string;
	samlIssuer?: string;
}

export const NewUserSignup = ({
	accountName,
	confirmPassword,
	confirmPasswordError,
	disableSignup,
	email,
	handleSignup,
	password,
	passwordError,
	passwordPolicy,
	passwordValidationDetails,
	name,
	setConfirmPassword,
	setPassword,
	setName,
	submitError,
	verificationCode,
	samlIssuer,
}: Props) => {
	const [showPassword, setShowPassword] = useState(false);
	const { data: { token: csrfToken } = {} } = useCsrf({
		enabled: !!samlIssuer,
	});

	const postUrl =
		window.SAM._env_.REACT_APP_ENVIRONMENT === "production" ?
			"https://sso.samdesk.io/signup/invitation/sso"
		:	"https://sso-dev.samdesk.io/signup/invitation/sso";

	return (
		<Stack spacing={3}>
			<Typography component="h1" sx={titleSx}>
				Welcome! Sign up to join the {accountName} account on Samdesk.
			</Typography>

			<Box component="form" method="post" action={postUrl}>
				<Stack spacing={2}>
					<Typography component="h2" sx={emailSx} variant="h3">
						{email}
					</Typography>

					{samlIssuer && (
						<>
							<input type="hidden" name="_csrf" value={csrfToken} />
							<input
								type="hidden"
								name="signup_code"
								value={verificationCode}
							/>
						</>
					)}

					{!samlIssuer && (
						<>
							<TextField
								autoComplete="name"
								fullWidth
								label="Name"
								margin="normal"
								onChange={(e) => setName(e.target.value)}
								type="text"
								value={name}
								variant="outlined"
							/>

							<TextField
								autoComplete="password"
								error={!!passwordError}
								fullWidth
								helperText={passwordError}
								label="Password"
								margin="normal"
								onChange={(e) => setPassword(e.target.value)}
								type={showPassword ? "text" : "password"}
								value={password}
								variant="outlined"
							/>

							<TextField
								onKeyDown={(e) => {
									if (e.key === "Enter" && !disableSignup) {
										handleSignup();
									}
								}}
								error={!!confirmPasswordError}
								fullWidth
								helperText={confirmPasswordError}
								label="Confirm Password"
								margin="normal"
								onChange={(e) => setConfirmPassword(e.target.value)}
								type={showPassword ? "text" : "password"}
								value={confirmPassword}
								variant="outlined"
							/>
						</>
					)}

					<Stack
						justifyContent="space-between"
						alignItems="center"
						direction="row"
					>
						{!samlIssuer && (
							<FormControlLabel
								control={
									<Checkbox
										onChange={() => setShowPassword((prev) => !prev)}
										value={showPassword}
									/>
								}
								label="Show passwords"
							/>
						)}

						<Tooltip
							arrow={true}
							disableHoverListener={!disableSignup}
							disableFocusListener={!disableSignup}
							disableTouchListener={!disableSignup}
							placement="left"
							title="All fields are required"
						>
							<Box>
								{samlIssuer ?
									<Button variant="contained" type="submit" fullWidth={true}>
										Sign Up with {samlIssuer}
									</Button>
								:	<Button
										disabled={disableSignup}
										onClick={handleSignup}
										variant="contained"
									>
										Sign Up
									</Button>
								}
							</Box>
						</Tooltip>
					</Stack>
				</Stack>
			</Box>

			{submitError && <Typography sx={submitErrorSx}>{submitError}</Typography>}

			{passwordPolicy && !samlIssuer && (
				<Box sx={passwordPolicyWrapperSx}>
					<Typography sx={passwordPolicyTitleSx}>Password Policy</Typography>
					<PasswordCriteria
						newPassword={password}
						passwordPolicy={passwordPolicy}
						passwordValidationDetails={passwordValidationDetails}
					/>
				</Box>
			)}

			<Box sx={finePrintWrapperSx}>
				<Typography sx={finePrintSx}>
					Before signing up with samdesk please read our&nbsp;
					<Typography
						component="a"
						href="https://www.samdesk.io/privacy"
						style={linkSx}
						target="_blank"
					>
						Privacy Policy
					</Typography>
				</Typography>
				<Typography sx={finePrintSx}>
					By signing up with samdesk, you agree to our&nbsp;
					<Typography
						component="a"
						href="https://www.samdesk.io/tos"
						sx={linkSx}
						target="_blank"
					>
						Terms of Service
					</Typography>
				</Typography>
			</Box>
		</Stack>
	);
};

/** Style */

const emailSx = {
	fontSize: "1rem",
	textAlign: "left",
};

const finePrintSx = {
	color: "text.secondary",
	margin: "0 0 10px",
	textAlign: "left",
};

const finePrintWrapperSx = {
	paddingTop: "2rem",
};

const linkSx = {
	color: theme.palette.primary.light,
	textDecoration: "none",
};

const passwordPolicyTitleSx = {
	color: "text.primary",
	margin: "0 0 10px",
	textAlign: "left",
};

const passwordPolicyWrapperSx = {
	backgroundColor: "grey.800",
	borderRadius: 2,
	display: "flex",
	flexDirection: "column",
	marginTop: "1rem",
	padding: "25px 25px 15px 25px",
	textAlign: "left",
	width: "100%",
};

export const submitErrorSx = {
	color: theme.palette.error.main,
	fontSize: "0.9rem",
	textAlign: "left",
};

export const titleSx = {
	fontSize: "1.5rem",
	fontWeight: 600,
};
