import Breadcrumbs from "routes/settings/Breadcrumbs";
import { SectionCard } from "routes/settings/style";
import CenteredCircularProgress from "ui-library/CenteredCircularProgress";
import EmptyStateBox from "ui-library/EmptyStateBox";
import SettingsPageTitle from "ui-library/SettingsPageTitle";
import EverbridgeIntegrationButton from "ui-library/everbridge/EverbridgeIntegrationButton";
import EverbridgeIntegrationCommonComponent from "ui-library/everbridge/EverbridgeIntegrationCommonComponent";
import EverbridgeIntegrationVersionPicker from "ui-library/everbridge/EverbridgeIntegrationVersionPicker";

import useController from "./useController";

const Index = () => {
	const {
		apiKey,
		createButtonIsDisabled,
		defaultEverbridgeVCCCategory,
		everbridgeVCCCategories,
		handleCreateEverbridgeIntegration,
		hasEverbridgeV2,
		integrationVersion,
		isError,
		isInitialLoading,
		isSuccess,
		name,
		setApiKey,
		setDefaultEverbridgeVCCCategory,
		setIntegrationVersion,
		setName,
		setUrl,
		tooltipMessage,
		url,
	} = useController();

	const everbridgeVersionChoices = [{ prettyName: "Version 2", version: 2 }];

	if (isInitialLoading) {
		return (
			<>
				<Breadcrumbs />
				<CenteredCircularProgress />
			</>
		);
	}

	if (isError) {
		return (
			<>
				<Breadcrumbs />
				<EmptyStateBox headerMessage="There was a problem loading this page." />
			</>
		);
	}

	if (!hasEverbridgeV2) {
		return (
			<>
				<Breadcrumbs />
				<EmptyStateBox
					headerMessage="You do not have access to this feature"
					subMessage="Please contact support for more information"
				/>
			</>
		);
	}

	if (isSuccess && everbridgeVCCCategories) {
		return (
			<>
				<Breadcrumbs />
				<SettingsPageTitle>Create Integration</SettingsPageTitle>

				<SectionCard variant="outlined">
					<EverbridgeIntegrationCommonComponent
						apiKey={apiKey}
						defaultEverbridgeVCCCategory={defaultEverbridgeVCCCategory}
						everbridgeVCCCategories={everbridgeVCCCategories}
						name={name}
						setApiKey={setApiKey}
						setDefaultEverbridgeVCCCategory={setDefaultEverbridgeVCCCategory}
						setName={setName}
						setUrl={setUrl}
						url={url}
					/>
					<EverbridgeIntegrationVersionPicker
						everbridgeVersionChoices={everbridgeVersionChoices}
						integrationVersion={integrationVersion}
						setIntegrationVersion={setIntegrationVersion}
					/>
				</SectionCard>
				<EverbridgeIntegrationButton
					action={handleCreateEverbridgeIntegration}
					disabled={createButtonIsDisabled}
					label="Create"
					tooltipMessage={tooltipMessage}
				/>
			</>
		);
	}

	return (
		<>
			<Breadcrumbs />
			<EmptyStateBox headerMessage="There was a problem loading this page." />
		</>
	);
};

export default Index;
