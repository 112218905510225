import useCreateEverbridgeIntegration from "api/mutations/useCreateEverbridgeIntegration";
import useGetDefaultEverbridgeIntegration from "api/queries/useGetDefaultEverbridgeIntegration";
import useGetEverbridgeVCCCategories from "api/queries/useListEverbridgeVCCCategories";
import useAccountSettings from "utils/useAccountSettings";
import useCacheToState from "utils/useCacheToState";
import useNavigateWithAccount from "utils/useNavigateWithAccount";
import useNavigationPrompt from "utils/useNavigationPrompt";
import useRequiresAdmin from "utils/useRequiresAdmin";
import useSnackbar from "utils/useSnackbar";

const useController = () => {
	const { handleError } = useSnackbar();
	const navigate = useNavigateWithAccount();

	/** see if user has access to everbridge v2 */
	const { isInitialLoading: isInitialLoadingAccountSettings, hasEverbridgeV2 } =
		useAccountSettings();

	/** defaults when on create page */
	const {
		data: defaultEverbridgeIntegration,
		isError: isErrorDefaultEverbridgeIntegration,
		isInitialLoading: isInitialLoadingDefaultEverbridgeIntegration,
		isSuccess: isSuccessDefaultEverbridgeIntegration,
	} = useGetDefaultEverbridgeIntegration();

	const {
		data: everbridgeVCCCategories,
		isError: isErrorEverbridgeVCCCategories,
		isInitialLoading: isInitialLoadingEverbridgeVCCCategories,
		isSuccess: isSuccessEverbridgeVCCCategories,
	} = useGetEverbridgeVCCCategories();

	const {
		mutateAsync: createEverbridgeIntegration,
		isLoading: isLoadingCreateEverbridgeIntegration,
	} = useCreateEverbridgeIntegration();

	const [name, setName, isDirtyName] = useCacheToState<string | undefined>("");
	const [apiKey, setApiKey, isDirtyApiKey] = useCacheToState<
		string | undefined
	>("");
	const [url, setUrl, isDirtyUrl] = useCacheToState<string | undefined>(
		defaultEverbridgeIntegration?.url
	);
	const [
		defaultEverbridgeVCCCategory,
		setDefaultEverbridgeVCCCategory,
		isDirtyDefaultEverbridgeVCCCategory,
	] = useCacheToState<null | string | undefined>("");
	const [integrationVersion, setIntegrationVersion, isDirtyIntegrationVersion] =
		useCacheToState(2);

	const isDirty =
		isDirtyName ||
		isDirtyApiKey ||
		isDirtyUrl ||
		isDirtyDefaultEverbridgeVCCCategory ||
		isDirtyIntegrationVersion;

	useNavigationPrompt(
		"You have unsaved changes, do you want to leave without saving?",
		isDirty && !isLoadingCreateEverbridgeIntegration
	);

	useRequiresAdmin();

	const isError =
		isErrorDefaultEverbridgeIntegration || isErrorEverbridgeVCCCategories;
	const isInitialLoading =
		isInitialLoadingDefaultEverbridgeIntegration ||
		isInitialLoadingAccountSettings ||
		isInitialLoadingEverbridgeVCCCategories;
	const isSuccess =
		isSuccessDefaultEverbridgeIntegration && isSuccessEverbridgeVCCCategories;
	const createButtonIsDisabled =
		!name || !apiKey || !integrationVersion || !url;
	const missingFields = [];
	if (!name) missingFields.push("Integration Name");
	if (!apiKey) missingFields.push("Api Key");
	if (!url) missingFields.push("URL");
	if (!integrationVersion) missingFields.push("Integration Version");
	const tooltipMessage =
		"Can not create new integration. Missing fields: " +
		missingFields.join(", ");

	const handleCreateEverbridgeIntegration = () => {
		if (isLoadingCreateEverbridgeIntegration) return;
		if (createButtonIsDisabled) return;

		let params = {
			api_key: apiKey,
			disabled: false,
			name: name,
			url: url,
			version: integrationVersion,
		};

		if (defaultEverbridgeVCCCategory) {
			params = Object.assign(params, {
				default_vcc_category: defaultEverbridgeVCCCategory,
			});
		}

		createEverbridgeIntegration(params)
			.then((variables) => {
				if (variables.success) {
					navigate("/settings/account/integrations/everbridge");
				} else if (variables.error) {
					handleError(variables.error);
				}
			})
			.catch((err) => handleError(err));
	};

	return {
		apiKey,
		createButtonIsDisabled,
		defaultEverbridgeVCCCategory,
		everbridgeVCCCategories,
		handleCreateEverbridgeIntegration,
		hasEverbridgeV2,
		integrationVersion,
		isError,
		isInitialLoading,
		isSuccess,
		name,
		setApiKey,
		setDefaultEverbridgeVCCCategory,
		setIntegrationVersion,
		setName,
		setUrl,
		tooltipMessage,
		url,
	};
};

export default useController;
