import { useUpdatePerson } from "api/mutations/useUpdatePerson";
import { useGetPerson } from "api/queries/useGetPerson";

let hasUpdatedTimezone = false;

export function useTimezone(): string {
	const { data: person, isLoading } = useGetPerson();
	const { mutate: updatePerson } = useUpdatePerson();

	if (person?.timezone) {
		return person.timezone;
	}

	const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

	if (!isLoading && person && !person.timezone && !hasUpdatedTimezone) {
		hasUpdatedTimezone = true;
		updatePerson({ timezone: browserTimezone });
	}

	return browserTimezone;
}
