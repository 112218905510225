import { BasicError } from "gql/graphql";

export interface ErrorToLog {
	columnNumber: null | number;
	fileName: null | string;
	lineNumber: null | number;
	message: null | string;
	referrer: string;
	stack: null | string;
	type: null | string;
	url: string;
	userAgent: string;
}

/**
 * builds an error object out of an unknown error
 *
 * @param err of the unknown error type
 * @param fileName name of the file where error happened when defined
 * @returns
 */
export const normalizeError = (err: unknown, fileName?: string): ErrorToLog => {
	const error = {
		columnNumber: getColumnNumber(err),
		fileName: getFileName(err, fileName),
		lineNumber: getLineNumber(err),
		message: getMessage(err),
		referrer: document.referrer,
		stack: getStack(err),
		type: getType(err),
		url: window.location.href,
		userAgent: navigator?.userAgent ? navigator.userAgent : "IE",
	};

	return error;
};

/** functions to help normalize unknown types of errors to reliable values and types */
const getMessage = (err: unknown): null | string => {
	let message = null;
	if (err instanceof Error) message = err.message;
	else if (typeof (err as Record<string, string>).message === "string") {
		message = (err as Record<string, string>).message;
	} else message = (err as Error).toString();

	return message;
};

const getLineNumber = (err: unknown): null | number => {
	let lineNumber = null;
	if ((err as Record<string, string>)?.lineNumber) {
		lineNumber = (err as Record<string, null | number>).lineNumber;
	}

	return lineNumber;
};

const getColumnNumber = (err: unknown): null | number => {
	let columnNumber = null;
	if ((err as Record<string, string>)?.columnNumber) {
		columnNumber = (err as Record<string, null | number>).columnNumber;
	}

	return columnNumber;
};

const getFileName = (err: unknown, fileNameArg?: string): null | string => {
	let fileName = null;
	if (fileNameArg) fileName = fileNameArg;
	else if ((err as Record<string, string>)?.fileName) {
		fileName = (err as Record<string, string>).fileName;
	}

	return fileName;
};

const getStack = (err: unknown): null | string => {
	let stack = null;
	if ((err as Record<string, string>)?.stack) {
		stack = (err as Record<string, string>).stack;
	}
	return stack;
};

const getType = (err: unknown): null | string => {
	let type = null;
	if (err instanceof Error) type = (err as Error).name;
	else if (typeof (err as Record<string, string>).type === "string") {
		type = (err as Record<string, string>).type;
	}

	return type;
};

/** type guard helpers */

export const isObjWithError = (obj: unknown): obj is { error: unknown } => {
	return typeof obj === "object" && obj !== null && "error" in obj;
};

export const isObjWithResponse = (
	obj: unknown
): obj is { response: unknown } => {
	return typeof obj === "object" && obj !== null && "response" in obj;
};

export const isObjWithStatus = (obj: unknown): obj is { status: number } => {
	return (
		typeof obj === "object" &&
		obj !== null &&
		"status" in obj &&
		typeof obj.status === "number"
	);
};

export const isObjWithGQLErrorsArr = (
	obj: unknown
): obj is { errors: GQLError[] } => {
	return (
		/** check that object has a .errors value that is an array */
		typeof obj === "object" &&
		obj !== null &&
		"errors" in obj &&
		Array.isArray(obj.errors) &&
		/** checks that the object in the errors array is what we expect */
		obj.errors.every(
			(error: unknown) =>
				typeof error === "object" &&
				error !== null &&
				typeof (error as GQLError)?.extensions?.code === "string" &&
				typeof (error as GQLError)?.extensions?.message === "string"
		)
	);
};

export const isObjWithMessage = (obj: unknown): obj is { message: string } => {
	return (
		typeof obj === "object" &&
		obj !== null &&
		"message" in obj &&
		obj.message !== null &&
		typeof obj.message === "string"
	);
};

interface GQLError {
	extensions: BasicError;
}
