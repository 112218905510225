import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Avatar } from "@mui/material";
import { partialAccountFieldsFragment } from "gql/graphql";
import { ButtonHTMLAttributes } from "react";
import styled from "styled-components";
import theme from "theme";

interface AccountButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
	name: string;
	partialAccount: partialAccountFieldsFragment;
}

const AccountButton = ({
	name,
	partialAccount,
	...props
}: AccountButtonProps) => {
	return (
		<Button active={partialAccount.active} {...props}>
			<Avatar>{name?.charAt(0)}</Avatar>
			<div style={nameWrapperStyle}>
				<div>{partialAccount.name}</div>
				<div>{name}</div>
			</div>
			<ArrowForwardIosIcon fontSize="small" />
		</Button>
	);
};

export default AccountButton;

interface ButtonProps {
	active: boolean;
}

/** Styles */

const Button = styled.button<ButtonProps>`
	display: flex;
	justify-content: space-between;
	align-items: center;
	min-height: 60px;
	height: 60px;
	margin-bottom: 0.5rem;
	max-width: 300px;
	width: 100%;
	text-align: left;
	padding: 0 20px;
	background-color: ${theme.palette.grey[700]};
	cursor: pointer;
	border: none;
	border-radius: 4px;
	color: ${theme.palette.text.primary};
	opacity: ${({ active }) => (active ? 1 : 0.6)};
`;

const nameWrapperStyle = {
	padding: "0 1rem",
	width: "100%",
};
