import { useEffect } from "react";
import { useActiveAccount } from "utils/useActiveAccount";

const useSetupGlobalState = () => {
	const { _id, userId } = useActiveAccount();

	useEffect(() => {
		if (_id) {
			window.SAM.accountId = _id;
		} else if (window.SAM.accountId) {
			window.SAM.accountId = "";
		}
	}, [_id]);

	useEffect(() => {
		if (userId) {
			window.SAM.userId = userId;
		} else if (window.SAM.userId) {
			window.SAM.userId = "";
		}
	}, [userId]);
};

export default useSetupGlobalState;
