import { Box, Button, Link, TextField, Typography } from "@mui/material";
import { Dispatch, SetStateAction } from "react";
import { Link as RouterLink } from "react-router-dom";

import { submitErrorSx, titleSx } from "./NewUserSignup";

interface Props {
	accountName: string;
	email: string;
	handleJoinAccount: () => void;
	password: string;
	passwordError: string;
	setPassword: Dispatch<SetStateAction<string>>;
	submitError: string;
	userName: string;
}

export const ExistingUserJoinAccount = ({
	accountName,
	email,
	handleJoinAccount,
	password,
	passwordError,
	setPassword,
	submitError,
	userName,
}: Props) => {
	return (
		<>
			<Typography component="h1" sx={titleSx} variant="h3">
				Welcome {userName}! Sign in to join the {accountName} account on
				Samdesk.
			</Typography>

			<Box component="form">
				<TextField
					fullWidth
					inputProps={{ readOnly: true }}
					label="Email"
					margin="normal"
					type="text"
					value={email}
					variant="outlined"
				/>

				<TextField
					onKeyDown={(e) => {
						if (e.key === "Enter") {
							handleJoinAccount();
						}
					}}
					autoComplete="password"
					fullWidth
					helperText={passwordError}
					label="Password"
					margin="normal"
					onChange={(e) => setPassword(e.target.value)}
					type="password"
					value={password}
					variant="outlined"
				/>

				<Button
					fullWidth
					onClick={handleJoinAccount}
					sx={buttonSx}
					variant="contained"
				>
					Join Account
				</Button>

				<Link
					component={RouterLink}
					sx={linkSx}
					to="/reset_password"
					variant="body2"
				>
					Forgot password?
				</Link>
			</Box>

			{submitError && <Typography sx={submitErrorSx}>{submitError}</Typography>}
		</>
	);
};

/** Style */

const buttonSx = {
	margin: "16px 0 8px",
};

const linkSx = {
	cursor: "pointer",
	textDecoration: "none",
};
