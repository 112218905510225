import { Button, Container, Typography } from "@mui/material";
import { useMatch } from "react-router-dom";
import theme from "theme";
import useNavigateWithAccount from "utils/useNavigateWithAccount";

interface Props {
	redirectUrl?: string;
}

export const PageNotFound = ({ redirectUrl }: Props) => {
	const match = useMatch("/u/:userAccountIndex/*");
	const navigate = useNavigateWithAccount();

	const handleBackButtonClick = () => {
		if (redirectUrl) {
			navigate(redirectUrl, { replace: true });
		} else if (match?.pathnameBase) {
			navigate(match.pathnameBase, { replace: true });
		} else {
			navigate("/", { replace: true });
		}
	};

	return (
		<Container component="main" disableGutters={true} sx={wrapperSx}>
			<Typography component="h1" sx={pageNotFoundTextSx} variant="h2">
				Page not found.
			</Typography>
			<Typography component="h1" sx={sorryTextSx} variant="h5">
				Sorry! The page you are looking for cannot be found. Check the URL and
				try again. If the problem persists, please{" "}
				<a
					aria-label="support link"
					href="mailto:support@samdesk.io"
					style={emailStyle}
					target="_blank"
				>
					contact support
				</a>
				.
			</Typography>
			<Button
				aria-label="take me back button"
				onClick={handleBackButtonClick}
				sx={backButtonSx}
				variant="contained"
			>
				Take me back
			</Button>
		</Container>
	);
};

/** Styles */

const backButtonSx = {
	marginBottom: "40px",
};

const emailStyle = {
	color: theme.palette.primary.main,
	textDecoration: "inherit",
};

const pageNotFoundTextSx = {
	color: "text.secondary",
	fontWeight: 600,
	margin: "40px",
};

const sorryTextSx = {
	color: "text.secondary",
	margin: "0 40px 40px",
	textAlign: "center",
};

const wrapperSx = {
	alignItems: "center",
	display: "flex",
	flexDirection: "column",
	height: "100vh",
	justifyContent: "center",
	width: "100vh",
};
