import { Typography } from "@mui/material";
import { Button } from "@samdesk/components/Button";
import { showPrompt } from "@samdesk/components/Prompt";
import { SectionCard } from "routes/settings/style";
import { descriptionSx } from "./Index";

interface Props {
	handleCreateDeletePersonRequest: () => void;
}

const DeleteProfile = ({ handleCreateDeletePersonRequest }: Props) => {
	async function handleDeleteProfile() {
		const { state: promptResponse } = await showPrompt({
			promptType: "confirm",
			title: "Confirm Delete",
			description: "Are you sure you want to delete your samdesk profile?",
			confirmButton: { label: "Delete Profile", intent: "destruction" },
		});

		if (promptResponse === "ok") {
			handleCreateDeletePersonRequest();
		}
	}

	return (
		<>
			<SectionCard variant="outlined">
				<Typography variant="h6">Samdesk Profile</Typography>
				<Typography sx={descriptionSx}>
					We'd be sorry to see you go but if you want to delete your profile at
					samdesk, use the button below.
				</Typography>
				<Button
					label="Delete Your Profile"
					intent="destruction"
					onClick={handleDeleteProfile}
				/>
			</SectionCard>
		</>
	);
};

export default DeleteProfile;
