import RequestResetPassword from "./RequestResetPassword";
import ResetPassword from "./ResetPassword";
import useController from "./useController";

const Index = () => {
	const {
		code,
		confirmPassword,
		confirmPasswordError,
		createResetPasswordRequestSuccess,
		disableSubmit,
		email,
		handleCreateResetPasswordRequest,
		handleSubmitResetPasswordRequest,
		isInititalLoadingResetPasswordRequest,
		navigate,
		password,
		passwordError,
		passwordValidationDetails,
		resetPasswordRequest,
		setConfirmPassword,
		setEmail,
		setPassword,
		submitError,
	} = useController();

	if (code) {
		return (
			<ResetPassword
				confirmPassword={confirmPassword}
				confirmPasswordError={confirmPasswordError}
				disableSubmit={disableSubmit}
				handleSubmitResetPasswordRequest={handleSubmitResetPasswordRequest}
				isInitialLoading={isInititalLoadingResetPasswordRequest}
				navigate={navigate}
				password={password}
				passwordError={passwordError}
				passwordValidationDetails={passwordValidationDetails}
				resetPasswordRequest={resetPasswordRequest}
				setConfirmPassword={setConfirmPassword}
				setPassword={setPassword}
				submitError={submitError}
			/>
		);
	} else {
		return (
			<RequestResetPassword
				email={email}
				handleCreateResetPasswordRequest={handleCreateResetPasswordRequest}
				isSuccess={createResetPasswordRequestSuccess}
				setEmail={setEmail}
			/>
		);
	}
};

export default Index;
