import useDeleteDashboardTab from "api/mutations/useDeleteDashboardTab";
import useLogIncidentInteraction from "api/mutations/useLogIncidentInteraction";
import useGetDashboard from "api/queries/useGetDashboard";
import useGetIncident from "api/queries/useGetIncident";
import { Tab } from "autoGenSubTypes";
import pluralize from "pluralize";
import { useParams } from "react-router-dom";
import useNavigateWithAccount from "utils/useNavigateWithAccount";
import useSnackbar from "utils/useSnackbar";
import useMissedAlertsCount from "./useMissedAlertsCount";
import useNavigateAfterTabDelete from "./useNavigateAfterTabDelete";
import useNewAlertSound from "./useNewAlertSound";

const useControllerTab = (tab: Tab) => {
	const {
		mutateAsync: deleteDashboardTab,
		isLoading: isLoadingDeleteDashboardTab,
	} = useDeleteDashboardTab();
	const { mutateAsync: logIncidentInteraction } = useLogIncidentInteraction();
	const { isFetching: isFetchingDashboard } = useGetDashboard();

	const incidentId =
		tab.__typename === "IncidentTab" ? tab.incident_tab_data.incident_id : "";
	const { data: incident } = useGetIncident({
		_id: incidentId,
		follow_merges: true,
	});

	const { tabKey } = useParams();
	const { totalMissedAlerts, bottomMissedAlerts, topMissedAlerts } =
		useMissedAlertsCount(tab);
	const navigateAfterTabDelete = useNavigateAfterTabDelete();
	const navigate = useNavigateWithAccount();

	const { handleError } = useSnackbar();

	const topSoundEnabled =
		tab.__typename === "AlertsTab" && tab.alerts_tab_data.top_sound.enabled;
	const bottomSoundEnabled =
		tab.__typename === "AlertsTab" && tab.alerts_tab_data.bottom_sound.enabled;

	useNewAlertSound({
		enabled: topSoundEnabled,
		newAlertCount: topMissedAlerts,
	});
	useNewAlertSound({
		enabled: bottomSoundEnabled,
		newAlertCount: bottomMissedAlerts,
	});

	/** The close functionality is only allowed for non-alerts tabs. Alerts tabs are removable via the TabMenu */
	const handleCloseTab = () => {
		deleteDashboardTab({ params: { key: tab.key } })
			.then((variables) => {
				if (variables.success) {
					navigateAfterTabDelete(tab.key);
					if (variables.tab?.__typename === "IncidentTab") {
						logIncidentInteraction({
							incident_id: incidentId,
							interaction_type: "track",
							source: "dashboard",
						});
					}
				} else if (variables.error) {
					handleError(variables.error);
				}
			})
			.catch((error) => handleError(error));
	};

	const getTabLabels = () => {
		const tabLabels = {
			label: "",
			subLabel: "",
		};

		switch (tab.__typename) {
			case "AlertsTab": {
				const streamCount =
					tab.alerts_tab_data.top.length + tab.alerts_tab_data.bottom.length;

				tabLabels.label = tab.alerts_tab_data.name;
				tabLabels.subLabel = `${streamCount} ${pluralize(
					"Stream",
					streamCount
				)}`;
				break;
			}
			case "SettingsTab": {
				tabLabels.label = "Settings";
				break;
			}
			case "StreamManagerTab": {
				tabLabels.label = "Streams";
				break;
			}
			case "IncidentTab": {
				tabLabels.label = incident?.wheres[0]?.compact_display || "";
				tabLabels.subLabel = incident?.types[0] || "";
				break;
			}
		}

		return tabLabels;
	};

	return {
		getTabLabels,
		handleCloseTab,
		incident,
		isFetchingDashboard,
		isLoadingDeleteDashboardTab,
		navigate,
		tabKey,
		totalMissedAlerts,
	};
};

export default useControllerTab;
