import { useQuery } from "@tanstack/react-query";
import axios from "axios";

async function getCsrf() {
	const { data } = await axios.get("/webv2/csrf");
	axios.defaults.headers.common["X-CSRF-TOKEN"] = data.token;
	return data;
}

export function useCsrf({ enabled = false }: { enabled?: boolean } = {}) {
	return useQuery(["csrf"], getCsrf, { enabled });
}
