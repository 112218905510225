import { isEqual } from "lodash";
import { Dispatch, SetStateAction, useEffect, useState } from "react";

import isJSONString from "./isJSONString";

export default function useCacheToState<D>(
	data: D
): [D, Dispatch<SetStateAction<D>>, boolean] {
	const [state, setState] = useState<D>(data);

	const combinedState = state ?? data;

	const isDirty =
		state !== undefined && data !== undefined && !isEqual(state, data);

	const stringifiedData = data ? JSON.stringify(data) : data;

	/** we need to handle a default value getting passed in while data is loading, this resets initial data in state on a change to it's init data */
	useEffect(() => {
		if (!stringifiedData || !isJSONString(stringifiedData)) return;
		const data = JSON.parse(stringifiedData as string);
		setState(data);
	}, [stringifiedData]);

	return [combinedState, setState, !!isDirty];
}
