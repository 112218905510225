import { Box } from "@mui/material";
import EnhancedOutlet from "ui-library/EnhancedOutlet";
import OptsBarPage from "ui-library/OptsBarPage";
import { withErrorBoundary } from "utils/ErrorBoundary";

const Index = () => {
	return (
		<>
			<OptsBarPage />
			<Box sx={settingsWrapperSx}>
				<EnhancedOutlet />
			</Box>
		</>
	);
};

export default withErrorBoundary(Index);

/** Style */

const settingsWrapperSx = {
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	margin: "50px auto 0",
	maxWidth: "1100px",
	padding: "60px 0 60px",
	width: "100%",
};
