import { ArrowForward } from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";
import Breadcrumbs from "routes/settings/Breadcrumbs";
import theme from "theme";
import SettingsPageTitle from "ui-library/SettingsPageTitle";

import EverbridgeTable from "./EverbridgeTable";
import useRequiresAdmin from "utils/useRequiresAdmin";
import useNavigateWithAccount from "utils/useNavigateWithAccount";
import useListEverbridgeIntegrations from "api/queries/useListEverbridgeIntegrations";

const Index = () => {
	const navigateWithAccount = useNavigateWithAccount();

	const {
		data: everbridgeIntegrations,
		isInitialLoading: isInitialLoadingEverbridgeIntegrations,
	} = useListEverbridgeIntegrations({});

	useRequiresAdmin();

	return (
		<>
			<Breadcrumbs />
			{/* title section */}
			<SettingsPageTitle>Everbridge Integrations</SettingsPageTitle>
			<Box sx={settingsPageContainerSx}>
				<Box sx={settingsPageHeaderContainerSx}>
					<Typography sx={settingsPageHeaderSx}>
						Through our partnership with Everbridge, samdesk customers can view
						events within the{" "}
						<a
							href="https://www.everbridge.com/products/visual-command-center/"
							style={settingsPageHeaderLinkSx}
							target="_blank"
						>
							VCC Platform
						</a>
						.
					</Typography>
					<Typography sx={settingsPageSubheaderSx}>
						In this section you can create and edit integrations and control
						which events you'd like sent to VCC. Please allow 10-15 minutes for
						changes to take place.
					</Typography>
				</Box>
				<Button
					color="secondary"
					endIcon={<ArrowForward />}
					onClick={() =>
						navigateWithAccount(
							"/settings/account/integrations/everbridge/create"
						)
					}
					sx={createIntegrationButtonSx}
					variant="outlined"
				>
					Create Integration
				</Button>
			</Box>

			{/* table section */}
			<EverbridgeTable
				everbridgeIntegrations={everbridgeIntegrations}
				isInitialLoadingEverbridgeIntegrations={
					isInitialLoadingEverbridgeIntegrations
				}
			/>
		</>
	);
};

export default Index;

const settingsPageContainerSx = {
	alignItems: "end",
	display: "flex",
	justifyContent: "space-between",
	marginBottom: "2rem",
};

const settingsPageHeaderContainerSx = {
	display: "flex",
	flexDirection: "column",
};

const settingsPageHeaderSx = {
	color: "text.secondary",
	margin: "10px 0 10px",
};

const settingsPageHeaderLinkSx = {
	color: theme.palette.secondary.main,
	textDecoration: "inherit",
};

const settingsPageSubheaderSx = {
	color: "text.secondary",
	margin: "10px 0 0",
};

const createIntegrationButtonSx = {
	float: "right",
	marginLeft: "1rem",
	maxHeight: "40px",
	minWidth: "max-content",
};
