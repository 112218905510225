// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
	--base-unit: 16px;

	--space-minuscule: calc(var(--base-unit) / 16); /* 1 */
	--space-smallest: calc(var(--base-unit) / 8); /* 2 */
	--space-smaller: calc(var(--base-unit) / 4); /* 4 */
	--space-small: calc(var(--base-unit) / 2); /* 8 */
	--space-base: calc(var(--base-unit) * 1); /* 16 */
	--space-large: calc(var(--base-unit) * 1.5); /* 24 */
	--space-larger: calc(var(--base-unit) * 2); /* 32 */
	--space-largest: calc(var(--base-unit) * 3); /* 48 */
	--space-extravagant: calc(var(--base-unit) * 4); /* 64 */
}
`, "",{"version":3,"sources":["webpack://./../../packages/design/src/spacing.css"],"names":[],"mappings":"AAAA;CACC,iBAAiB;;CAEjB,8CAA8C,EAAE,MAAM;CACtD,4CAA4C,EAAE,MAAM;CACpD,2CAA2C,EAAE,MAAM;CACnD,yCAAyC,EAAE,MAAM;CACjD,wCAAwC,EAAE,OAAO;CACjD,2CAA2C,EAAE,OAAO;CACpD,0CAA0C,EAAE,OAAO;CACnD,2CAA2C,EAAE,OAAO;CACpD,+CAA+C,EAAE,OAAO;AACzD","sourcesContent":[":root {\n\t--base-unit: 16px;\n\n\t--space-minuscule: calc(var(--base-unit) / 16); /* 1 */\n\t--space-smallest: calc(var(--base-unit) / 8); /* 2 */\n\t--space-smaller: calc(var(--base-unit) / 4); /* 4 */\n\t--space-small: calc(var(--base-unit) / 2); /* 8 */\n\t--space-base: calc(var(--base-unit) * 1); /* 16 */\n\t--space-large: calc(var(--base-unit) * 1.5); /* 24 */\n\t--space-larger: calc(var(--base-unit) * 2); /* 32 */\n\t--space-largest: calc(var(--base-unit) * 3); /* 48 */\n\t--space-extravagant: calc(var(--base-unit) * 4); /* 64 */\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
