import { Box, Button, Link, TextField, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

import useController from "./useController";

const Index = () => {
	const { emailMismatchError, errorMessage, formAction, misconfiguredError } =
		useController();

	/** this is a unique endpoint that has to be form submitted */
	return (
		<Box action={formAction} component="form" method="post" sx={formSx}>
			<TextField
				autoComplete="email"
				fullWidth
				label="Email"
				margin="normal"
				name="email"
				type="text"
				variant="outlined"
			/>
			<Button fullWidth sx={buttonSx} type="submit" variant="contained">
				Sign In With SSO
			</Button>

			<Link component={RouterLink} sx={linkSx} to="/signin" variant="body2">
				Sign In with email/password
			</Link>

			{/* display error message as is from url */}
			{errorMessage && (
				<Typography color="error" variant="body2">
					{errorMessage}
				</Typography>
			)}

			{/* custom error message from a url param */}
			{emailMismatchError && (
				<>
					<Typography color="error" variant="body2">
						There was an email mismatch between what you requested and what your
						identity provider returned.
					</Typography>
					<Typography color="error" variant="body2">
						Please ensure that the email you provide is the same one you use to
						log into your identity provider.
					</Typography>
				</>
			)}

			{/* custom error message from a url param */}
			{misconfiguredError && (
				<>
					<Typography color="error" variant="body2">
						It looks like your SSO integration is misconfigured.
					</Typography>
					<Typography color="error" variant="body2">
						Please contact your account administrator for assistance.
					</Typography>
				</>
			)}
		</Box>
	);
};

export default Index;

/** Style */

const buttonSx = {
	marginBottom: "1rem",
};

const formSx = {
	width: "100%",
};

const linkSx = {
	cursor: "pointer",
	textDecoration: "none",
};
