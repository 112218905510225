import { create } from "zustand";

type State = {
	setShowIntercom: (showIntercom: boolean) => void;
	showIntercom: boolean;
};

export const useIntercomStore = create<State>()((set) => ({
	setShowIntercom: (showIntercom) => set(() => ({ showIntercom })),
	showIntercom: false,
}));
