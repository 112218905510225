import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

interface Props {
	everbridgeVersionChoices: { prettyName: string; version: number }[];
	integrationVersion: number | undefined;
	setIntegrationVersion: (integrationVersion: number) => void;
}

const EverbridgeIntegrationVersionPicker = ({
	everbridgeVersionChoices,
	integrationVersion,
	setIntegrationVersion,
}: Props) => {
	return (
		<>
			<br />
			<FormControl>
				<InputLabel id="everbridge-version-label" size="small">
					Everbridge Integration Version
				</InputLabel>
				<Select
					label="Everbridge Integration Version"
					labelId="everbridge-version-label"
					onChange={(e) => setIntegrationVersion(Number(e.target.value))}
					size="small"
					sx={versionPickerSx}
					value={integrationVersion ?? ""}
					variant="outlined"
				>
					{everbridgeVersionChoices.map((everbridgeVersion) => {
						return (
							<MenuItem
								key={everbridgeVersion.version}
								value={everbridgeVersion.version}
							>
								{everbridgeVersion.prettyName}
							</MenuItem>
						);
					})}
				</Select>
			</FormControl>
		</>
	);
};

export default EverbridgeIntegrationVersionPicker;

/** Styles */

const versionPickerSx = {
	marginBottom: "1rem",
	width: "400px",
};
