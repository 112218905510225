// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
	--elevation-default: 0;
	--elevation-menu: 1200;
	--elevation-modal: 1300; /* The only one we're currently using and its the same z-index as MUI Modal */
	--elevation-tooltip: 1400;
	--elevation-toast: 1500;
}
`, "",{"version":3,"sources":["webpack://./../../packages/design/src/elevations.css"],"names":[],"mappings":"AAAA;CACC,sBAAsB;CACtB,sBAAsB;CACtB,uBAAuB,EAAE,6EAA6E;CACtG,yBAAyB;CACzB,uBAAuB;AACxB","sourcesContent":[":root {\n\t--elevation-default: 0;\n\t--elevation-menu: 1200;\n\t--elevation-modal: 1300; /* The only one we're currently using and its the same z-index as MUI Modal */\n\t--elevation-tooltip: 1400;\n\t--elevation-toast: 1500;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
