import { useActiveAccount } from "./useActiveAccount";

const useAccountSettings = () => {
	const { account, isLoading, isInitialLoading } = useActiveAccount();

	// general account flags
	const hasAllowWebhooks = !!account?.settings.allow_webhooks;
	const hasApiAccess = !!account?.settings.api_access;
	const hasEbIntegration = !!account?.settings.eb_integration;
	const hasEverbridgeV2 = !!account?.settings.everbridge_v2;
	const hasMsTeams = !!account?.settings.ms_teams;
	const hasResolver = !!account?.settings.resolver;
	const hasProximity = !!account?.settings.show_proximity;
	const hasSaml = !!account?.settings.saml;
	const hasAuthentic8 = !!account?.settings.authentic8;
	const hasTravelIntegration = !!account?.settings.travel_integration;
	const isSam = !!account?.settings.is_sam;

	// Legacy account flags -- pre-FE being able to make them
	const hasSamalytics = !!account?.settings.samalytics;
	const hasSamalyticsTab = !!account?.settings.samalytics_tab;
	const hasCopyStream = !!account?.settings.copy_stream;
	const hasCsvAssetUpload = !!account?.settings.csv_asset_upload;
	const hasSharedStreams = !!account?.settings.shared_streams;

	return {
		// account settings
		hasAllowWebhooks,
		hasApiAccess,
		hasAuthentic8,
		hasEbIntegration,
		hasEverbridgeV2,
		hasMsTeams,
		hasResolver,
		hasProximity,
		hasSaml,
		hasTravelIntegration,

		// account flags
		hasSamalytics,
		hasSamalyticsTab,
		hasCopyStream,
		hasCsvAssetUpload,
		isSam,
		hasSharedStreams,

		flags: account?.flags ?? [],

		// query status
		isInitialLoading,
		isLoading,
	};
};

export default useAccountSettings;
