import { Box, Button, Typography } from "@mui/material";

import AccountButton from "./AccountButton";
import useController from "./useController";

const Index = () => {
	const { handleAccountSelect, handleSignout, person } = useController();

	return (
		<Box sx={outerWrapperSx}>
			<Box sx={innerWrapperSx}>
				<Typography component="h1" sx={titleSx} variant="h5">
					Choose An Account
				</Typography>
				<Box sx={accountButtonWrapperSx}>
					{person?.partialAccounts.map((partialAccount) => {
						return (
							<AccountButton
								key={partialAccount._id}
								name={person?.name}
								onClick={(e) => handleAccountSelect(e, partialAccount)}
								partialAccount={partialAccount}
							/>
						);
					})}
				</Box>
			</Box>
			<Button onClick={handleSignout} sx={buttonSx} variant="contained">
				Sign Out
			</Button>
		</Box>
	);
};

export default Index;

/** Style */

const accountButtonWrapperSx = {
	overflowY: "auto",
	padding: "0 1rem",
};

const innerWrapperSx = {
	display: "flex",
	flexDirection: "column",
	height: "100%",
	justifyContent: "center",
};

const outerWrapperSx = {
	height: "calc(100% - 160px)",
};

const titleSx = {
	marginBottom: "1rem",
};

const buttonSx = {
	marginBottom: "40px",
	marginTop: "15px",
};
