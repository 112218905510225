import { InfoOutlined } from "@mui/icons-material";
import {
	Box,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	TextField,
	Tooltip,
	Typography,
} from "@mui/material";
import { EverbridgeVCCCategories } from "autoGenSubTypes";
import { Dispatch, SetStateAction } from "react";

interface Props {
	apiKey: string | undefined;
	defaultEverbridgeVCCCategory: null | string | undefined;
	everbridgeVCCCategories: EverbridgeVCCCategories;
	name: string | undefined;
	setApiKey: Dispatch<SetStateAction<string | undefined>>;
	setDefaultEverbridgeVCCCategory: Dispatch<
		SetStateAction<null | string | undefined>
	>;
	setName: Dispatch<SetStateAction<string | undefined>>;
	setUrl: Dispatch<SetStateAction<string | undefined>>;
	url: string | undefined;
}

const EverbridgeIntegrationCommonComponent = ({
	apiKey,
	defaultEverbridgeVCCCategory,
	everbridgeVCCCategories,
	name,
	setApiKey,
	setDefaultEverbridgeVCCCategory,
	setName,
	setUrl,
	url,
}: Props) => {
	const displayDefaultEverbridgeVCCCategory =
		defaultEverbridgeVCCCategory ?? "";

	return (
		<>
			<Typography variant="h6">The Basics</Typography>
			<TextField
				label="Integration Name"
				onChange={(e) => setName(e.target.value)}
				size="small"
				sx={textFieldTitleSx}
				value={name ?? ""}
				variant="outlined"
			/>
			<Box sx={tooltipBoxSx}>
				<Tooltip
					placement="top"
					title="Name this integration to make it easy to identify."
				>
					<InfoOutlined fontSize="small" sx={textFieldTooltipSx} />
				</Tooltip>
			</Box>
			<br />
			<TextField
				label="Api Key"
				onChange={(e) => setApiKey(e.target.value)}
				size="small"
				sx={textFieldTitleSx}
				value={apiKey ?? ""}
				variant="outlined"
			/>
			<Box sx={tooltipBoxSx}>
				<Tooltip placement="top" title="Get an API Key for VCC API.">
					<InfoOutlined fontSize="small" sx={textFieldTooltipSx} />
				</Tooltip>
			</Box>
			<br />
			<TextField
				label="URL"
				onChange={(e) => setUrl(e.target.value)}
				size="small"
				sx={textFieldTitleSx}
				value={url ?? ""}
				variant="outlined"
			/>
			<Box sx={tooltipBoxSx}>
				<Tooltip
					placement="top"
					title="Don't change this unless you know what you're doing."
				>
					<InfoOutlined fontSize="small" sx={textFieldTooltipSx} />
				</Tooltip>
			</Box>
			<br />
			<FormControl>
				<InputLabel id="eventtype-select-label" size="small">
					New Event Type Default
				</InputLabel>
				<Select
					label="New Event Type Default"
					labelId="eventtype-select-label"
					onChange={(e) => setDefaultEverbridgeVCCCategory(e.target.value)}
					size="small"
					sx={selectFieldSx}
					value={displayDefaultEverbridgeVCCCategory}
					variant="outlined"
				>
					{everbridgeVCCCategories.map((everbridgeVCCCategory) => {
						return (
							<MenuItem
								key={everbridgeVCCCategory._id}
								value={everbridgeVCCCategory.category}
							>
								{everbridgeVCCCategory.category}
							</MenuItem>
						);
					})}
				</Select>
			</FormControl>
		</>
	);
};

export default EverbridgeIntegrationCommonComponent;

/** Styles */

const tooltipBoxSx = {
	display: "inline",
	height: "2rem",
	paddingTop: "17px",
	verticalAlign: "middle",
};

const textFieldTitleSx = {
	marginBottom: "1rem",
	width: "400px",
};

const textFieldTooltipSx = {
	color: "text.secondary",
	marginLeft: "4px",
};

const selectFieldSx = {
	marginBottom: "1rem",
	width: "400px",
};
