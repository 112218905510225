// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
	--element-small: calc(var(--base-unit) * 1.5);
	--element-base: calc(var(--base-unit) * 2.5);
}
`, "",{"version":3,"sources":["webpack://./../../packages/design/src/sizing.css"],"names":[],"mappings":"AAAA;CACC,6CAA6C;CAC7C,4CAA4C;AAC7C","sourcesContent":[":root {\n\t--element-small: calc(var(--base-unit) * 1.5);\n\t--element-base: calc(var(--base-unit) * 2.5);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
