import axios from "axios";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import customParseFormat from "dayjs/plugin/customParseFormat";
import relativeTime from "dayjs/plugin/relativeTime";
import timezone from "dayjs/plugin/timezone";
import updateLocale from "dayjs/plugin/updateLocale";
import utc from "dayjs/plugin/utc";
import logErrors, { forceLogErrors } from "utils/logErrors";

/** initializing everything that should happen before React starts rendering */
const init = () => {
	/** setting axios defaults for entire app */
	axios.defaults.baseURL = window.SAM._env_.REACT_APP_BASE_URL;
	axios.defaults.withCredentials = true;
	axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

	/** setting up a place to store the current users accountId for non react code */
	window.SAM.accountId = "";

	/** setting up listeners to trigger sendBeacon error logging, this is to send any unsent errors if the user closes, refreshes, or leaves the page */
	/**
	 * recommended mdn method to send errors on unload
	 * https://developer.mozilla.org/en-US/docs/Web/API/Navigator/sendBeacon#sending_analytics_at_the_end_of_a_session
	 */
	document.addEventListener("visibilitychange", () => {
		if (document.visibilityState === "hidden") {
			forceLogErrors();
		}
	});

	/**
	 * from mdn:
	 * "The best event to use to signal the end of a user's session is the visibilitychange event.
	 * In browsers that don't support visibilitychange the pagehide event is the next-best alternative."
	 */
	window.onpagehide = (event) => {
		if (event.persisted) {
			forceLogErrors();
		}
	};

	/** setting up error handling for things that exist outside of React and errors that aren't handled in React */
	window.onerror = (message, source, lineno, colno, error) => {
		if (error) {
			if (!error?.lineNumber) error.lineNumber = lineno;
			if (!error?.columnNumber) error.columnNumber = colno;
			error.fileName = "window.onerror global catch";
			logErrors(error);
		} else {
			logErrors({
				columnNumber: colno,
				fileName: "window.onerror global catch",
				lineNumber: lineno,
				message,
				url: source,
			});
		}
	};

	window.addEventListener("unhandledrejection", (event) => {
		// prevent the event from being logged to the console: https://developer.mozilla.org/en-US/docs/Web/API/Window/unhandledrejection_event#preventing_default_handling
		event.preventDefault();

		logErrors({
			fileName: "window.addEventListener unhandledrejection global catch",
			message: event.reason.message,
			stack: event.reason.stack,
			type: "unhandled promise rejections",
		});

		/** not adding a call to Rollbar from here since Rollbar is already catching uncaught errors */
	});

	/** Set custom  config and locale for relative timestamps across application (via dayjs) */
	const config = {
		thresholds: [
			{ l: "s", r: 1 },
			{ d: "seconds", l: "s", r: 59 },
			{ l: "m", r: 1 },
			{ d: "minute", l: "mm", r: 59 },
			{ l: "h", r: 1 },
			{ d: "hour", l: "hh", r: 23 },
			{ l: "d", r: 1 },
			{ d: "day", l: "dd", r: 30 },
			{ l: "M", r: 1 },
			{ d: "month", l: "MM", r: 11 },
			{ l: "y", r: 1 },
			{ d: "year", l: "yy" },
		],
	};

	dayjs.extend(advancedFormat);
	dayjs.extend(relativeTime, config);
	dayjs.extend(utc);
	dayjs.extend(timezone);
	dayjs.extend(updateLocale);
	dayjs.extend(customParseFormat);

	const relativeTimeLocale = {
		relativeTime: {
			s: "%ds",
			m: "%dm",
			mm: "%dm",
			h: "%dh",
			hh: "%dh",
			d: "%dd",
			dd: "%dd",
			M: "%dM",
			MM: "%dM",
			y: "%dY",
			yy: "%dY",
		},
	};

	dayjs.updateLocale("en", relativeTimeLocale);
};

export default init;
