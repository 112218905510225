import { useEffect, useState } from "react";
import { isValidEmail } from "./contacts";

const useEmailValidation = (initialState = "") => {
	const [email, setEmail] = useState(initialState);
	const [emailError, setEmailError] = useState("");

	/** Handle error for update email */
	useEffect(() => {
		if (!email || isValidEmail(email)) setEmailError("");
		else setEmailError("Please enter a valid email address");
	}, [email]);

	return { email, setEmail, emailError, valid: isValidEmail(email) };
};

export default useEmailValidation;
