import { Paper } from "@mui/material";
import layout from "common/layoutDefinitions";

const OptsBarPage = () => {
	return <Paper sx={optsBarPageSx} />;
};

export default OptsBarPage;

/** STYLE */

const optsBarPageSx = {
	backgroundColor: "grey.900",
	backgroundImage: "unset",
	borderRadius: 0,
	boxShadow: "none",
	maxHeight: `${layout.optsBar.height}px`,
	minHeight: `${layout.optsBar.height}px`,
	position: "sticky",
	top: "0px",
	width: "fill-available",
	zIndex: 2,
};
