import { useSearchStore } from "stores/searchStore";
import useLogToMixPanel from "utils/useLogToMixpanel";

const useControllerSearch = () => {
	const { logToMixpanel } = useLogToMixPanel();
	const { setModalOpen } = useSearchStore();

	const handleOnClick = () => {
		setModalOpen(true);
		logToMixpanel("search_incident_open", { source: "search_button" });
	};

	return { handleOnClick };
};

export default useControllerSearch;
