import { LinkProps, Link as MuiLink } from "@mui/material";
import useGetAccount from "api/queries/useGetAccount";
import { PropsWithChildren } from "react";

import { isExternalLink } from "./isExternalLink";

/**
 * Accepting only a small set of props for now.
 */
interface Props {
	href?: string;
	onClick?: LinkProps["onClick"];

	/**
	 * @deprecated As we figure out our design system we can hopefully remove this.
	 */
	sx?: LinkProps["sx"];

	/**
	 * @deprecated As we figure out our design system we can hopefully remove this.
	 */
	variant?: LinkProps["variant"];
}

export const Link = (props: PropsWithChildren<Props>) => {
	if (isExternalLink(props.href)) return <ExternalLink {...props} />;

	return <MuiLink {...props} />;
};

const ExternalLink = (props: LinkProps) => {
	const { data: account } = useGetAccount();

	let externalLinkProps: LinkProps = { ...props, target: "_blank" };

	if (
		externalLinkProps.href &&
		account?.settings?.authentic8 &&
		account?.preferences?.authentic8
	) {
		externalLinkProps = {
			...externalLinkProps,
			href: `https://a8silo.com/launch#${props.href}`,
		};
	}
	return <MuiLink {...externalLinkProps} />;
};
