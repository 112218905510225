import { useSignin } from "api/mutations/useSignin";
import { useGetPerson } from "api/queries/useGetPerson";
import { personFieldsFragment } from "gql/graphql";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useEmailValidation from "utils/useEmailValidation";

const useController = () => {
	const { mutateAsync: signin } = useSignin();
	const { data: person } = useGetPerson();
	const { email, setEmail } = useEmailValidation();

	const [password, setPassword] = useState("");
	const [rememberMe, setRememberMe] = useState(true);
	const [errorMessage, setErrorMessage] = useState("");

	const navigate = useNavigate();

	const handlePerson = useCallback(
		(person: personFieldsFragment) => {
			const accountCount = person.partialAccounts.length;
			const accountZeroRequiresMFA =
				(person.partialAccounts[0]?.mfaRequired && !person.mfaAuthenticated) ??
				false;

			if (accountCount === 0) {
				navigate("/choose_account");
			} else if (accountCount === 1 && accountZeroRequiresMFA) {
				return navigate("/authenticate");
			} else if (accountCount === 1 && !accountZeroRequiresMFA) {
				return navigate("/");
			} else if (accountCount > 1) {
				navigate("/choose_account");
			}
		},
		[navigate]
	);

	/** move person forward if they are already authorized */
	useEffect(() => {
		if (person) {
			handlePerson(person);
		}
	}, [handlePerson, person]);

	const handleSignin = async () => {
		try {
			const response = await signin({
				params: {
					email,
					password,
					remember_me: rememberMe,
				},
			});

			if (response.success && response.person) {
				setErrorMessage("");
				handlePerson(response.person);
			} else if (response.error) {
				setErrorMessage(response.error.message);
			} else {
				setErrorMessage("Error Signing In");
			}
		} catch (error) {
			setErrorMessage("Invalid email or password");
		}
	};

	return {
		email,
		errorMessage,
		handleSignin,
		password,
		rememberMe,
		setEmail,
		setPassword,
		setRememberMe,
	};
};

export default useController;
