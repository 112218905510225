import { useRollbarPerson } from "@rollbar/react";
import { useGetPerson } from "api/queries/useGetPerson";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useActiveAccount } from "utils/useActiveAccount";

/**
 * A custom hook that checkes if you're signed in and mfa authenticated
 * If you're not, it will navigate you to /signin or /authenticate as required
 * use case is for routes that you should be able to view only if you're signed in and mfa authenticated
 */
const useRequiresAuth = () => {
	const { data: person, isError } = useGetPerson();
	const { mfaRequired, user, userId, _id: accountId } = useActiveAccount();
	const navigate = useNavigate();

	useRollbarPerson({
		userId,
		accountId,
	});

	useEffect(() => {
		const needsMFA = person && !person.mfaAuthenticated && mfaRequired && user;

		if (isError) navigate("/signin");
		else if (needsMFA) {
			navigate(`/authenticate?return_url=/u/${user.index}`);
		}
	}, [isError, mfaRequired, navigate, person, user]);
};

export default useRequiresAuth;
