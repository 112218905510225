import useGetUser from "api/queries/useGetUser";
import {
	bubblesUrl,
	chimesUrl,
	sonarUrl,
	woodBlocksUrl,
} from "common/soundDefinitions";
import { useEffect, useState } from "react";

interface Args {
	enabled: boolean;
	newAlertCount: number;
}

const useNewAlertSound = ({ enabled, newAlertCount }: Args) => {
	const { data: user } = useGetUser();

	const [audio, setAudio] = useState<HTMLAudioElement>(new Audio(bubblesUrl));

	const notificationSound = user?.preferences.notification_sound || "bubbles";

	/** sets up audio element based on notification sound */
	useEffect(() => {
		if (notificationSound === "bubbles" && !audio.src.includes(bubblesUrl)) {
			setAudio(new Audio(bubblesUrl));
		} else if (
			notificationSound === "chimes" &&
			!audio.src.includes(chimesUrl)
		) {
			setAudio(new Audio(chimesUrl));
		} else if (notificationSound === "sonar" && !audio.src.includes(sonarUrl)) {
			setAudio(new Audio(sonarUrl));
		} else if (
			notificationSound === "wood_blocks" &&
			!audio.src.includes(woodBlocksUrl)
		) {
			setAudio(new Audio(woodBlocksUrl));
		}
	}, [audio.src, notificationSound]);

	/** when newAlertCount changes and enabled, play the sound */
	useEffect(() => {
		if (!enabled || newAlertCount === 0) return;

		audio.play().catch(() => {
			// Empty catch block to suppress the error
		});
	}, [enabled, newAlertCount, audio]);
};

export default useNewAlertSound;
