import { create } from "zustand";
import { SubSource } from "types/common";

type ModalState =
	| {
			incidentId: string;
			subSource: SubSource;
			open: true;
	  }
	| {
			incidentId?: undefined;
			subSource?: undefined;
			open: false;
	  };

type State = {
	modalState: ModalState;
	/** incident view post tab state  */

	setModalState: (modalState: ModalState) => void;
};

export const useIncidentViewStore = create<State>()((set) => ({
	modalState: { open: false },

	setModalState: (modalState) => set(() => ({ modalState })),
}));
