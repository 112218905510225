import { Box, Breadcrumbs as BreadcrumbTrail } from "@mui/material";
import { useLocation } from "react-router-dom";
import theme from "theme";

import Breadcrumb from "./Breadcrumb";

interface Props {
	/**
	 * By default, each breadcrumb will be the displayed exactly as defined in the router.
	 * Can be overriden by passing the path as a key and the desired string as the value
	 */
	overrideName?: { [key: string]: string };
	limit?: number;
}

const Breadcrumbs = ({ overrideName = {}, limit }: Props) => {
	const location = useLocation();
	let pathArray = location.pathname.split("/").slice(1);
	/** remove the u/# section from the breadcrumbs if present in the url */
	if (pathArray[0] === "u") pathArray = pathArray.splice(2);
	if (limit) pathArray = pathArray.slice(0, limit);

	return (
		<Box aria-label={"breadcrumbs"} component="nav">
			<BreadcrumbTrail sx={breadCrumbTrailSx}>
				{pathArray.map((pathSegment, index) => {
					return (
						<Breadcrumb
							index={index}
							key={index}
							overrideName={overrideName}
							pathArray={pathArray}
							pathSegment={pathSegment}
						/>
					);
				})}
			</BreadcrumbTrail>
		</Box>
	);
};

export default Breadcrumbs;

/** Style */

const breadCrumbTrailSx = {
	"& .MuiBreadcrumbs-separator": {
		color: theme.palette.text.disabled,
	},
};
