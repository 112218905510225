import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import useAccountPermissions from "./useAccountPermissions";

/**
 * A custom hook that checkes if you have admin permissions.
 * If you don't, it will navigate you to /unauthorized.
 * Use case is for routes that are only viewable by admins
 */
const useRequiresAdmin = () => {
	const { isAdmin } = useAccountPermissions();
	const navigate = useNavigate();

	useEffect(() => {
		if (!isAdmin) navigate("/unauthorized");
	}, [isAdmin, navigate]);
};

export default useRequiresAdmin;
