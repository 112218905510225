import { Box } from "@mui/material";
import layout from "common/layoutDefinitions";
import samdeskLogo from "images/samdeskLogo.png";
import { debounce } from "lodash";
import { useEffect, useMemo, useState } from "react";
import theme from "theme";

import Menu from "./Menu";
import NavTabs from "./NavTabs";
import Search from "./Search";

const NavBar = () => {
	const [visibleTabs, setVisibleTabs] = useState(0);

	const updateVisibleTabs = useMemo(
		() =>
			debounce(() => {
				const navMenuItems = 150;
				const tabDropdown = 50;
				const samdeskLogo = 60;
				const buffer = 20;
				const tabWidth = 215;
				const availableSpace =
					innerWidth - navMenuItems - tabDropdown - samdeskLogo - buffer;
				const visibleTabs = Math.floor(availableSpace / tabWidth);

				setVisibleTabs(visibleTabs <= 0 ? 1 : visibleTabs); // Always show at least one tab
			}, 200),
		[setVisibleTabs]
	);

	useEffect(() => {
		updateVisibleTabs();
	}, [updateVisibleTabs]);

	useEffect(() => {
		window.addEventListener("resize", updateVisibleTabs);

		return () => window.removeEventListener("resize", updateVisibleTabs);
	}, [updateVisibleTabs]);

	return (
		<Box sx={navBarContainerSx}>
			<Box sx={tabsWrapperSx}>
				<Box sx={iconWrapperSx}>
					<img src={samdeskLogo} style={samdeskLogoStyle} />
				</Box>
				<NavTabs visibleTabs={visibleTabs} />
			</Box>
			<Box sx={actionsWrapper}>
				<Search />
				<Menu />
			</Box>
		</Box>
	);
};

export default NavBar;

/** STYLES */

const actionsWrapper = {
	marginBottom: "5px",
	whiteSpace: "nowrap",
};

const iconWrapperSx = {
	alignItems: "center",
	alignSelf: "flex-end",
	display: "flex",
	height: "50px",
	justifyContent: "center",
	width: "60px",
};

const navBarContainerSx = {
	alignItems: "flex-end",
	backgroundColor: "background.default",
	borderBottom: `${layout.navBar.border}px solid ${theme.palette.border.main}`,
	display: "flex",
	height: `${layout.navBar.height}px`,
	justifyContent: "space-between",
	position: "relative",
};

const samdeskLogoStyle = {
	width: "25px",
};

const tabsWrapperSx = {
	display: "flex",
};
