import {
	Button,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from "@mui/material";
import { useRollbar } from "@rollbar/react";
import { EverbridgeIntegrations } from "autoGenSubTypes";
import dayjs from "dayjs";
import CenteredCircularProgress from "ui-library/CenteredCircularProgress";
import EmptyStateBox from "ui-library/EmptyStateBox";
import logErrors from "utils/logErrors";
import { showPrompt } from "@samdesk/components/Prompt";
import useNavigateWithAccount from "utils/useNavigateWithAccount";
import useDeleteEverbridgeIntegration from "api/mutations/useDeleteEverbridgeIntegration";
import useSnackbar from "utils/useSnackbar";

interface Props {
	everbridgeIntegrations: EverbridgeIntegrations | undefined;
	isInitialLoadingEverbridgeIntegrations: boolean;
}

const EverbridgeTable = ({
	everbridgeIntegrations,
	isInitialLoadingEverbridgeIntegrations,
}: Props) => {
	const { handleError } = useSnackbar();
	const { mutateAsync: deleteEverbridgeIntegration } =
		useDeleteEverbridgeIntegration();
	const navigateWithAccount = useNavigateWithAccount();

	const rollbar = useRollbar();

	if (isInitialLoadingEverbridgeIntegrations) {
		return <CenteredCircularProgress />;
	}

	if (!everbridgeIntegrations) {
		return (
			<EmptyStateBox
				headerMessage="You have no Everbridge Integrations"
				subMessage="Get started and create one"
				subMessageOnClick={() =>
					navigateWithAccount(
						"/settings/account/integrations/everbridge/create"
					)
				}
			/>
		);
	}

	const handleDeleteIntegration = async (integrationId: string) => {
		const { state } = await showPrompt({
			promptType: "confirm",
			title: `Confirm Delete`,
			description: `Are you sure you want to disable this integration? It can only be undone by talking to support.`,
			confirmButton: { label: "Delete" },
		});

		if (state === "ok") {
			deleteEverbridgeIntegration({ _id: integrationId }).catch((error) =>
				handleError(error)
			);
		}
	};

	return (
		<>
			<TableContainer component={Paper} sx={integrationTableContainerSx}>
				<Table aria-label="simple table" sx={integrationTableSx}>
					<TableHead>
						<TableRow>
							<TableCell>Name</TableCell>
							<TableCell>API Key</TableCell>
							<TableCell>Devault VCC Category</TableCell>
							<TableCell>Created</TableCell>
							<TableCell>Updated</TableCell>
							<TableCell></TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{everbridgeIntegrations?.map((integration, i) => {
							if (!integration) {
								/** This should never happen.
								 * If we're here, it somehow means that there's a gap in the integrations,
								 * that is, the list looks something like [integration, null, integration].
								 * We want to know if this happens.
								 */

								/** Stringify the integrations in this way so we do not accidentally leak the API key */
								logErrors(
									`Error finding integration at index ${i} in everbridgeIntegrations: ${JSON.stringify(
										everbridgeIntegrations,
										["_id"]
									)}`
								);

								/** log to Rollbar */
								rollbar.error(
									new Error(
										`Error finding integration at index ${i} in everbridgeIntegrations: ${JSON.stringify(
											everbridgeIntegrations,
											["_id"]
										)}`
									)
								);

								return null;
							}
							return (
								<TableRow key={integration._id} sx={integrationTableRowSx}>
									<TableCell component="th" scope="row">
										{integration.name}
									</TableCell>
									<TableCell component="th" scope="row">
										{integration.api_key}
									</TableCell>
									<TableCell>{integration.default_vcc_category}</TableCell>
									<TableCell align="left">
										{dayjs(integration.created).format("DD/MM/YY HH:MM")}
									</TableCell>
									<TableCell align="left">
										{dayjs(integration.updated).format("DD/MM/YY HH:MM")}
									</TableCell>
									<TableCell align="left">
										<Button
											color="secondary"
											onClick={() =>
												navigateWithAccount(
													`/settings/account/integrations/everbridge/${integration._id}`
												)
											}
											variant="outlined"
										>
											Edit
										</Button>
										<Button
											onClick={() => {
												handleDeleteIntegration(integration._id);
											}}
											color="error"
											sx={deleteButtonSx}
											variant="outlined"
										>
											Delete
										</Button>
									</TableCell>
								</TableRow>
							);
						})}
					</TableBody>
				</Table>
			</TableContainer>
		</>
	);
};

export default EverbridgeTable;

const integrationTableRowSx = {
	"&:last-child td, &:last-child th": {
		border: 0,
	},
};

const deleteButtonSx = {
	marginLeft: "1rem",
};

const integrationTableContainerSx = {
	marginTop: "80px",
};

const integrationTableSx = {
	minWidth: 650,
};
