import { CircularProgress } from "@mui/material";
import { useIsFetching } from "@tanstack/react-query";

const GlobalFetchingIndicator = () => {
	const isFetching = useIsFetching();

	return isFetching ?
			<CircularProgress size={16} sx={circularProgressSx} />
		:	null;
};

export default GlobalFetchingIndicator;

/** Styles */

const circularProgressSx = {
	bottom: "10px",
	position: "absolute",
	right: "10px",
};
