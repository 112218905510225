import {
	Box,
	Button,
	Checkbox,
	FormControlLabel,
	Link,
	TextField,
	Typography,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { withErrorBoundary } from "utils/ErrorBoundary";

import useController from "./useController";

const Index = () => {
	const {
		email,
		errorMessage,
		handleSignin,
		password,
		rememberMe,
		setEmail,
		setPassword,
		setRememberMe,
	} = useController();

	return (
		<>
			<Box component="form" sx={wrapperSx}>
				<TextField
					autoComplete="email"
					fullWidth
					label="Email"
					margin="normal"
					onChange={(e) => setEmail(e.target.value)}
					sx={emailTextFieldSx}
					type="text"
					value={email}
					variant="outlined"
				/>
				<TextField
					onKeyDown={(e) => {
						if (e.key === "Enter" && email && password) {
							handleSignin();
						}
					}}
					autoComplete="current-password"
					fullWidth
					label="Password"
					margin="normal"
					onChange={(e) => setPassword(e.target.value)}
					type="password"
					value={password}
					variant="outlined"
				/>
				{errorMessage && (
					<Typography component="p" variant="p1" sx={errorMessageSx}>
						{errorMessage}
					</Typography>
				)}
				<Box sx={signinButtonWrapperSx}>
					<FormControlLabel
						control={
							<Checkbox
								checked={rememberMe}
								color="primary"
								onChange={() => setRememberMe((state) => !state)}
								value="remember"
							/>
						}
						label="Remember me"
						sx={rememberMeLabelSx}
					/>
					<Button
						onClick={handleSignin}
						sx={signinButtonSx}
						variant="contained"
					>
						Sign In
					</Button>
				</Box>
				<Box sx={linkWrapperSx}>
					<Link
						component={RouterLink}
						sx={linkSx}
						to="/signin/sso"
						variant="body2"
					>
						Sign In with SSO
					</Link>
					<Link
						component={RouterLink}
						sx={linkSx}
						to="/reset_password"
						variant="body2"
					>
						Forgot password?
					</Link>
				</Box>
			</Box>
		</>
	);
};

export default withErrorBoundary(Index);

/** Style */

const emailTextFieldSx = {
	marginBottom: "0",
};

const errorMessageSx = {
	color: "warning.critical",
	width: "100%",
	alignSelf: "center",
	marginTop: "10px",
};

const rememberMeLabelSx = {
	float: "left",
};

const linkSx = {
	cursor: "pointer",
	marginBottom: "0.5rem",
	textDecoration: "none",
	width: "fit-content",
};

const linkWrapperSx = {
	display: "flex",
	flexDirection: "column",
	marginTop: "1.5rem",
	width: "100%",
	alignItems: "center",
};

const signinButtonSx = {
	padding: "0 1.5rem",
};

const signinButtonWrapperSx = {
	display: "flex",
	height: "36px",
	justifyContent: "space-between",
	marginTop: "0.5rem",
};

const wrapperSx = {
	width: "100%",
};
