import {
	AddBoxOutlined,
	SettingsOutlined,
	ViewStreamOutlined,
} from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import useCreateDashboardTab from "api/mutations/useCreateDashboardTab";
import useGetDashboard from "api/queries/useGetDashboard";
import { DashboardTabType } from "gql/graphql";
import samdeskLogo from "images/samdeskLogo.png";
import { useEffect } from "react";
import SettingsCard from "routes/settings/SettingsCard";
import CenteredCircularProgress from "ui-library/CenteredCircularProgress";
import OptsBarPage from "ui-library/OptsBarPage";
import useNavigateWithAccount from "utils/useNavigateWithAccount";
import useSnackbar from "utils/useSnackbar";

const NoTabs = () => {
	const { mutateAsync: createDashboardTab } = useCreateDashboardTab();
	const { data: dashboard, isInitialLoading, isSuccess } = useGetDashboard();

	const navigate = useNavigateWithAccount();
	const { handleError } = useSnackbar();

	const firstTab = dashboard?.tabs[0];

	/** handles redirecting to first tab if there are tabs */
	useEffect(() => {
		if (isInitialLoading) return;

		if (firstTab?.__typename === "AlertsTab") {
			navigate(`/alerts/${firstTab.key}`, { replace: true });
		} else if (firstTab?.__typename === "IncidentTab") {
			navigate(`/incident/${firstTab.incident_tab_data.incident_id}`, {
				replace: true,
			});
		}
	}, [firstTab, isInitialLoading, navigate]);

	const handleClickNewAlertsTab = () => {
		const newAlertsTab = {
			alerts_tab_data: {
				bottom: [],
				name: "New Alerts Tab",
				top: [],
			},
			type: DashboardTabType.ALERTS,
		};

		createDashboardTab({ params: { tab: newAlertsTab } })
			.then((variables) => {
				if (variables.success && variables.tab) {
					navigate(`alerts/${variables.tab.key}`);
				}
			})
			.catch((error) => handleError(error));
	};

	if (isInitialLoading) {
		return (
			<>
				<OptsBarPage />
				<CenteredCircularProgress />
			</>
		);
	}

	if (isSuccess && !firstTab) {
		return (
			<>
				<OptsBarPage />
				<Box sx={contentWrapperSx}>
					<img src={samdeskLogo} />
					<Typography component="h1" sx={welcomeToSamdeskTextSx}>
						Welcome to samdesk
					</Typography>
					<Box sx={cardWrapperSx}>
						<SettingsCard
							content="Create a new alerts dashboard and add streams to monitor critical events."
							heading="Create a dashboard"
							icon={<AddBoxOutlined />}
							onClick={handleClickNewAlertsTab}
							width={430}
						/>
						<SettingsCard
							content="Create and customize a stream with the event types and severity relevant to you."
							destination="streams"
							heading="Manage streams"
							icon={<ViewStreamOutlined />}
							width={430}
						/>
						<SettingsCard
							content="Update your personal or samdesk account settings."
							destination="settings"
							heading="Settings"
							icon={<SettingsOutlined />}
							width={430}
						/>
					</Box>
				</Box>
			</>
		);
	}

	/**
	 * this component only has a loading state and elements to show if there's no first tab (ie no tabs)
	 * normally we wouldn't return null, but in this case it should be safe
	 */
	return null;
};

export default NoTabs;

/** STYLES */

const cardWrapperSx = {
	div: {
		marginBottom: "1.5rem",
	},

	marginTop: "80px",
};

const contentWrapperSx = {
	alignItems: "center",
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	marginTop: "80px",
};

const welcomeToSamdeskTextSx = {
	fontSize: "28px",
	marginTop: "0.75rem",
};
