import useSignup from "api/mutations/useSignup";
import { useGetAccountInvitation } from "api/queries/useGetAccountInvitation";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import usePasswordInputState from "utils/usePasswordInputState";
import useSnackbar from "utils/useSnackbar";

export function useController(verificationCode: string) {
	const {
		data: accountInvitation,
		isInitialLoading: isInitialLoadingAccountInvitation,
	} = useGetAccountInvitation({
		verification_code: verificationCode,
	});
	const { mutateAsync: signup } = useSignup();

	const navigate = useNavigate();

	const email = accountInvitation?.pending_user_account?.email ?? "";
	const passwordPolicy = accountInvitation?.passwordPolicy ?? undefined;

	const [password, setPassword, passwordError, passwordValidationDetails] =
		usePasswordInputState(passwordPolicy);
	const [confirmPassword, setConfirmPassword, confirmPasswordError] =
		usePasswordInputState(undefined, password);
	const [name, setName] = useState("");
	const [submitError, setSubmitError] = useState("");

	const { handleError } = useSnackbar();

	/** disable signup button logic */
	const signupInputMissing = Boolean(!name || !password || !confirmPassword);
	const disableSignup =
		signupInputMissing || !!passwordError || !!confirmPasswordError;

	const handleSignup = () => {
		const params = {
			confirm_password: confirmPassword,
			email,
			name,
			password,
			verification_code: verificationCode,
		};

		signup(params)
			.then((variables) => {
				if (variables.success) {
					setSubmitError("");
					navigate("/choose_account");
				} else if (variables.error) {
					setSubmitError(variables.error.message);
				}
			})
			.catch((error) => handleError(error));
	};

	const handleJoinAccount = () => {
		const params = {
			email,
			password,
			verification_code: verificationCode,
		};

		signup(params)
			.then((variables) => {
				if (variables.success) {
					setSubmitError("");
					navigate("/choose_account");
				} else if (variables.error) {
					setSubmitError(variables.error.message);
				}
			})
			.catch((error) => handleError(error));
	};

	return {
		accountName: accountInvitation?.accountName ?? "",
		confirmPassword,
		confirmPasswordError,
		disableSignup,
		email,
		existingUser: accountInvitation?.personName !== null,
		handleJoinAccount,
		handleSignup,
		isInitialLoadingAccountInvitation,
		name,
		password,
		passwordError,
		passwordPolicy,
		passwordValidationDetails,
		setConfirmPassword,
		setName,
		setPassword,
		submitError,
		userName: accountInvitation?.personName ?? "",
		samlIssuer: accountInvitation?.samlIssuer,
	};
}
