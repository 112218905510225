import { SxProps } from "@mui/material";
import theme from "theme";

export const sectionTitleSx: SxProps = {
	fontSize: "1.25rem",
	fontWeight: theme.typography.fontWeightBold,
};

export const sectionWrapperSx: SxProps = {
	margin: "1rem 0",
};
