import { Box, Button, Typography } from "@mui/material";
import useSignout from "api/mutations/useSignout";
import { useGetPerson } from "api/queries/useGetPerson";
import SamdeskIcon from "icons/samdesk.svg";
import { Link, useNavigate } from "react-router-dom";
import { useActiveAccount } from "utils/useActiveAccount";
import useSnackbar from "utils/useSnackbar";

const DisabledAccount = () => {
	const { data: person } = useGetPerson();
	const { name } = useActiveAccount();
	const { mutateAsync: signout } = useSignout();

	const navigate = useNavigate();
	const { handleError } = useSnackbar();

	const handleSignout = () => {
		signout()
			.then((variables) => {
				if (variables.success) {
					navigate("/signin");
				}
			})
			.catch((error) => handleError(error));
	};

	if (!name || !person?.name) return null;

	return (
		<>
			<SamdeskIcon style={samdeskIconSx} />
			<Typography component="h1" sx={disabledTextSx} variant="h5">
				Your samdesk account "{name}" has been disabled.
			</Typography>
			<Typography component="p" sx={informationTextSx} variant="body2">
				If you are unsure why you are seeing this page, please contact your
				account administrator.
			</Typography>
			<Typography component="p" sx={informationTextSx} variant="body2">
				Signed in as {person?.name}.
			</Typography>
			<Box component="div" sx={buttonWrapperSx}>
				<Link replace style={chooseAccountLinkStyle} to="/choose_account">
					<Button>Choose Account</Button>
				</Link>
				<Button onClick={handleSignout}>Sign Out</Button>
			</Box>
		</>
	);
};

export default DisabledAccount;

/** Styles */

const buttonWrapperSx = {
	textAlign: "center",
};

const chooseAccountLinkStyle = {
	textDecoration: "inherit",
};

const informationTextSx = {
	color: "text.secondary",
	margin: "0.5rem 0",
	textAlign: "center",
};

const disabledTextSx = {
	textAlign: "center",
};

const samdeskIconSx = {
	margin: "1rem",
	minHeight: "32px",
};
