import { ArrowForward } from "@mui/icons-material";
import {
	Box,
	Button,
	Container,
	SxProps,
	Typography,
	useMediaQuery,
} from "@mui/material";
import AuthBackgroundImage from "images/authBackground.png";
import samdeskLogo from "images/samdeskFontLogo.png";
import theme from "theme";
import EnhancedOutlet from "ui-library/EnhancedOutlet";

const AuthOutlet = () => {
	const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

	return (
		<Container
			component="main"
			disableGutters={true}
			maxWidth={false}
			sx={authWrapperSx(isDesktop)}
		>
			<Box sx={authLeftPanelSx(isDesktop)}>
				<Box component="img" src={samdeskLogo} sx={samLogoSx} />
				<EnhancedOutlet />
			</Box>
			{isDesktop && (
				<Box sx={authRightPanelSx}>
					<Box sx={textWrapperSx}>
						<Typography component="h1" sx={titleSx} variant="h3">
							Real-time crisis alerts <br /> powered by AI.
						</Typography>
						<Typography component="h2" sx={subtitleSx} variant="h6">
							We help you protect your people, assets, and brand when it matters
							most.
						</Typography>
						<Button
							color="inherit"
							endIcon={<ArrowForward />}
							href="https://www.samdesk.io/"
							target="_blank"
							variant="outlined"
						>
							Request Access
						</Button>
					</Box>
				</Box>
			)}
		</Container>
	);
};

export default AuthOutlet;

/** STYLES */

const authLeftPanelSx = (isDesktop: boolean): SxProps => ({
	display: "flex",
	flexDirection: "column",
	height: "100%",
	alignItems: "center",
	justifyContent: "safe center",
	margin: isDesktop ? "0 80px" : "0 40px",
	padding: "100px 0",
	maxWidth: isDesktop ? "550px" : "unset",
	overflowX: "hidden",
	overflowY: "auto",
	position: "relative",
	textAlign: "center",
	width: isDesktop ? "100%" : "unset",
});

const authRightPanelSx: SxProps = {
	"&::before": {
		backgroundImage: `url(${AuthBackgroundImage})`,
		backgroundRepeat: "no-repeat",
		backgroundSize: "cover",
		content: '""',
		height: "100%",
		opacity: 0.75,
		position: "absolute",
		width: "100%",
		zIndex: -1,
	},

	alignItems: "center",
	display: "flex",
	height: "100%",
	position: "relative",
	width: "100%",
	zIndex: 1,
};

const authWrapperSx = (isDesktop: boolean): SxProps => ({
	display: isDesktop ? "flex" : "inherit",
	height: "100vh",
});

const samLogoSx: SxProps = {
	position: "absolute",
	top: "40px",
	left: "40px",
};

const subtitleSx: SxProps = {
	margin: "2rem 0",
};

const textWrapperSx: SxProps = {
	padding: "4rem",
};

const titleSx: SxProps = {
	fontFamily: "SamFont",
};
