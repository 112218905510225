import { Tab, TabTypename } from "autoGenSubTypes";
import { DashboardTabType } from "gql/graphql";

/**
 * This function takes a dashboard tab object and returns the path to get to it
 *
 * @param tab
 * @returns string (path to the tab used as an arg)
 */
export const getTabPath = (tab: Tab) => {
	let path = "";

	switch (tab.__typename) {
		case "AlertsTab": {
			path = `alerts/${tab.key}`;
			break;
		}
		case "SettingsTab": {
			path = "settings";
			break;
		}
		case "StreamManagerTab": {
			path = "streams";
			break;
		}
		case "IncidentTab": {
			path = `incident/${tab.incident_tab_data.incident_id}`;
			break;
		}
	}

	return path;
};

/** For the purpose of getting the equivalent values between __typename and DashboardTabType  */
export const tabTypeNameIndex: { [key in TabTypename]: DashboardTabType } = {
	AlertsTab: DashboardTabType.ALERTS,
	BaseDashboardTab: DashboardTabType.ALERTS, // DashboardTabType.BaseDashboardTab does not exist
	HistoricalSearchTab: DashboardTabType.HISTORICAL_SEARCH,
	IncidentTab: DashboardTabType.INCIDENT,
	SettingsTab: DashboardTabType.SETTINGS,
	StreamManagerTab: DashboardTabType.STREAM_MANAGER,
};

export const tabTypeIndex: { [key in DashboardTabType]: TabTypename } = {
	ALERTS: "AlertsTab",
	HISTORICAL_SEARCH: "HistoricalSearchTab",
	INCIDENT: "IncidentTab",
	SEARCH: "HistoricalSearchTab", // SearchTab does not exist yet (v2)
	SETTINGS: "SettingsTab",
	STREAM_MANAGER: "StreamManagerTab",
	TRACKED_EVENT: "IncidentTab", // TrackedEventTab does not exist
};
