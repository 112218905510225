import { Check, ErrorOutline } from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";
import samdeskLogo from "images/samdeskFontLogo.png";
import theme from "theme";
import CenteredCircularProgress from "ui-library/CenteredCircularProgress";

import useController from "./useController";

const Index = () => {
	const {
		errorMessage,
		handleContinueToSamdesk,
		isLoading,
		successCancellingEmailChange,
		successEmailChange,
	} = useController();

	if (isLoading) return <CenteredCircularProgress />;

	return (
		<Box component="main" sx={wrapperSx}>
			<Box component="img" src={samdeskLogo} />
			<Box component="div" sx={statusSx}>
				{successEmailChange && (
					<>
						<Check fontSize="large" />
						<Typography sx={messageSx}>Your email has been changed.</Typography>
						<Button onClick={handleContinueToSamdesk} variant="contained">
							Continue to samdesk
						</Button>
					</>
				)}

				{successCancellingEmailChange && (
					<>
						<Check fontSize="large" />
						<Typography sx={messageSx}>
							The email change has been cancelled.
						</Typography>
					</>
				)}

				{errorMessage && (
					<>
						<ErrorOutline fontSize="large" />
						<Typography color="error" sx={messageSx}>
							{errorMessage}
						</Typography>
					</>
				)}
			</Box>
		</Box>
	);
};

export default Index;

/** Style */

const messageSx = {
	fontSize: "larger",
	margin: "1rem",
	textAlign: "center",
};

const statusSx = {
	alignItems: "center",
	background: theme.palette.grey[800],
	borderRadius: "5px",
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	marginTop: "2rem",
	padding: "2rem",
	width: "400px",
};

const wrapperSx = {
	alignItems: "center",
	display: "flex",
	flexDirection: "column",
	height: "100vh",
	justifyContent: "center",
};
