import { useQuery } from "@apollo/client";
import { ResultOf } from "@graphql-typed-document-node/core";
import { Stack } from "@mui/material";
import { Button } from "@samdesk/components/Button";
import {
	autoColumnSize,
	createColumnHelper,
	DataTablePrototype,
} from "@samdesk/components/DataTablePrototype";
import { Heading } from "@samdesk/components/Heading";
import { Icon, IconName } from "@samdesk/components/Icon";
import { Text } from "@samdesk/components/Text";
import { getFragmentData, graphql } from "gql/apollo";
import CenteredCircularProgress from "ui-library/CenteredCircularProgress";
import { useOpenIncident } from "utils/useOpenIncident";

const IncidentResponseHistoryRowFragment = graphql(/* GraphQL */ `
	fragment IncidentResponseHistoryRow on IncidentResponse {
		gid
		incident {
			gid
			_id
			headline {
				text
			}
			incident_response {
				aggregates {
					safeCount
					unsafeCount
					warningCount
					failedCount
				}
			}
		}
	}
`);

export const IncidentResponseHistoryQuery = graphql(/* GraphQL */ `
	query ListIncidentResponses($params: ListIncidentResponsesParams!) {
		listIncidentResponses(params: $params) {
			nodes {
				gid
				incident {
					gid
					_id
					headline {
						text
					}
				}

				...IncidentResponseHistoryRow
			}
			pageInfo {
				hasNextPage
				endCursor
			}
		}
	}
`);

const pageSize = 10;

interface IncidentResponseHistoryProps {
	onRequestClose: () => void;
}

export function IncidentResponseHistory({
	onRequestClose,
}: IncidentResponseHistoryProps) {
	const { openIncident } = useOpenIncident();

	const columnHelper =
		createColumnHelper<ResultOf<typeof IncidentResponseHistoryRowFragment>>();

	const columns = [
		columnHelper.accessor("incident.headline.text", {
			id: "name",
			header: "Name",
			enableSorting: false,
			...autoColumnSize,
		}),
		columnHelper.accessor("incident.incident_response.aggregates", {
			id: "status",
			header: "Status",
			enableSorting: false,
			cell: ({ row }) => {
				const aggregates = row.original.incident?.incident_response?.aggregates;
				const counts: [IconName, number | undefined][] = [
					["check", aggregates?.safeCount],
					["close", aggregates?.unsafeCount],
					["warning", aggregates?.warningCount],
					["error", aggregates?.failedCount],
				];

				return (
					<Stack direction="row" gap="var(--space-small)" alignItems="center">
						{counts.map(
							([status, count]) =>
								!!count && (
									<Stack
										key={status}
										direction="row"
										gap="var(--space-smaller)"
										alignItems="center"
									>
										{count} <Icon name={status} size="small" />
									</Stack>
								)
						)}
					</Stack>
				);
			},
		}),
		columnHelper.display({
			id: "actions",
			size: 120,
			cell: ({ row }) => (
				<Button
					fullWidth
					label="View Incident"
					size="small"
					onClick={() => {
						openIncident({
							incidentId: row.original.incident!._id,
							subSource: "incident_response_modal",
						});
						onRequestClose();
					}}
				/>
			),
		}),
	];

	const { data, loading, error, fetchMore } = useQuery(
		IncidentResponseHistoryQuery,
		{
			variables: { params: { count: pageSize } },
		}
	);

	function loadMore() {
		if (!data?.listIncidentResponses.pageInfo.hasNextPage) return;

		const endCursor = data?.listIncidentResponses.pageInfo.endCursor;

		fetchMore({
			variables: {
				params: {
					count: pageSize,
					after: endCursor,
				},
			},
			updateQuery: (previousResult, { fetchMoreResult }) => {
				fetchMoreResult.listIncidentResponses.nodes = [
					...previousResult.listIncidentResponses.nodes,
					...fetchMoreResult.listIncidentResponses.nodes,
				];
				return fetchMoreResult;
			},
		});
	}

	if (loading) return <CenteredCircularProgress />;

	if (error) {
		return (
			<Stack alignItems="center">
				<Heading>There was a problem loading your incident responses</Heading>
				<Text>Please refresh to try again</Text>
			</Stack>
		);
	}

	if (data?.listIncidentResponses?.nodes.length === 0) {
		return (
			<Stack alignItems="center">
				<Heading>No Incident Responses</Heading>
				<Text>A history of your incident responses will appear here.</Text>
			</Stack>
		);
	}

	return (
		<Stack gap="var(--space-base)" alignItems="center">
			<DataTablePrototype
				columns={columns}
				data={(
					getFragmentData(
						IncidentResponseHistoryRowFragment,
						data?.listIncidentResponses.nodes
					) ?? []
				).filter((irn) => irn.incident)}
				showGlobalFilter={false}
			/>
			{data?.listIncidentResponses.pageInfo.hasNextPage && (
				<Button onClick={loadMore} prominence="secondary" label="Load More" />
			)}
		</Stack>
	);
}
