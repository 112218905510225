import { Button, Stack, Typography } from "@mui/material";
import { AnimatedMarker } from "ui-library/AnimatedMarker";

export const ErrorComponent = () => {
	return (
		<Stack alignItems={"center"} spacing={2} sx={stackSx}>
			{/* TODO: I hate this. It's a styled component instead of a proper component. I tried to fix it but it uses keyframes 
                and keyframes -> sx is a huge pain. So I gave up and cried a little before writing this note for later.
            */}
			<AnimatedMarker />
			<Typography component="h6" variant="h6">
				Oops! Something went wrong.
			</Typography>
			<Typography component="p" variant="p1">
				Samdesk is on it. Please try reloading the app. Thanks for your
				patience!
			</Typography>
			<Button onClick={() => window.location.reload()} variant={"contained"}>
				Reload this page
			</Button>
		</Stack>
	);
};

const stackSx = {
	margin: "10% 0",
};
