import { Typography } from "@mui/material";

interface Props {
	children: string;
}

const SettingsPageTitle = ({ children }: Props) => {
	return (
		<Typography component="h1" sx={settingsTitleSx} variant="h5">
			{children}
		</Typography>
	);
};

export default SettingsPageTitle;

/** Style */

const settingsTitleSx = {
	fontWeight: 600,
	margin: "2rem 0 1.5rem",
};
