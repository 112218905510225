import { useGetPerson } from "api/queries/useGetPerson";
import { throttle } from "lodash";
import { useEffect } from "react";
import { useMixpanel } from "react-mixpanel-browser";
import { useActiveAccount } from "utils/useActiveAccount";

const useSetupMixpanel = () => {
	const { data: person } = useGetPerson();

	const { _id, name, userId } = useActiveAccount();

	const mixpanel = useMixpanel();

	const fiveMinutes = 300000;

	/** set and update user profile information as needed */
	useEffect(() => {
		if (!userId || !person) return;

		/** identify the current user */
		mixpanel.identify(userId);

		/** set their user profile information */
		mixpanel.people.set({
			account_id: _id,
			account_name: name,
		});
	}, [_id, mixpanel, name, person, userId]);

	/** send a mousemove event at most once every 5 minutes */
	useEffect(() => {
		const trackMouseMove = throttle(
			() => mixpanel.track("mousemove"),
			fiveMinutes,
			{ trailing: false }
		);

		document.addEventListener("mousemove", trackMouseMove);

		return () => document.removeEventListener("mousemove", trackMouseMove);
	}, [fiveMinutes, mixpanel]);

	/** send a heartbeat event every 5 min the app is in an active tab */
	useEffect(() => {
		let lastHeartbeat = 0;
		let heartbeatTimeout: ReturnType<typeof setTimeout>;

		const trackHeartbeat = () => {
			lastHeartbeat = Date.now();
			mixpanel.track("heartbeat");
			heartbeatTimeout = setTimeout(trackHeartbeat, fiveMinutes);
		};

		/** send the first heartbeat immediately */
		trackHeartbeat();

		const trackHeartbeatWithTimeout = () => {
			if (document.visibilityState === "visible") {
				/**
				 * they came back to the tab:
				 * start the timeout back up, and fire immediately if
				 * it has been longer then five minutes
				 */
				const timeout = Math.max(fiveMinutes - (Date.now() - lastHeartbeat), 0);
				heartbeatTimeout = setTimeout(trackHeartbeat, timeout);
			} else if (heartbeatTimeout) {
				/**
				 * they left the tab:
				 * clear the timeout
				 */
				clearTimeout(heartbeatTimeout);
			}
		};

		document.addEventListener("visibilitychange", trackHeartbeatWithTimeout);

		return () =>
			document.removeEventListener(
				"visibilitychange",
				trackHeartbeatWithTimeout
			);
	}, [mixpanel]);
};

export default useSetupMixpanel;
