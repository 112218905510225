import { Button, Link, TextField, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { isValidEmail } from "utils/contacts";

interface Props {
	email: string;
	handleCreateResetPasswordRequest: () => void;
	isSuccess: boolean;
	setEmail: (email: string) => void;
}

const RequestResetPassword = ({
	email,
	handleCreateResetPasswordRequest,
	isSuccess,
	setEmail,
}: Props) => {
	const emailError = !!email && !isValidEmail(email);

	return (
		<>
			<Typography component="h1" sx={titleSx} variant="h5">
				Reset Password
			</Typography>

			{isSuccess && (
				<>
					<Typography component="p" sx={messageSx} variant="body2">
						An email has been sent to {email} with a password reset link. Please
						check your email within 3 days or the link will expire.
					</Typography>

					<Typography component="p" sx={messageSx} variant="body2">
						Didn't receive an email? Try again.
					</Typography>
				</>
			)}

			{!isSuccess && (
				<Typography component="p" sx={messageSx} variant="body2">
					Enter your email below and we'll send you a link to reset your
					password.
				</Typography>
			)}
			<TextField
				onKeyDown={(e) => {
					if (e.key === "Enter") {
						handleCreateResetPasswordRequest();
					}
				}}
				autoComplete="email"
				fullWidth
				label="Email"
				margin="normal"
				onChange={(e) => setEmail(e.target.value)}
				type="text"
				value={email}
				variant="outlined"
				error={emailError}
				helperText={emailError && "Please enter a valid email"}
			/>

			<Button
				fullWidth
				onClick={handleCreateResetPasswordRequest}
				sx={buttonSx}
				variant="contained"
			>
				RESET PASSWORD
			</Button>
			<Link
				component={RouterLink}
				replace
				sx={linkSx}
				to="/signin"
				variant="body2"
			>
				I remember my password
			</Link>
		</>
	);
};

export default RequestResetPassword;

/** Style */

const buttonSx = {
	margin: "8px 0",
};

const linkSx = {
	cursor: "pointer",
	textDecoration: "none",
};

const messageSx = {
	color: "text.secondary",
	margin: "8px 0",
	textAlign: "left",
};

const titleSx = {
	margin: "8px 0",
};
