export function getTimeZoneName(
	timeZone: string,
	timeZoneName: "shortGeneric" | "shortOffset" | "long"
) {
	const parts = Intl.DateTimeFormat("en", {
		timeZone,
		timeZoneName,
	}).formatToParts();

	/* `timeZoneName` is always present in DateTimeFormatPart */
	/* istanbul ignore next */
	return parts.find((part) => part.type === "timeZoneName")?.value;
}
