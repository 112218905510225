import useCreateResetPasswordRequest from "api/mutations/useCreateResetPasswordRequest";
import useSubmitResetPasswordRequest from "api/mutations/useSubmitResetPasswordRequest";
import useGetResetPasswordRequest from "api/queries/useGetResetPasswordRequest";
import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { isValidEmail } from "utils/contacts";
import useEmailValidation from "utils/useEmailValidation";
import usePasswordInputState from "utils/usePasswordInputState";
import useSnackbar from "utils/useSnackbar";

const useController = () => {
	/** common controller logic */
	const { handleError } = useSnackbar();

	/** request reset password controller logic */
	const { email, setEmail } = useEmailValidation();

	const {
		mutateAsync: createResetPasswordRequest,
		isSuccess: createResetPasswordRequestSuccess,
	} = useCreateResetPasswordRequest();

	const handleCreateResetPasswordRequest = () => {
		if (isValidEmail(email)) {
			createResetPasswordRequest({ email })
				.then((variables) => {
					if (variables.error) {
						handleError(variables.error);
					}
					/** success is handled in view component */
				})
				.catch((error) => handleError(error));
		}
	};

	/** reset password controller logic */
	const navigate = useNavigate();

	const [searchParams] = useSearchParams();
	const code = searchParams.get("code") ?? "";

	const { mutateAsync: submitResetPasswordRequest } =
		useSubmitResetPasswordRequest();
	const {
		data: resetPasswordRequest,
		isInitialLoading: isInititalLoadingResetPasswordRequest,
	} = useGetResetPasswordRequest({ code });

	const [password, setPassword, passwordError, passwordValidationDetails] =
		usePasswordInputState(resetPasswordRequest?.account_password_policy);
	const [confirmPassword, setConfirmPassword, confirmPasswordError] =
		usePasswordInputState(undefined, password);

	const [submitError, setSubmitError] = useState("");

	const resetPasswordInputMissing = Boolean(!password || !confirmPassword);
	const fieldsDoNotMatch = confirmPassword !== password;
	const disableSubmit =
		resetPasswordInputMissing ||
		fieldsDoNotMatch ||
		!!passwordError ||
		!!confirmPasswordError;

	const handleSubmitResetPasswordRequest = () => {
		if (!code || !password) return;

		const params = { code, password };

		submitResetPasswordRequest(params)
			.then((variables) => {
				if (variables.error) {
					setSubmitError(variables.error.message);
				} else if (variables.success) {
					navigate("/signin");
				}
			})
			.catch((error) => handleError(error));
	};

	return {
		code,
		confirmPassword,
		confirmPasswordError,
		createResetPasswordRequestSuccess,
		disableSubmit,
		email,
		handleCreateResetPasswordRequest,
		handleSubmitResetPasswordRequest,
		isInititalLoadingResetPasswordRequest,
		navigate,
		password,
		passwordError,
		passwordValidationDetails,
		resetPasswordRequest,
		setConfirmPassword,
		setEmail,
		setPassword,
		submitError,
	};
};

export default useController;
