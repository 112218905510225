import { Button, Stack, SxProps, TextField, Typography } from "@mui/material";
import useCreateChangeEmailRequest from "api/mutations/useCreateChangeEmailRequest";
import { personFieldsFragment } from "gql/graphql";
import { SectionCard } from "routes/settings/style";
import useEmailValidation from "utils/useEmailValidation";
import useSnackbar from "utils/useSnackbar";
import { sectionTitleSx, sectionWrapperSx } from "./common.styles";

export const UpdateEmail = ({ person }: { person: personFieldsFragment }) => {
	const { mutateAsync: createChangeEmailRequest } =
		useCreateChangeEmailRequest();

	const { email, setEmail, emailError } = useEmailValidation(person?.email);
	const { handleError, handleSuccess } = useSnackbar();

	const handleCreateChangeEmailRequest = () => {
		createChangeEmailRequest({ email })
			.then((variables) => {
				if (variables.error) {
					handleError(variables.error);
				} else if (variables.success) {
					handleSuccess("Check your email to verify your email change.");
				}
			})
			.catch((error) => handleError(error));
	};

	return (
		<SectionCard variant="outlined">
			<Stack spacing={2} sx={sectionWrapperSx}>
				<Typography sx={sectionTitleSx}>Update Email</Typography>

				<TextField
					error={!!email && !!emailError}
					helperText={emailError}
					onChange={(e) => setEmail(e.target.value)}
					size="small"
					value={email}
					variant="outlined"
					fullWidth
				/>

				<Button
					disabled={!email || !!emailError}
					onClick={handleCreateChangeEmailRequest}
					variant="contained"
					sx={buttonSx}
				>
					Update Email
				</Button>
			</Stack>
		</SectionCard>
	);
};

/** Style */

const buttonSx: SxProps = {
	width: "fit-content",
};
