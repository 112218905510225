import { create } from "zustand";

export type SnackType = "error" | "info" | "success" | "warning";

type State = {
	setSnackDuration: (snackDuration: null | number) => void;
	setSnackMessage: (snackMessage: string | undefined) => void;

	setSnackOpen: (snackOpen: boolean) => void;
	setSnackType: (snackType: SnackType) => void;

	snackDuration: null | number;
	snackMessage: string | undefined;

	snackOpen: boolean;
	snackType: SnackType | undefined;
};

export const useSnackbarStore = create<State>()((set) => ({
	setSnackDuration: (snackDuration) => set(() => ({ snackDuration })),
	setSnackMessage: (snackMessage) => set(() => ({ snackMessage })),

	setSnackOpen: (snackOpen) => set(() => ({ snackOpen })),
	setSnackType: (snackType) => set(() => ({ snackType })),

	snackDuration: 6000,
	snackMessage: undefined,

	snackOpen: false,
	snackType: undefined,
}));
