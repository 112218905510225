import { Suspense } from "react";
import { Outlet } from "react-router-dom";
import { withErrorBoundary } from "utils/ErrorBoundary";

import CenteredCircularProgress from "./CenteredCircularProgress";

/**
 * The react router Outlet component, but with wrappers.
 * 1. suspense (this is where suspense needs to catch for lazy loaded files - nested routes messes it up anywhere else)
 * 2. error boundary - just a good place for another catch of the error boundaries
 *
 * @returns and Outlet element with extra default wrappers of suspense and error boundary
 */
const EnhancedOutlet = () => {
	return (
		<Suspense fallback={<CenteredCircularProgress />}>
			<Outlet />
		</Suspense>
	);
};

export default withErrorBoundary(EnhancedOutlet);
