import {
	CodeOutlined,
	ExtensionOutlined,
	LocationOnOutlined,
	LockOutlined,
	PeopleAltOutlined,
} from "@mui/icons-material";
import { Grid } from "@mui/material";
import SettingsCard from "routes/settings/SettingsCard";
import SettingsPageTitle from "ui-library/SettingsPageTitle";

import Breadcrumbs from "../Breadcrumbs";
import useController from "./useController";

const Index = () => {
	useController();

	return (
		<>
			<Breadcrumbs />
			<SettingsPageTitle>Account Settings</SettingsPageTitle>

			<Grid
				columnSpacing={{ md: 3, sm: 2, xs: 1 }}
				columns={3}
				container
				rowSpacing={1}
			>
				<SettingsCard
					content="Invite members of your team to the samdesk platform"
					destination="members"
					heading="Members"
					icon={<PeopleAltOutlined />}
				/>
				<SettingsCard
					content="Upload and manage assets you'd like to monitor in samdesk"
					destination="assets"
					heading="Proximity Assets"
					icon={<LocationOnOutlined />}
				/>
				<SettingsCard
					content="Generate an API key and connect with samdesk"
					destination="api"
					heading="API"
					icon={<CodeOutlined />}
				/>
				<SettingsCard
					content="Connect your samdesk account with other services"
					destination="integrations"
					heading="Integrations"
					icon={<ExtensionOutlined />}
				/>
				<SettingsCard
					content="Control admin settings for your samdesk account"
					destination="security"
					heading="Security"
					icon={<LockOutlined />}
				/>
			</Grid>
		</>
	);
};

export default Index;
