import {
	Box,
	Button,
	Checkbox,
	FormControlLabel,
	Link,
	TextField,
	Typography,
} from "@mui/material";
import { ResetPasswordRequest } from "autoGenSubTypes";
import { Dispatch, SetStateAction, useState } from "react";
import { NavigateFunction, Link as RouterLink } from "react-router-dom";
import CenteredCircularProgress from "ui-library/CenteredCircularProgress";
import { PasswordCriteria } from "ui-library/PasswordCriteria";

interface Props {
	confirmPassword: string;
	confirmPasswordError: string;
	disableSubmit: boolean;
	handleSubmitResetPasswordRequest: () => void;
	isInitialLoading: boolean;
	navigate: NavigateFunction;
	password: string;
	passwordError: string;
	passwordValidationDetails: Record<string, boolean> | null;
	resetPasswordRequest: ResetPasswordRequest;
	setConfirmPassword: Dispatch<SetStateAction<string>>;
	setPassword: Dispatch<SetStateAction<string>>;
	submitError: string;
}

const ResetPassword = ({
	confirmPassword,
	confirmPasswordError,
	disableSubmit,
	handleSubmitResetPasswordRequest,
	isInitialLoading,
	navigate,
	password,
	passwordError,
	passwordValidationDetails,
	resetPasswordRequest,
	setConfirmPassword,
	setPassword,
	submitError,
}: Props) => {
	const [showPassword, setShowPassword] = useState(false);

	if (isInitialLoading) return <CenteredCircularProgress />;

	if (resetPasswordRequest) {
		return (
			<>
				<Typography component="h1" sx={titleSx} variant="h5">
					Reset your password
				</Typography>

				<TextField
					autoComplete="password"
					error={!!passwordError}
					fullWidth
					label="New Password"
					margin="normal"
					onChange={(e) => setPassword(e.target.value)}
					type={showPassword ? "text" : "password"}
					value={password}
					variant="outlined"
				/>
				<TextField
					onKeyDown={(e) => {
						if (e.key === "Enter" && !disableSubmit) {
							handleSubmitResetPasswordRequest();
						}
					}}
					error={!!confirmPasswordError}
					fullWidth
					helperText={confirmPasswordError}
					label="Confirm New Password"
					margin="normal"
					onChange={(e) => setConfirmPassword(e.target.value)}
					type={showPassword ? "text" : "password"}
					value={confirmPassword}
					variant="outlined"
				/>

				<Box sx={buttonWrapperSx}>
					<FormControlLabel
						control={
							<Checkbox
								onChange={() => setShowPassword(!showPassword)}
								value={showPassword}
							/>
						}
						label="Show password"
					/>

					<Button
						disabled={disableSubmit}
						onClick={() => handleSubmitResetPasswordRequest()}
						variant={"contained"}
					>
						Change Password
					</Button>
				</Box>

				{submitError && (
					<Typography sx={submitErrorSx}>{submitError}</Typography>
				)}

				{resetPasswordRequest.account_password_policy && (
					<Box sx={passwordPolicyWrapperSx}>
						<Typography sx={passwordPolicyTitleSx}>Password Policy</Typography>
						<PasswordCriteria
							newPassword={password}
							passwordPolicy={resetPasswordRequest.account_password_policy}
							passwordValidationDetails={passwordValidationDetails}
						/>
					</Box>
				)}
			</>
		);
	}

	/** For case when for whatever reason we could not find the request
	 *  Give the user an easy way to navigate back */
	return (
		<>
			<Typography component="h1" sx={titleSx} variant="h5">
				Reset password request could not be found. Please try again.
			</Typography>
			<Button
				fullWidth
				onClick={() => navigate("/reset_password")}
				sx={buttonSx}
				variant="contained"
			>
				Create New Request
			</Button>
			<Link
				component={RouterLink}
				replace
				sx={linkSx}
				to="/signin"
				variant="body2"
			>
				I remember my password
			</Link>
		</>
	);
};

export default ResetPassword;

/** Style */

const buttonSx = {
	margin: "8px 0",
};

const buttonWrapperSx = {
	display: "flex",
	justifyContent: "space-between",
	margin: "1rem 0",
	width: "100%",
};

const linkSx = {
	cursor: "pointer",
	textDecoration: "none",
};

const passwordPolicyTitleSx = {
	color: "text.primary",
	margin: "0 0 10px",
	textAlign: "left",
};

const passwordPolicyWrapperSx = {
	backgroundColor: "grey.800",
	borderRadius: 2,
	display: "flex",
	flexDirection: "column",
	marginTop: "1rem",
	padding: "25px 25px 15px 25px",
	textAlign: "left",
	width: "100%",
};

export const submitErrorSx = {
	color: "error.main",
	fontSize: "0.9rem",
	textAlign: "left",
};

export const titleSx = {
	fontSize: "1.5rem",
	fontWeight: 600,
	marginBottom: "4rem",
};
