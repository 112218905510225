import useListProximityAssetSets from "api/queries/useListProximityAssetSets";
import useAccountSettings from "utils/useAccountSettings";
import { useActiveAccount } from "utils/useActiveAccount";
import useNavigateWithAccount from "utils/useNavigateWithAccount";
import useRequiresAdmin from "utils/useRequiresAdmin";

const useController = () => {
	useRequiresAdmin();

	const { account, isInitialLoading: isInitialLoadingAccount } =
		useActiveAccount();
	const { hasProximity, isInitialLoading: isInitialLoadingAccountSettings } =
		useAccountSettings();
	const {
		data: proximityAssetSets,
		isInitialLoading: isInitialLoadingAssetSets,
	} = useListProximityAssetSets({ _ids: undefined });

	const navigate = useNavigateWithAccount();

	const isInitialLoading =
		isInitialLoadingAssetSets ||
		isInitialLoadingAccount ||
		isInitialLoadingAccountSettings;
	const atMaxAssetSets =
		account && proximityAssetSets ?
			account.settings.max_asset_sets === proximityAssetSets.length
		:	undefined;

	return {
		account,
		atMaxAssetSets,
		hasProximity,
		isInitialLoading,
		navigate,
		proximityAssetSets,
	};
};

export default useController;
