import { Box, Menu as MUIMenu, MenuItem, Typography } from "@mui/material";
import {
	partialAccountFieldsFragment,
	personFieldsFragment,
} from "gql/graphql";
import AccountIcon from "icons/account.svg";
import { SetStateAction } from "react";
import { useNavigate } from "react-router-dom";
import { useActiveAccountIndex } from "utils/useActiveAccount";
import { menuItemSx } from "./Menu";
import { accountTextWrapperSx } from "./MenuAccountOptions";

interface Props {
	handleCloseMenu: () => void;
	person: personFieldsFragment;
	secondaryAnchorEl: HTMLElement | null;
	secondaryOpen: boolean;
	setSecondaryAnchorEl: (value: SetStateAction<HTMLElement | null>) => void;
}

const SecondaryMenu = ({
	handleCloseMenu,
	person,
	secondaryOpen,
	secondaryAnchorEl,
	setSecondaryAnchorEl,
}: Props) => {
	const navigate = useNavigate();
	const { activeAccountIndex } = useActiveAccountIndex();

	const handleChangeAccount = (
		partialAccount: partialAccountFieldsFragment
	) => {
		const authenticated =
			person?.mfaAuthenticated || !partialAccount.mfaRequired;

		if (authenticated) {
			window.location.assign(
				`${window.location.origin}/u/${partialAccount.index}`
			);
		} else navigate(`/authenticate?return_url=/u/${partialAccount.index}`);

		handleCloseMenu();
	};

	return (
		<MUIMenu
			anchorEl={secondaryAnchorEl}
			anchorOrigin={{ horizontal: -11, vertical: -1 }}
			onClose={() => setSecondaryAnchorEl(null)}
			open={secondaryOpen}
			transformOrigin={{ horizontal: "right", vertical: "top" }}
		>
			{person.partialAccounts.map((partialAccount) => {
				if (partialAccount.index !== activeAccountIndex) {
					return (
						<MenuItem
							onClick={(e) => {
								e.preventDefault();
								handleChangeAccount(partialAccount);
							}}
							key={partialAccount._id}
							sx={menuItemSx({})}
						>
							<AccountIcon />
							<Box sx={accountTextWrapperSx}>
								<Typography noWrap>{person.name}</Typography>
								<Typography noWrap variant="caption1">
									{partialAccount.name}
								</Typography>
							</Box>
						</MenuItem>
					);
				}
			})}
		</MUIMenu>
	);
};
export default SecondaryMenu;
