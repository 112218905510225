import { Autocomplete, Stack, TextField, Typography } from "@mui/material";
import { Dispatch, HTMLAttributes } from "react";
import { sectionTitleSx } from "./common.styles";
import { getTimezoneWithOffset } from "./getTimezoneWithOffset";

export type TimezoneWithOffset = {
	timezone: string;
	displayTimezone: string;
	offset: string;
};

interface Props {
	timezone: string;
	onChange: Dispatch<string>;
}

export const ChangeTimezone = ({ timezone, onChange }: Props) => {
	const currentTimezone = getTimezoneWithOffset(timezone);

	const timezoneOptionsWithOffsets: TimezoneWithOffset[] =
		Intl.supportedValuesOf("timeZone").map((timezoneOption) =>
			getTimezoneWithOffset(timezoneOption)
		);

	return (
		<Stack>
			<Typography sx={sectionTitleSx} variant="h6">
				Time Zone
			</Typography>
			<Autocomplete
				ListboxProps={ListboxProps}
				autoHighlight
				disableClearable
				options={timezoneOptionsWithOffsets}
				getOptionLabel={(option) =>
					`${option.displayTimezone} ${option.offset}`
				}
				renderOption={RenderOption}
				renderInput={(params) => (
					<TextField
						{...params}
						fullWidth
						placeholder="Select Time Zone"
						value={timezone}
						size="small"
					/>
				)}
				value={currentTimezone}
				size="small"
				onChange={(_, newValue) => {
					onChange(newValue.timezone);
				}}
			/>
		</Stack>
	);
};

function RenderOption(
	props: HTMLAttributes<HTMLLIElement>,
	option: TimezoneWithOffset
) {
	return (
		<li {...props}>
			<Stack direction="row" spacing={1}>
				<Typography>{option.displayTimezone}</Typography>
				<Typography sx={{ color: "text.secondary" }}>
					{option.offset}
				</Typography>
			</Stack>
		</li>
	);
}

const ListboxProps = {
	style: {
		maxHeight: "400px",
	},
};
