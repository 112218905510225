/** based on previous logging at 'src/web/public/apps/core/config/window.js' in samdesk.io repo */
/**
 * TODO: https://samdesk.atlassian.net/browse/DEV-5049
 * 1. figure out what the mute on certain errors is about (we probably need sounds in the new dashboard to do that)
 */
import { debounce, uniqBy } from "lodash";

import client from "api/graphql/getClient";
import { graphql } from "gql/gql";
import { LogSAMErrorsParams, SAMErrorInput } from "gql/graphql";
import {
	ErrorToLog,
	isObjWithGQLErrorsArr,
	isObjWithMessage,
	isObjWithResponse,
	isObjWithStatus,
	normalizeError,
} from "./errorHelpers";

let errorQueue: ErrorToLog[] = [];
const errorTimes: number[] = [];
let loggedIntercomError = false;
const maxErrorCount = 50;
const maxErrorTime = 5 * 60 * 1000;

const logErrors = (err: unknown, fileName?: string) => {
	if (!window.SAM._env_.REACT_APP_LOG_ERRORS || !err) return;
	/** REST error ignoring */
	if (isObjWithResponse(err) && isObjWithStatus(err.response)) {
		const unauthenticated = err.response.status === 401;
		const unauthorized = err.response.status === 499;

		const skipLogging = unauthenticated || unauthorized;

		if (skipLogging) return;
	}

	/** GQL error ignoring */
	if (isObjWithResponse(err) && isObjWithGQLErrorsArr(err.response)) {
		const unauthenticated = !!err.response.errors.find(
			(error) => error.extensions.code === "UNAUTHENTICATED"
		);

		const skipLogging = unauthenticated;

		if (skipLogging) return;
	}

	/** normalized error handling (come in as unhandled rejections for whatever reason??) */
	if (isObjWithMessage(err)) {
		const maxAlertsTabs = err.message === "Cannot have more than 5 alerts tabs";
		const maxIncidentTabs =
			err.message === "Cannot have more than 10 incident tabs";
		const networkError = err.message === "Network Error";
		const unauthenticated =
			err.message.includes("unauthenticated") ||
			err.message === "Request failed with status code 401";
		const unauthorized = err.message === "Request failed with status code 499";

		const skipLogging =
			maxAlertsTabs ||
			maxIncidentTabs ||
			networkError ||
			unauthenticated ||
			unauthorized;

		if (skipLogging) return;
	}

	try {
		const now = Date.now();
		const error = normalizeError(err, fileName);

		const resizeObserverError =
			error?.message === "ResizeObserver loop limit exceeded";
		const abortError = error?.message === "AbortError";
		const networkRequestFailed = error?.message?.includes(
			"Network request failed"
		);
		const ignoreErrors =
			resizeObserverError || abortError || networkRequestFailed;

		if (ignoreErrors) return;

		//     if(error.errorMsg === 'NotAllowedError' && !!~error.stack.indexOf('play@[native code]')) {
		//         return AudioManager.mute(true);
		//     }

		/**
		 * this is for users getting Intercom errors from blocking iframes or something to that effect -
		 * we want to let the first error through so that we can keep track of which users are affected
		 * so that we can reach out and so that we have some way of knowing if this becomes a bigger issue
		 */
		const intercomError =
			error.message ===
				"The remote server machine does not exist or is unavailable" &&
			error.stack &&
			error.stack.indexOf("js.intercomcdn.com") > -1;

		if (intercomError) {
			if (loggedIntercomError) return;
			else loggedIntercomError = true;

			/** we also might as well shut Intercom down because it obviously isn't working in this case */
			if (window.Intercom) window.Intercom("shutdown");
		}

		/** add the error to our errorTimes array and filter down to errors in the last 5 minutes */
		errorTimes.push(now);
		const errorTimeAgo = now - maxErrorTime;
		const recentErrorTimes = errorTimes
			.filter((time) => time >= errorTimeAgo)
			.filter(Boolean);

		/** reload the page if users are getting 50+ errors per minute */
		if (recentErrorTimes.length >= maxErrorCount) {
			window.location.reload();
		}

		/** add the unique errors to the queue */
		const uniqueErrors = uniqBy(
			[...[error], ...errorQueue],
			(error) => error.message
		);
		errorQueue = uniqueErrors;

		debouncedLogErrors(errorQueue);
	} catch (err: unknown) {
		logErrorsPost([
			{
				columnNumber: null,
				fileName: "logError.ts OH MY!",
				lineNumber: null,
				message: (err as Error).message,
				referrer: document.referrer,
				stack: (err as Error).stack || null,
				type: (err as Error).type || null,
				url: window.location.href,
				userAgent: navigator?.userAgent ? navigator.userAgent : "IE",
			},
		]);
	}
};

export default logErrors;

/**
 * sends all unsent errors when the browser is closed
 */
export const forceLogErrors = () => {
	if (!window.SAM._env_.REACT_APP_LOG_ERRORS || errorQueue.length === 0) return;

	try {
		logErrorsPost(errorQueue);
	} catch (err) {
		logErrorsPost([
			{
				columnNumber: null,
				fileName: "logError.ts OH MY!",
				lineNumber: null,
				message: (err as Error).message,
				referrer: document.referrer,
				stack: (err as Error).stack || null,
				type: (err as Error).type || null,
				url: window.location.href,
				userAgent: navigator?.userAgent ? navigator.userAgent : "IE",
			},
		]);
	}
};

const logErrorsPost = (errors: SAMErrorInput[]) => {
	try {
		logSAMErrors({ errors });
		// Set error queue to empty here even if we logged a custom error
		errorQueue = [];
	} catch (err) {
		// log error to console here if is occured, since something would have gone wrong with our error logging code to get here
		console.error(err);
	}
};

/**
 * log errors if there hasn't been an error for 5 seconds
 */
const debouncedLogErrors = debounce(logErrorsPost, 5000);

const useLogSAMErrorsDocument = graphql(/* GraphQL */ `
	mutation logSAMErrors($params: LogSAMErrorsParams!) {
		logSAMErrors(params: $params) {
			error {
				code
				message
			}
			success
		}
	}
`);

function logSAMErrors(params: LogSAMErrorsParams) {
	if (window.SAM.userId) {
		client(window.SAM.userId)
			.request(useLogSAMErrorsDocument, { params })
			.then((res) => res.logSAMErrors);
	} else {
		client()
			.request(useLogSAMErrorsDocument, { params })
			.then((res) => res.logSAMErrors);
	}
}
