import {
	AccountCircleOutlined,
	DisplaySettings,
	SettingsOutlined,
} from "@mui/icons-material";
import { Grid } from "@mui/material";
import SettingsPageTitle from "ui-library/SettingsPageTitle";

import SettingsCard from "./SettingsCard";
import useController from "./useController";

const Index = () => {
	const { isAdmin } = useController();

	return (
		<>
			<SettingsPageTitle>Settings</SettingsPageTitle>
			<Grid
				columnSpacing={{ md: 3, sm: 2, xs: 1 }}
				columns={3}
				container
				rowSpacing={1}
			>
				<SettingsCard
					content="Update your samdesk alert preferences"
					destination="alerts"
					heading="Alert Preferences"
					icon={<SettingsOutlined />}
				/>
				<SettingsCard
					content="Change or update your personal samdesk information"
					destination="profile"
					heading="Profile Settings"
					icon={<AccountCircleOutlined />}
				/>
				{isAdmin && (
					<SettingsCard
						content="Update your samdesk account information"
						destination="account"
						heading="Account Settings"
						icon={<DisplaySettings />}
					/>
				)}
			</Grid>
		</>
	);
};

export default Index;
