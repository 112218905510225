import { ExpandMore } from "@mui/icons-material";
import {
	Box,
	ClickAwayListener,
	IconButton,
	Popover,
	Tooltip,
} from "@mui/material";
import useGetDashboard from "api/queries/useGetDashboard";
import layout from "common/layoutDefinitions";
import { useEffect, useState } from "react";
import styled from "styled-components";
import theme from "theme";

import NavTabs from "./NavTabs";

interface Props {
	highlightTab: boolean;
	visibleTabs: number;
}

const SeeAllTab = ({ highlightTab, visibleTabs }: Props) => {
	const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
	const [openPopover, setOpenPopover] = useState(false);

	const { data: dashboard } = useGetDashboard();

	const tabs = dashboard?.tabs || [];
	const hideSeeAllTab = tabs.length <= visibleTabs;

	useEffect(() => {
		if (hideSeeAllTab) {
			setOpenPopover(false);
			setAnchorEl(null);
		}
	}, [hideSeeAllTab]);

	if (hideSeeAllTab) return null;

	return (
		<>
			<Tooltip title="See all tabs">
				<SeeAll $highlightTab={highlightTab}>
					<IconButton
						onClick={(e) => {
							setAnchorEl(e.currentTarget);
							setOpenPopover(true);
						}}
					>
						<ExpandMore fontSize="small" />
					</IconButton>
				</SeeAll>
			</Tooltip>

			{/* Navtabs dropdown menu */}
			<Popover
				PaperProps={{ sx: paperPropsSx }}
				anchorEl={anchorEl}
				anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
				open={openPopover}
			>
				<ClickAwayListener onClickAway={() => setOpenPopover(false)}>
					<Box>
						<NavTabs isOverflowComponent={true} visibleTabs={visibleTabs} />
					</Box>
				</ClickAwayListener>
			</Popover>
		</>
	);
};

export default SeeAllTab;

/** STYLES */

const SeeAll = styled(Box)<{ $highlightTab: boolean }>`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-self: flex-end;
	align-items: center;
	height: 50px;
	width: 50px;
	margin-bottom: -${layout.navBar.border}px;
	background: ${({ $highlightTab }) =>
		$highlightTab ?
			`${theme.palette.grey[900]}`
		:	`${theme.palette.background.default}`};
	border: ${layout.navBar.border}px solid ${theme.palette.border.main};
	border-bottom: ${({ $highlightTab }) =>
		$highlightTab ?
			`${layout.navBar.border}px solid ${theme.palette.grey[900]}`
		:	`${layout.navBar.border}px solid ${theme.palette.border.main}`};
	border-radius: 4px 4px 0 0;
`;

const paperPropsSx = {
	border: `1px solid ${theme.palette.border.main}`,
	height: "fit-content",
	marginLeft: "-25px",
	marginTop: "25px",
	maxHeight: "75vh",
};
