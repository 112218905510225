import { Card } from "@mui/material";
import styled from "styled-components";

export const SectionCard = styled(Card)`
	background: transparent;
	padding: 0 1.25rem 1.25rem;
	margin: 0.75rem 0 0.75rem;

	h6 {
		margin-top: 1.25rem;
		margin-bottom: 1rem;
		font-weight: 600;
	}
`;
