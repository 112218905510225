// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
	--radius-base: 4px;
	--radius-round: 50%;
}
`, "",{"version":3,"sources":["webpack://./../../packages/design/src/radii.css"],"names":[],"mappings":"AAAA;CACC,kBAAkB;CAClB,mBAAmB;AACpB","sourcesContent":[":root {\n\t--radius-base: 4px;\n\t--radius-round: 50%;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
