import useGetUser from "api/queries/useGetUser";

interface FormatDistanceProps {
	value: number | undefined;
}

export const FormatDistance = ({ value }: FormatDistanceProps) => {
	const { data: user } = useGetUser();
	const distanceUnit = user?.preferences.distance_unit;

	if (value == undefined) {
		return null;
	} else if (distanceUnit === "IMPERIAL") {
		/** for distances under 0.1 Mi, show in ft */
		if (value < 160.934) return <>{Math.round(value * 3.2808)}ft</>;
		/** otherwise show in Mi */ else {
			return <>{((value / 1000) * 0.621371).toFixed(1)}Mi</>;
		}
	} else {
		/** metric is default for users that haven't set a value yet */
		/** for distances under 1 KM, show in m */
		if (value < 1000) return <>{Math.round(value)}m</>;
		/** otherwise show in KM */ else return <>{(value / 1000).toFixed(1)}km</>;
	}
};
