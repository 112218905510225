import { useRollbar } from "@rollbar/react";
import useCancelChangeEmailRequest from "api/mutations/useCancelChangeEmailRequest";
import useSubmitChangeEmailRequest from "api/mutations/useSubmitChangeEmailRequest";
import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import logErrors from "utils/logErrors";
import useSnackbar from "utils/useSnackbar";

const useController = () => {
	const [searchParams] = useSearchParams();
	const {
		mutateAsync: submitChangeEmailRequest,
		data: submitChangeEmailData,
		isLoading: isLoadingSubmitChangeEmail,
	} = useSubmitChangeEmailRequest();
	const {
		mutateAsync: cancelChangeEmailRequest,
		data: cancelChangeEmailData,
		isLoading: isLoadingCancelEmailRequest,
	} = useCancelChangeEmailRequest();

	const navigate = useNavigate();
	const { handleError } = useSnackbar();
	const rollbar = useRollbar();

	const cancelRequest = searchParams.get("cancel") === "true";
	const code = searchParams.get("code");

	const errorMessage =
		submitChangeEmailData?.error?.message ??
		cancelChangeEmailData?.error?.message;
	const successEmailChange = submitChangeEmailData?.success;
	const successCancellingEmailChange = cancelChangeEmailData?.success;

	const isLoading = isLoadingSubmitChangeEmail || isLoadingCancelEmailRequest;

	const handleContinueToSamdesk = () => {
		navigate("/");
	};

	/** submit email change request on page load if params only include a code */
	useEffect(() => {
		if (code && !cancelRequest) {
			const controller = new AbortController();
			submitChangeEmailRequest({ controller, params: { code } })
				.then((variables) => {
					if (variables.error) {
						/** error shows in the ui, but we still need to log them */
						logErrors(variables.error);
						rollbar.error(new Error("Error in submit change email request"), {
							error: variables.error,
						});
					}
				})
				.catch((error) => handleError(error));

			return () => controller.abort();
		}
	}, [cancelRequest, code, handleError, submitChangeEmailRequest, rollbar]);

	/** cancel email change request on page load if params include a cancel param */
	useEffect(() => {
		if (cancelRequest && code) {
			const controller = new AbortController();
			cancelChangeEmailRequest({ controller, params: { code } })
				.then((variables) => {
					if (variables.error) {
						/** error shows in the ui, but we still need to log them */
						logErrors(variables.error);
						rollbar.error(new Error("Error in cancel change email request"), {
							error: variables.error,
						});
					}
				})
				.catch((error) => handleError(error));

			return () => controller.abort();
		}
	}, [cancelRequest, code, handleError, cancelChangeEmailRequest, rollbar]);

	return {
		errorMessage,
		handleContinueToSamdesk,
		isLoading,
		successCancellingEmailChange,
		successEmailChange,
	};
};

export default useController;
