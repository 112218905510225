import { MoreVert } from "@mui/icons-material";
import {
	Box,
	Button,
	ClickAwayListener,
	IconButton,
	Menu,
	MenuItem,
	Popover,
	TextField,
	Typography,
} from "@mui/material";
import useCreateDashboardTab from "api/mutations/useCreateDashboardTab";
import useDeleteDashboardTab from "api/mutations/useDeleteDashboardTab";
import useUpdateDashboardTab from "api/mutations/useUpdateDashboardTab";
import useGetDashboard from "api/queries/useGetDashboard";
import { AlertsTabData } from "autoGenSubTypes";
import { DashboardTabType } from "gql/graphql";
import { MutableRefObject, forwardRef, useState } from "react";
import theme from "theme";
import useNavigateWithAccount from "utils/useNavigateWithAccount";
import useSnackbar from "utils/useSnackbar";
import { showPrompt } from "@samdesk/components/Prompt";

import useNavigateAfterTabDelete from "./useNavigateAfterTabDelete";

interface Props {
	alertsData: AlertsTabData;
	tabKey: string;
}

const TabMenu = forwardRef<HTMLDivElement, Props>(
	({ alertsData, tabKey }, ref) => {
		const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
		const [openPopover, setOpenPopover] = useState(false);
		const [newTabName, setNewTabName] = useState("");

		const {
			mutateAsync: createDashboardTab,
			isLoading: isLoadingCreateDashboardTab,
		} = useCreateDashboardTab();
		const {
			mutateAsync: updateDashboardTab,
			isLoading: isLoadingUpdateDashboardTab,
		} = useUpdateDashboardTab();
		const {
			mutateAsync: deleteDashboardTab,
			isLoading: isLoadingDeleteDashboardTab,
		} = useDeleteDashboardTab();
		const { isFetching: isFetchingDashboard } = useGetDashboard();

		const navigate = useNavigateWithAccount();
		const navigateAfterTabDelete = useNavigateAfterTabDelete();

		const { handleError } = useSnackbar();

		/** Menu handlers */
		const handleClickRename = () => {
			setOpenPopover(true);
			/** I kind of hate this, but it's the simplest way to trigger a focus on a disappearing input that I could come up with */
			setTimeout(() => {
				const renameInput = document.getElementById("renameInput");
				renameInput?.focus();
			}, 100);
		};

		const handleClickDelete = async () => {
			setAnchorEl(null);
			const confirmPrompt = await showPrompt({
				promptType: "confirm",
				title: "Are you sure you want to delete this tab?",
				description:
					"Your streams will remain available in the stream manager.",
				confirmButton: { label: "Delete", intent: "destruction" },
			});
			if (confirmPrompt.state === "ok") {
				await handleSubmitDelete();
			}
		};

		/** Response handlers */
		const handleSubmitRename = () => {
			if (!newTabName) return;

			updateDashboardTab({
				key: tabKey,
				tab: {
					alerts_tab_data: {
						bottom: alertsData.bottom,
						name: newTabName,
						top: alertsData.top,
					},
					type: DashboardTabType.ALERTS,
				},
			})
				.then((variables) => {
					if (variables.error) {
						handleError(variables.error);
					}
				})
				.catch((error) => handleError(error));

			setOpenPopover(false);
			setAnchorEl(null);
		};

		const handleSubmitDelete = async () => {
			if (!tabKey) return;

			deleteDashboardTab({ params: { key: tabKey } })
				.then((variables) => {
					if (variables.success) {
						navigateAfterTabDelete(tabKey);
					} else if (variables.error) {
						handleError(variables.error);
					}
				})
				.catch((error) => handleError(error));
		};

		const handleSubmitDuplicate = () => {
			createDashboardTab({
				params: {
					tab: {
						alerts_tab_data: {
							bottom: alertsData.bottom,
							name: alertsData.name,
							top: alertsData.top,
						},
						type: DashboardTabType.ALERTS,
					},
				},
			})
				.then((variables) => {
					if (variables.success && variables.tab) {
						navigate(`/alerts/${variables.tab.key}`);
					}
				})
				.catch((error) => handleError(error));

			setAnchorEl(null);
		};

		const isBusy =
			isLoadingCreateDashboardTab ||
			isLoadingUpdateDashboardTab ||
			isLoadingDeleteDashboardTab ||
			isFetchingDashboard;

		return (
			<>
				<IconButton
					disabled={isBusy}
					onClick={(e) => setAnchorEl(e.currentTarget)}
				>
					<MoreVert fontSize="small" />
				</IconButton>
				<Menu
					anchorEl={anchorEl}
					onClose={() => setAnchorEl(null)}
					open={Boolean(anchorEl)}
					sx={tabDropdownSx}
				>
					<MenuItem onClick={handleClickRename}>Rename Tab</MenuItem>
					<MenuItem onClick={handleClickDelete}>Delete Tab</MenuItem>
					<MenuItem onClick={handleSubmitDuplicate}>Duplicate Tab</MenuItem>
				</Menu>

				{/* Popover (rename tab) */}
				{ref && openPopover && anchorEl && (
					<Popover
						anchorEl={(ref as MutableRefObject<HTMLDivElement>).current}
						anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
						open={openPopover}
					>
						<ClickAwayListener onClickAway={() => setOpenPopover(false)}>
							<Box sx={renameTabContentSx}>
								<Typography fontWeight="600" variant="h3">
									Rename Tab
								</Typography>
								<TextField
									onKeyDown={(e) => {
										if (e.key === "Enter" && newTabName) {
											handleSubmitRename();
										}
									}}
									fullWidth
									id="renameInput"
									label={"Rename tab"}
									onChange={(e) => setNewTabName(e.target.value)}
									size="small"
									sx={renameTabFieldSx}
									value={newTabName}
									variant="outlined"
								/>
								<Box sx={cancelSaveButtonWrapperSx}>
									<Button
										color="secondary"
										onClick={() => setOpenPopover(false)}
										sx={cancelButtonSx}
										variant="outlined"
									>
										Cancel
									</Button>
									<Button
										disabled={!newTabName}
										onClick={handleSubmitRename}
										variant="contained"
									>
										Save
									</Button>
								</Box>
							</Box>
						</ClickAwayListener>
					</Popover>
				)}
			</>
		);
	}
);

export default TabMenu;

/** Styles */

const cancelButtonSx = {
	marginRight: "1rem",
};

const cancelSaveButtonWrapperSx = {
	display: "flex",
	justifyContent: "center",
};

const renameTabContentSx = {
	alignItems: "center",
	backgroundColor: "grey.900",
	border: `1px solid ${theme.palette.border.main}`,
	borderRadius: "4px",
	display: "flex",
	flexDirection: "column",
	height: "175px",
	justifyContent: "center",
	padding: "1.5rem 1.5rem 1.5rem",
	width: "380px",
};

const renameTabFieldSx = {
	margin: "1rem 0",
};

const tabDropdownSx = {
	"& .MuiMenu-list": {
		background: "grey.800",
		width: "195px",
	},
	"& .MuiMenuItem-gutters": {
		fontSize: theme.typography.p2.fontSize,
	},
};
