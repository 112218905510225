import { getTimeZoneName } from "utils/getTimeZoneName";
import { TimezoneWithOffset } from "./ChangeTimezone";

export function getTimezoneWithOffset(timezone: string) {
	const timezoneOffset = getTimeZoneName(timezone, "shortOffset");
	const timezoneAbbreviation = getTimeZoneName(timezone, "long");

	const timezoneWithOffset: TimezoneWithOffset = {
		timezone,
		displayTimezone: `${timezone} - ${timezoneAbbreviation}`,
		offset: `(${timezoneOffset})`,
	};

	return timezoneWithOffset;
}
