export function getActiveUserIndex() {
	const path = window.location.pathname.split("/");

	let userIndex = 0;

	if (path[1] === "u" && parseInt(path[2], 10) >= 0) {
		userIndex = parseInt(path[2], 10);
	}

	return userIndex;
}
