import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

const useController = () => {
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();

	const formAction = `${
		window.SAM._env_.REACT_APP_BASE_API_URL
	}/signin/sso?return_url=${encodeURIComponent(
		window.SAM._env_.REACT_APP_V2_BASE_URL
	)}`;

	const returnUrlParam = searchParams.get("return_url");

	const errorMessage = searchParams.get("error");

	const misconfiguredParam = searchParams.get("misconfigured");
	const misconfiguredError = misconfiguredParam === "true";

	const emailMismatchParam = searchParams.get("email_mismatch");
	const emailMismatchError = emailMismatchParam === "true";

	/** on success you should have only a return url in the param, in which case we want to go there */
	useEffect(() => {
		if (
			returnUrlParam &&
			!errorMessage &&
			!misconfiguredError &&
			!emailMismatchError
		) {
			navigate(returnUrlParam);
		}
	}, [
		emailMismatchError,
		errorMessage,
		misconfiguredError,
		navigate,
		returnUrlParam,
	]);

	return { emailMismatchError, errorMessage, formAction, misconfiguredError };
};

export default useController;
