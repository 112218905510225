import { Box, MenuItem, Skeleton, Typography } from "@mui/material";
import { personFieldsFragment } from "gql/graphql";
import AccountIcon from "icons/account.svg";
import SwitchAccountIcon from "icons/switchAccount.svg";
import { SetStateAction } from "react";
import { useActiveAccountIndex } from "utils/useActiveAccount";
import { menuItemSx } from "./Menu";
import SecondaryMenu from "./SecondaryMenu";

interface Props {
	handleCloseMenu: () => void;
	isError: boolean;
	isInitialLoading: boolean;
	isSuccess: boolean;
	person: personFieldsFragment | undefined;
	secondaryAnchorEl: HTMLElement | null;
	secondaryOpen: boolean;
	setSecondaryAnchorEl: (value: SetStateAction<HTMLElement | null>) => void;
}

const MenuAccountOptions = ({
	handleCloseMenu,
	isError,
	isInitialLoading,
	isSuccess,
	person,
	secondaryAnchorEl,
	secondaryOpen,
	setSecondaryAnchorEl,
}: Props) => {
	const { activeAccountIndex } = useActiveAccountIndex();

	if (isInitialLoading || activeAccountIndex == undefined) {
		return (
			<MenuItem sx={menuItemSx({ canClickMenuItem: false })}>
				<Skeleton width="100%" />
			</MenuItem>
		);
	}

	if (isError) {
		return (
			<MenuItem sx={menuItemSx({ canClickMenuItem: false })}>
				<Typography component="p" variant="caption1">
					There was an issue loading your account
				</Typography>
			</MenuItem>
		);
	}

	if (isSuccess && !!person) {
		const hasMultipleAccounts = person.partialAccounts.length > 1;

		const activePartialAccount = person.partialAccounts.find(
			(partialAccount) => partialAccount.index === activeAccountIndex
		);

		if (!activePartialAccount) return null;

		return (
			<>
				<MenuItem
					onClick={(e) => {
						if (hasMultipleAccounts) setSecondaryAnchorEl(e.currentTarget);
					}}
					sx={menuItemSx({ canClickMenuItem: hasMultipleAccounts })}
				>
					{hasMultipleAccounts ?
						<SwitchAccountIcon />
					:	<AccountIcon />}
					<Box sx={accountTextWrapperSx}>
						<Typography noWrap>{person.name}</Typography>
						<Typography noWrap variant="caption1">
							{activePartialAccount.name}
						</Typography>
					</Box>
				</MenuItem>

				<SecondaryMenu
					handleCloseMenu={handleCloseMenu}
					person={person}
					secondaryAnchorEl={secondaryAnchorEl}
					secondaryOpen={secondaryOpen}
					setSecondaryAnchorEl={setSecondaryAnchorEl}
				/>
			</>
		);
	}

	return (
		<MenuItem sx={menuItemSx({ canClickMenuItem: false })}>
			<Typography component="p" variant="caption1">
				There was an issue loading your account
			</Typography>
		</MenuItem>
	);
};

export default MenuAccountOptions;

/** Styles */

export const accountTextWrapperSx = {
	lineHeight: "0.8em",
	marginLeft: "5px",
};
