const arrayToSentence = (arr: (string | undefined)[] | undefined) => {
	if (!arr || arr.length === 0) return "";

	const cleanArr = arr.filter(Boolean);

	if (!cleanArr || cleanArr.length === 0) return "";

	return (
		cleanArr.slice(0, -2).join(", ") +
		(cleanArr.slice(0, -2).length ? ", " : "") +
		cleanArr.slice(-2).join(" and ")
	);
};

export default arrayToSentence;
