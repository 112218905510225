import { BasicError } from "gql/graphql";
import arrayToSentence from "./arrayToSentence";

export type ResponseWithErrors = {
	response: { errors: [{ message: string }] };
};

export function getNormalizedErrorMessage(error: unknown) {
	/** handles if you pass a basic string in as the error */
	let stringErrorMessage: string | undefined;
	if (typeof error === "string") {
		stringErrorMessage = error;
	}

	/** handles an error response with multiple error messages (response that isn't a 2xx code) */
	const responseErrorMessages = arrayToSentence(
		(error as ResponseWithErrors)?.response?.errors?.map(
			(error) => error.message
		)
	);

	/** handles an error response with one error message */
	const responseErrorMessage = (
		error as { response: { data: { error: { message: string } } } }
	)?.response?.data?.error?.message;

	/** handles a gql 200 response with an error message */
	const GQLErrorMessage = (error as BasicError)?.message;

	/**
	 * prioritizes all error messages and decides what to show
	 * 1. a message that is passed an the optional second arg
	 * 2. a string if it's passed as the first arg
	 * 3. all error messages in an error response (non 200 response)
	 * 4. a single error message in an error response (non 200 response)
	 * 5. the gql error message in successful (200) gql response
	 */
	return (
		stringErrorMessage ||
		responseErrorMessages ||
		responseErrorMessage ||
		GQLErrorMessage
	);
}
