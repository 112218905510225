import { Box, CircularProgress } from "@mui/material";

const CenteredCircularProgress = () => {
	return (
		<Box sx={wrapperSx}>
			<CircularProgress />
		</Box>
	);
};

export default CenteredCircularProgress;

/** Styles */

const wrapperSx = {
	left: "50%",
	position: "absolute",
	top: "50%",
	transform: "translate(-50%, -50%)",
};
