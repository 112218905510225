import { create } from "zustand";

type State = {
	modalOpen: boolean;
	setModalOpen: (modalOpen: boolean) => void;
};

export const useSearchStore = create<State>()((set) => ({
	modalOpen: false,
	setModalOpen: (modalOpen) => set(() => ({ modalOpen })),
}));
