import useGetUser from "api/queries/useGetUser";
import { useParams } from "react-router-dom";

export const useActiveAccountIndex = () => {
	const { userAccountIndex } = useParams();

	const noAccountSelected =
		window.location.pathname.includes("choose_account") ||
		window.location.pathname.includes("signin") ||
		window.location.pathname.includes("authenticate") ||
		window.location.pathname.includes("reset_password");
	const activeAccountIndex =
		noAccountSelected ? null : Number(userAccountIndex) || 0;

	return { activeAccountIndex };
};

export const useActiveAccount = () => {
	const {
		data: user,
		error,
		isError,
		isLoading,
		isInitialLoading,
		isSuccess,
	} = useGetUser();

	return {
		_id: user?.account._id,
		account: user?.account,
		active: user?.account.active,
		error: error,
		intercom: user?.intercom,
		isError: isError,
		isInitialLoading: isInitialLoading,
		isLoading: isLoading,
		isSuccess: isSuccess,
		mfaRequired: user?.account.preferences.two_factor_authentication,
		name: user?.account.name,
		personName: user?.person.name,
		user: user,
		userId: user?._id,
	};
};
