import { Link, LinkProps } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

interface LinkRouterProps extends LinkProps {
	replace?: boolean;
	to: string;
}

interface Props {
	index: number;
	overrideName?: { [key: string]: string };
	pathArray: string[];
	pathSegment: string;
}

const Breadcrumb = ({ index, overrideName, pathArray, pathSegment }: Props) => {
	/** Wrap react router link component in the mui counterpart to be usable with breadcrumbs */
	const BreadcrumbLink = (props: LinkRouterProps) => (
		<Link {...props} component={RouterLink} />
	);

	const currentPath = pathArray.length - 1 === index;

	/** Returns the relative path (route) for each pathSegment, adjusted for u/# being in the url or not  */
	const fullPathAsArray = location.pathname.split("/").slice(1);
	const offset = fullPathAsArray[0] === "u" ? 3 + index : 1 + index;
	const relativePath = fullPathAsArray.slice(0, offset).join("/");

	return (
		<BreadcrumbLink
			aria-current={currentPath ? "page" : false}
			key={pathSegment}
			sx={breadcrumbSx(currentPath)}
			to={`/${relativePath}`}
		>
			{overrideName?.[pathSegment] ?? pathSegment}
		</BreadcrumbLink>
	);
};

export default Breadcrumb;

/** Style */

const breadcrumbSx = (currentPath: boolean) => ({
	"&:hover": {
		color: "text.secondary",
		textDecoration: "underline",
	},
	color: currentPath ? "text.secondary" : "text.disabled",
	pointerEvents: currentPath ? "none" : "auto",
	textDecoration: "none",
	textTransform: "capitalize",
});
