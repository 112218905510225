// TODO: https://samdesk.atlassian.net/browse/DEV-5088 - it's worth making a more performant function to de-dupe alerts.
import { InfiniteData } from "@tanstack/react-query";
import { alertFieldsFragment, incidentAlertFieldsFragment } from "gql/graphql";

/**
 * flattens and dedupes a single infinite query of listAlerts data
 *
 * @param alerts the alerts that are returned from the infinite query
 * @returns an array of alerts that are deduped
 */
export const getFlatAlerts = (
	alerts: InfiniteData<alertFieldsFragment[]> | undefined
) => {
	return alerts?.pages.flat() ?? [];
};

/**
 * flattens two different infinite queries of listAlerts data and combines them.
 */
export const getCombinedAlerts = (
	alertsTop: InfiniteData<alertFieldsFragment[]> | undefined,
	alertsBottom: InfiniteData<alertFieldsFragment[]> | undefined
) => {
	const topAlertData = alertsTop?.pages.flat() ?? [];
	const bottomAlertData = alertsBottom?.pages.flat() ?? [];
	/** Combine all alerts */
	const allAlerts = [...topAlertData, ...bottomAlertData];

	return allAlerts;
};

export function isIncidentAlert(
	alert: alertFieldsFragment
): alert is incidentAlertFieldsFragment {
	return alert.__typename === "IncidentAlert";
}
