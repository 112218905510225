import useGetDashboard from "api/queries/useGetDashboard";
import { AlertsTab, Tab } from "autoGenSubTypes";

const useCurrentAlertsTab = (tabKey: string | undefined) => {
	const { data: dashboard, isInitialLoading: isInitialLoadingCurrentTab } =
		useGetDashboard();

	const getCurrentAlertsTab = (tab: Tab): tab is AlertsTab => {
		return tab.__typename === "AlertsTab" && tab.key === tabKey;
	};

	const currentAlertsTab = dashboard?.tabs.find(getCurrentAlertsTab);

	return { currentAlertsTab, isInitialLoadingCurrentTab };
};

export default useCurrentAlertsTab;
