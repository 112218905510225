import styled from "styled-components";
import theme from "theme";

import { markerAnimation } from "./Map/GoogleMapStyleWrapper";

export const AnimatedMarker = styled.div`
	background-color: ${theme.palette.primary.main};
	border-radius: 50px;
	box-shadow:
		0 0 0 1px ${theme.palette.grey[600]},
		0 0 8px 0 ${theme.palette.grey[400]};
	cursor: inherit;
	height: 16px;
	margin: 0 auto;
	position: relative;
	width: 16px;

	${markerAnimation}

	/** markerAnimation overrides */
    &:before, &:after {
		top: -32px;
		left: -32px;
	}
`;
