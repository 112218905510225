import { PaletteMode, createTheme } from "@mui/material";

/**
 * Refer to https://mui.com/material-ui/customization/default-theme/ for object template,
 * any properties that are not included by default must be extended
 */

const darkPalette = {
	background: {
		default: "var(--color-surface)",
		paper: "#10171E",
	},
	border: {
		light: "#30445F",
		main: "#1E2B3C",
	},
	/** custom section of theme */
	eventType: {
		blue: "#2273E0",
		grey: "#8A8E93",
		orange: "#F2994A",
		purple: "#9B51E0",
		red: "#EB5757",
		teal: "#68BABF",
		white: "#E0E1E3",
		climate: "#2273E0",
		emergency_and_safety: "#EB5757",
		geopolitics: "#F2994A",
		infrastructure: "#9B51E0",
		health: "#68BABF",
		cybersecurity: "#FF61C0",
		impact: "#FFF68F",
		life_cycle: "#1CB469",
	},
	grey: {
		100: "#E0E1E3",
		500: "#8A8E93",
		600: "#32383E",
		700: "#2A3037",
		800: "#21272E",
		900: "#192027",
	},
	highlight: {
		background: "#837135",
		text: "#F3DFA1",
	},
	primary: {
		dark: "#1C7D45",
		darker: "#176839",
		light: "#4DAB75",
		lighter: "#7AC098",
		main: "#219653",
	},
	secondary: {
		main: "#A1AFCB",
		light: "#fff",
	},
	text: {
		disabled: "#515F7B",
		primary: "#E0E1E3",
		secondary: "#ABB0BB",
	},
	warning: {
		critical: "#AC3131",
		light: "#F9DEDC",
		main: "#57130F",
	},
	// Note: Trying out a color convention here ahead of doing it more properly
	//       in a Design system.
	hints: {
		surface: "#C2D0E9",
		onSurface: "#060D1A",
	},
};

/** everything thing is hotpink as a placeholder :) */
const lightPalette = {
	background: {
		default: "#FF69B4",
		paper: "#FF69B4",
	},
	border: {
		light: "#FF69B4",
		main: "#FF69B4",
	},
	/** custom section of theme */
	eventType: {
		blue: "#FF69B4",
		grey: "#FF69B4",
		orange: "#FF69B4",
		purple: "#FF69B4",
		red: "#FF69B4",
		teal: "#FF69B4",
	},
	grey: {
		100: "#FF69B4",
		600: "#FF69B4",
		800: "#FF69B4",
		830: "#FF69B4",
		860: "#FF69B4",
		900: "#FF69B4",
	},
	highlight: {
		background: "#FF69B4",
		text: "#FF69B4",
	},
	primary: {
		dark: "#FF69B4",
		darker: "#FF69B4",
		light: "#FF69B4",
		lighter: "#FF69B4",
		main: "#FF69B4",
	},
	secondary: {
		main: "#FF69B4",
	},
	text: {
		disabled: "#FF69B4",
		primary: "#FF69B4",
		secondary: "#FF69B4",
	},
	warning: {
		light: "#FF69B4",
		main: "#FF69B4",
	},
};

const typography = {
	caption1: {
		fontSize: ".75rem",
		lineHeight: 1.35,
	},
	caption2: {
		fontSize: ".625rem",
		lineHeight: 1.35,
	},
	fontFamily: ["Source Sans Pro", "Helvetica", "Arial", "sans-serif"].join(","),
	fontWeightBold: 600,
	h1: {
		fontSize: "1.5rem",
		lineHeight: 1.2,
	},
	h2: {
		fontSize: "1.375rem",
		lineHeight: 1.25,
	},
	h3: {
		fontSize: "1.125rem",
		lineHeight: 1.25,
	},
	p1: {
		fontSize: "1rem",
		lineHeight: 1.3,
	},
	p2: {
		fontSize: ".875rem",
		lineHeight: 1.35,
	},
};

const getDesignTokens = (mode: PaletteMode) => ({
	components: {
		MuiAccordion: {
			styleOverrides: {
				root: {
					".MuiAccordionDetails-root": {
						backgroundColor: darkPalette.background.default,
					},
					border: `1px solid ${darkPalette.border.main}`,
					margin: "0",
					// https://github.com/mui/material-ui/issues/8928
					position: "unset" as const,
				},
			},
		},
		MuiButton: {
			defaultProps: {
				size: "large" as const,
				style: {
					whiteSpace: "nowrap" as const,
				},
			},
			variants: [
				{
					props: { variant: "warning" as const },
					style: {
						"&:hover": {
							background: darkPalette.warning.critical,
							border: `1px solid ${darkPalette.warning.critical}`,
						},
						border: `1px solid ${darkPalette.border.light}`,
						color: darkPalette.text.primary,
						// https://github.com/mui/material-ui/issues/16307
						textTransform: "none" as const,
					},
				},
			],
		},
		MuiButtonBase: {
			defaultProps: {
				disableRipple: true,
			},
			styleOverrides: {
				root: {
					"&.MuiButton-outlinedSecondary": {
						borderColor: darkPalette.border.light,
						color: darkPalette.text.primary,
					},
					"&.MuiButton-sizeLarge": {
						height: "40px",
						minWidth: "64px",
					},
					"&.MuiButton-sizeLarge, &.MuiButton-sizeSmall": {
						borderRadius: "2px",
						textTransform: "none",
					},
					"&.MuiButton-sizeSmall": {
						height: "24px",
						minWidth: "48px",
					},
				},
			},
		},
		MuiChip: {
			styleOverrides: {
				root: {
					borderRadius: "2px",
				},
			},
		},
		MuiCssBaseline: {
			styleOverrides: `
                body {
                    -webkit-font-smoothing: auto;
                    overflow-x: auto;
                    overflow-y: hidden;
                };
            `,
		},
		MuiDialog: {
			styleOverrides: {
				root: {
					".MuiDialog-paper": {
						background: darkPalette.grey[900],
					},
				},
			},
		},
		MuiIconButton: {
			styleOverrides: {
				root: {
					"&.Mui-disabled": {
						color: darkPalette.text.disabled,
					},
					"&:hover": {
						backgroundColor: darkPalette.grey[800],
						color: darkPalette.grey[100],
					},
					color: darkPalette.text.secondary,
				},
			},
		},
		MuiMenu: {
			styleOverrides: {
				root: `
                    ul {
                        padding: 0;
                        background: ${darkPalette.grey[900]};
                        border: 1px solid ${darkPalette.border.main};
                        border-radius: 4px;
                    }`,
			},
		},
		MuiSwitch: {
			styleOverrides: {
				root: {
					".Mui-checked": {
						".MuiSwitch-thumb": {
							backgroundColor: darkPalette.primary.main,
						},
					},
					".MuiSwitch-thumb": {
						backgroundColor: darkPalette.grey[500],
					},
				},
				track: {
					backgroundColor: darkPalette.grey[600],
				},
			},
		},
		MuiTable: {
			styleOverrides: {
				root: {
					"&.MuiTableCell-head": {
						color: darkPalette.grey[100],
						fontWeight: 600,
					},
					".MuiTableCell-root": {
						borderColor: `${darkPalette.grey[600]}`,
						color: darkPalette.grey[100],
						fontWeight: 300,
					},
					backgroundColor: darkPalette.background.default,
					border: `1px solid ${darkPalette.grey[600]}`,
				},
			},
		},
		MuiTooltip: {
			styleOverrides: {
				tooltip: {
					fontSize: "0.875rem",
				},
			},
		},
	},
	palette: {
		mode,
		...(mode === "light" ? lightPalette : darkPalette),
	},
	typography: typography,
});

/**
 * hard coded to dark mode for now, but this is getting us setup for a possible future toggle to light mode
 * https://mui.com/material-ui/customization/dark-mode/
 */
export const theme = createTheme(getDesignTokens("dark"));

export default theme;

export const baseModalStyle = {
	bgcolor: "background.paper",
	border: `1px solid ${theme.palette.border.light}`,
	borderRadius: "4px",
	boxShadow: 24,
	left: "50%",
	outline: "none",
	padding: "24px",
	position: "absolute",
	top: "50%",
	transform: "translate(-50%, -50%)",
	width: 400,
};

export const defaultBackgroundHoverTransition =
	"background-color 250ms cubic-bezier(0.4,0,0.2,1) 0ms,box-shadow 250ms cubic-bezier(0.4,0,0.2,1) 0ms,border-color 250ms cubic-bezier(0.4,0,0.2,1) 0ms,color 250ms cubic-bezier(0.4,0,0.2,1) 0ms";
