import { Stack, TextField, Typography } from "@mui/material";
import { Dispatch } from "react";
import { sectionTitleSx } from "./common.styles";

interface Props {
	name: string;
	onChange: Dispatch<string>;
}

export const ChangeName = ({ name, onChange }: Props) => {
	const emptyNameString = !!name && name.trim().length === 0;

	return (
		<Stack spacing={2}>
			<Typography sx={sectionTitleSx}>Name</Typography>

			<TextField
				onChange={(e) => onChange(e.target.value)}
				size="small"
				value={name}
				variant="outlined"
				error={emptyNameString}
				helperText={emptyNameString && "Name can not be empty"}
				fullWidth
			/>
		</Stack>
	);
};
