import { useCallback, useEffect } from "react";
import { useSnackbarStore } from "stores/snackbarStore";
import {
	ResponseWithErrors,
	getNormalizedErrorMessage,
} from "./getNormalizedErrorMessage";
import logErrors from "./logErrors";

const useSnackbar = () => {
	const { setSnackMessage, setSnackOpen, setSnackType } = useSnackbarStore();

	const handleSuccess = useCallback(
		(message: string) => {
			setSnackMessage(message);
			setSnackType("success");
			setSnackOpen(true);
		},
		[setSnackMessage, setSnackOpen, setSnackType]
	);

	const handleInfo = useCallback(
		(message: string) => {
			setSnackMessage(message);
			setSnackType("info");
			setSnackOpen(true);
		},
		[setSnackMessage, setSnackOpen, setSnackType]
	);

	/**
	 * handles an error by adding a toast and logging it to papertrail
	 *
	 * @param err the error that was thrown (is always of an unknown shape, but we handle the normal shapes here -axios and gql)
	 * @param message (optional message that is always be used in toast if it's passed)
	 */
	const handleError = useCallback(
		(error: unknown, message?: string) => {
			const unauthenticated = (
				error as ResponseWithErrors
			)?.response?.errors?.reduce(isUnauthenticated, false);

			const axiosCancelled =
				(error as { code: string })?.code === "ERR_CANCELED" &&
				(error as { name: string })?.name === "CanceledError";
			const graphqlCancelled =
				(error as { message: string })?.message === "Aborted" ||
				(error as { type: string })?.type === "aborted";
			const cancelled = axiosCancelled || graphqlCancelled;

			if (unauthenticated) {
				/** downgrade unauthenticated to a console info */
				console.info("Not authenticated.");
			} else if (cancelled) {
				/** downgrade cancelled requests to a console info */
				console.info("Action cancelled");
			} else {
				/** handle all other answers with a toast and logging the error to papertrail */
				console.error(error);

				/** finds an error message in all the possible ways a message could come back */

				const normalizedErrorMessage =
					message ?? getNormalizedErrorMessage(error);

				if (!normalizedErrorMessage) {
					logErrors(error, "No Message pulled from error.");
				}

				setSnackMessage(normalizedErrorMessage);
				setSnackOpen(true);
				setSnackType("error");
			}
		},
		[setSnackMessage, setSnackOpen, setSnackType]
	);

	return { handleError, handleInfo, handleSuccess };
};

export default useSnackbar;

/** custom hook to be used in router so there's listeners to notify the user when they're offline */
export const useOfflineSnackbar = () => {
	const { setSnackDuration, setSnackMessage, setSnackOpen, setSnackType } =
		useSnackbarStore();

	useEffect(() => {
		const updateStatus = () => {
			if (navigator.onLine) {
				setSnackMessage("Your internet connection is back online.");
				setSnackType("success");
				setSnackDuration(6000);
				setSnackOpen(true);
			} else {
				setSnackMessage("You have lost your internet connection.");
				setSnackType("error");
				setSnackDuration(null);
				setSnackOpen(true);
			}
		};

		window.addEventListener("online", updateStatus);
		window.addEventListener("offline", updateStatus);

		return () => {
			window.removeEventListener("online", updateStatus);
			window.removeEventListener("offline", updateStatus);
		};
	}, [setSnackDuration, setSnackMessage, setSnackOpen, setSnackType]);
};

const isUnauthenticated = (acc: boolean, cur: { message: string }) => {
	if (cur?.message === "unauthenticated") return true;
	else return acc;
};
