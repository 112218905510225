import useSignout from "api/mutations/useSignout";
import { useNavigate } from "react-router-dom";
import useRequiresSignin from "utils/useRequiresSignin";
import useSnackbar from "utils/useSnackbar";

const useController = () => {
	useRequiresSignin();
	const { mutateAsync: signout } = useSignout();
	const navigate = useNavigate();
	const { handleError } = useSnackbar();

	const handleSignout = () => {
		signout()
			.then((variables) => {
				if (variables.success) {
					navigate("/signin");
				} else if (variables.error) {
					handleError(variables.error);
				}
			})
			.catch((error) => handleError(error));
	};

	return { handleSignout };
};

export default useController;
