import { ErrorBoundary as RollbarErrorBoundary } from "@rollbar/react";
import { ErrorComponent } from "./ErrorComponent";

export function withErrorBoundary<T extends {}>(
	Component: React.ComponentType<T>
) {
	const WrappedComponent = (wrappedProps: T) => {
		return (
			<RollbarErrorBoundary fallbackUI={ErrorComponent}>
				<Component {...(wrappedProps as T)} />
			</RollbarErrorBoundary>
		);
	};
	return WrappedComponent;
}
