import theme from "theme";

export const streamIdPlaceholder = "new";

// https://github.com/SAMdesk/tweet-event-service/blob/main/service/src/common/constants.ts#L312
export const earliestPossibleQueryDate = "2020-09-25";

export const eventTypes = Object.keys(theme.palette.eventType);

// https://github.com/SAMdesk/tweet-event-service/blob/main/service/src/common/constants.ts#L85
// TODO: https://samdesk.atlassian.net/browse/DEV-5962
export const orderedLocationTypes = [
	"place",
	"neighbourhood",
	"sublocality_5",
	"sublocality_4",
	"sublocality_3",
	"sublocality_2",
	"sublocality_1",
	"locality",
	"admin_5",
	"admin_4",
	"admin_3",
	"admin_2", // counties
	"admin_1", // provinces/states
	"country", // 'country'
];
