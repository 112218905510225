import { Button, Container, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import theme from "theme";
import { Link } from "ui-library/Link";
import { withErrorBoundary } from "utils/ErrorBoundary";

import useController from "./useController";

const Index = () => {
	const { handleSignout } = useController();
	const navigate = useNavigate();

	return (
		<Container component="main" disableGutters={true} sx={containerSx}>
			<Typography component="h1" sx={titleSx} variant="h2">
				Unauthorized access.
			</Typography>
			<Typography component="h1" sx={textSx} variant="h5">
				Sorry! You are not authorized to access this page. Check the URL and try
				again. If the problem persists, please{" "}
				<Link href="mailto:support@samdesk.io" sx={mailtoSx}>
					contact support
				</Link>
				.
			</Typography>
			<Button
				onClick={() => navigate("/choose_account")}
				sx={buttonSx}
				variant="contained"
			>
				Choose Account
			</Button>
			<Button onClick={handleSignout} sx={buttonSx} variant="contained">
				Sign Out
			</Button>
		</Container>
	);
};

export default withErrorBoundary(Index);

const containerSx = {
	alignItems: "center",
	display: "flex",
	flexDirection: "column",
	height: "100vh",
	justifyContent: "center",
	width: "100vh",
};

const buttonSx = {
	marginBottom: "40px",
};

const mailtoSx = {
	color: theme.palette.primary.main,
	textDecoration: "inherit",
};

const textSx = {
	color: "text.secondary",
	margin: " 0 40px 40px",
	textAlign: "center",
};

const titleSx = {
	color: "text.secondary",
	fontWeight: 600,
	margin: "40px",
};
