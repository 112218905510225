import { Box, Button, TextField, Typography } from "@mui/material";
import { withErrorBoundary } from "utils/ErrorBoundary";

import useController from "./useController";

const Index = () => {
	const { code, setCode, submitMFACode } = useController();

	return (
		<>
			<Typography component="h1" sx={titleSx} variant="h5">
				Two-Factor Authentication
			</Typography>

			<Typography component="p" sx={subtitleSx} variant="body2">
				We have sent a Two-Factor Authentication code to your user email. Enter
				the code below continue.
			</Typography>

			<Typography component="p" sx={subtitleSx} variant="body2">
				If you did not see the email, please check your spam folder or contact
				your account admin.
			</Typography>

			<Box component="form" sx={formSx}>
				<TextField
					onKeyDown={(e) => {
						if (e.key === "Enter") {
							submitMFACode({ params: { code } });
						}
					}}
					autoComplete="one-time-code"
					fullWidth
					label="Code"
					margin="normal"
					onChange={(e) => setCode(e.target.value)}
					type="text"
					value={code}
					variant="outlined"
				/>
				<Button
					fullWidth
					onClick={() => submitMFACode({ params: { code } })}
					sx={buttonSx}
					variant="contained"
				>
					SIGN IN
				</Button>
			</Box>
		</>
	);
};

export default withErrorBoundary(Index);

/** Style */

const buttonSx = {
	margin: "0.5rem 0",
};

const formSx = {
	width: "100%",
};

const subtitleSx = {
	color: "text.secondary",
	margin: "0.5rem 0",
	textAlign: "left",
};

const titleSx = {
	margin: "0.5rem 0",
};
