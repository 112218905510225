import { Button, Typography } from "@mui/material";
import SearchIcon from "icons/search.svg";
import theme from "theme";

import useControllerSearch from "./useControllerSearch";

const Search = () => {
	const { handleOnClick } = useControllerSearch();

	return (
		<Button
			onClick={handleOnClick}
			startIcon={<SearchIcon />}
			sx={searchButtonSx}
		>
			<Typography noWrap>Search</Typography>
		</Button>
	);
};

export default Search;

const searchButtonSx = {
	":hover": {
		backgroundColor: "unset",
		color: "text.primary",
		path: {
			fill: theme.palette.text.primary,
		},
	},
	color: "text.secondary",
	path: {
		fill: theme.palette.text.secondary,
		transition: theme.transitions.create(["fill"], {
			duration: theme.transitions.duration.standard,
		}),
	},
};
