import useListIncidentTypes from "api/queries/useListIncidentTypes";

/** custom hook that returns a function to match and alert with it's type data */
const useDecorateEventType = () => {
	const { data: incidentTypes, isInitialLoading } = useListIncidentTypes();

	/**
	 * Used to go from the string version of an incident type,
	 * to the full on object version of the incident type
	 *
	 * @param type a string representing the incident's type
	 * @returns an object containing the parent category of that type
	 * return object contains:
	 *     - category
	 *     - name
	 *     - type
	 */
	const decorateEventType = (type: string) => {
		if (isInitialLoading) return;
		const incidentType = incidentTypes?.find(
			(event_type) => event_type?.type === type
		);

		return incidentType;
	};

	return decorateEventType;
};

export default useDecorateEventType;
