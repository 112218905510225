import { Tab } from "autoGenSubTypes";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

const useLastActiveTime = (tab: Tab) => {
	const { tabKey } = useParams();

	const [lastActiveTime, setLastActiveTime] = useState(Date.now);
	const [isSelectedTab, setIsSelectedTab] = useState(tab.key === tabKey);

	/**
	 * toggles state to be isSelected or not (so that we know when it changes to be deselected)
	 * we need to distinguish between a changes in tabs in general and a change of tab that deselects this tab
	 */
	useEffect(() => {
		if (tab.key === tabKey) setIsSelectedTab(true);
		else setIsSelectedTab(false);
	}, [tab.key, tabKey]);

	/**
	 * when a tab is deselected (a different tab is selected and this was the previously selected tab)
	 * then we set this as the new last active date
	 */
	useEffect(() => {
		if (!isSelectedTab) setLastActiveTime(Date.now);
	}, [isSelectedTab]);

	return { lastActiveTime };
};

export default useLastActiveTime;
