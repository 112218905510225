import useCreateDashboardTab from "api/mutations/useCreateDashboardTab";
import { DashboardTabType } from "gql/graphql";
import { useHotkeys } from "react-hotkeys-hook";
import { useLocation } from "react-router-dom";
import { useSearchStore } from "stores/searchStore";
import useAccountSettings from "utils/useAccountSettings";
import useLogToMixPanel from "utils/useLogToMixpanel";
import useNavigateWithAccount from "utils/useNavigateWithAccount";
import useSnackbar from "utils/useSnackbar";

const useHotKeys = (handleCloseMenu: () => void) => {
	const { modalOpen, setModalOpen } = useSearchStore();

	const { hasSamalyticsTab } = useAccountSettings();

	const { mutateAsync: createDashboardTab } = useCreateDashboardTab();

	const navigate = useNavigateWithAccount();
	const { logToMixpanel } = useLogToMixPanel();
	const { handleError } = useSnackbar();
	const currentURL = useLocation();

	/** Nav Hotkeys */
	/** streams hotkey */
	useHotkeys(
		"shift+alt+s",
		() => {
			setModalOpen(false);
			handleCloseMenu();
			const alreadyAtStreams = currentURL.pathname.endsWith("/streams");
			if (!alreadyAtStreams) {
				navigate(`streams`);
				logToMixpanel("stream_manager_tab_open", { source: "hotkey" });
			}
		},
		[currentURL, logToMixpanel, navigate, setModalOpen]
	);

	/** search hotkey */
	useHotkeys(
		"shift+alt+k",
		() => {
			handleCloseMenu();
			if (!modalOpen) {
				setModalOpen(true);
				logToMixpanel("search_incident_open", { source: "hotkey" });
			}
		},
		[logToMixpanel, modalOpen, setModalOpen]
	);

	/** analytics hotkey */
	useHotkeys(
		"shift+alt+a",
		() => {
			if (hasSamalyticsTab) {
				setModalOpen(false);
				handleCloseMenu();
				const alreadyAtAnalytics = currentURL.pathname.includes("/analytics");
				if (!alreadyAtAnalytics) {
					navigate(`analytics`);
					logToMixpanel("analytics_tab_open", { source: "hotkey" });
				}
			}
		},
		[currentURL, hasSamalyticsTab, logToMixpanel, navigate, setModalOpen]
	);

	/** new tab hotkey */
	useHotkeys(
		"shift+alt+t",
		() => {
			const newAlertsTab = {
				alerts_tab_data: {
					bottom: [],
					name: "New Alerts Tab",
					top: [],
				},
				type: DashboardTabType.ALERTS,
			};

			setModalOpen(false);
			handleCloseMenu();
			createDashboardTab({ params: { tab: newAlertsTab } })
				.then((variables) => {
					if (variables.success && variables.tab) {
						navigate(`alerts/${variables.tab.key}`);
					}
				})
				.catch((error) => handleError(error));
		},
		[createDashboardTab, handleError, navigate, setModalOpen]
	);
};

export default useHotKeys;
