import { Button, Stack, SxProps } from "@mui/material";
import { useUpdatePerson } from "api/mutations/useUpdatePerson";
import { UpdatePersonParams, personFieldsFragment } from "gql/graphql";
import { useState } from "react";
import { SectionCard } from "routes/settings/style";
import { useActiveAccount } from "utils/useActiveAccount";
import usePasswordInputState from "utils/usePasswordInputState";
import useSnackbar from "utils/useSnackbar";
import { useTimezone } from "utils/useTimezone";
import { ChangeName } from "./ChangeName";
import { ChangePassword } from "./ChangePassword";
import { ChangeTimezone } from "./ChangeTimezone";
import { sectionWrapperSx } from "./common.styles";

export const UpdateProfile = ({ person }: { person: personFieldsFragment }) => {
	const { account } = useActiveAccount();
	const { mutateAsync: updatePerson } = useUpdatePerson();
	const { handleError, handleSuccess } = useSnackbar();

	const [oldPassword, setOldPassword] = useState("");
	const passwordPolicy = account?.password_policy;
	const [
		newPassword,
		setNewPassword,
		newPasswordError,
		passwordValidationDetails,
	] = usePasswordInputState(passwordPolicy);
	const [confirmPassword, setConfirmPassword, confirmPasswordError] =
		usePasswordInputState(undefined, newPassword);

	const [name, setName] = useState(person.name);

	const [timezone, setTimezone] = useState<string>(useTimezone());

	const handleUpdateProfile = () => {
		const params: UpdatePersonParams = {};

		if (oldPassword && newPassword && confirmPassword) {
			params.password_data = {
				new_password: newPassword,
				old_password: oldPassword,
			};
		}

		if (name && name !== person.name && name.trim().length > 0) {
			params.name = name;
		}

		if (timezone && timezone !== person.timezone) {
			params.timezone = timezone;
		}

		if (params.password_data || params.name || params.timezone) {
			updatePerson(params)
				.then((variables) => {
					if (variables.success) {
						handleSuccess("Profile Updated");
						setOldPassword("");
						setNewPassword("");
						setConfirmPassword("");
					} else if (variables.error) {
						handleError(variables.error);
					}
				})
				.catch((error) => handleError(error));
		}
	};

	return (
		<SectionCard variant="outlined">
			<Stack spacing={3} sx={sectionWrapperSx}>
				<ChangeName name={name} onChange={setName} />

				<ChangePassword
					confirmPassword={confirmPassword}
					confirmPasswordError={confirmPasswordError}
					newPassword={newPassword}
					newPasswordError={newPasswordError}
					passwordPolicy={passwordPolicy}
					passwordValidationDetails={passwordValidationDetails}
					setConfirmPassword={setConfirmPassword}
					setNewPassword={setNewPassword}
					oldPassword={oldPassword}
					setOldPassword={setOldPassword}
				/>

				<ChangeTimezone timezone={timezone} onChange={setTimezone} />

				<Button
					variant="contained"
					sx={buttonSx}
					disabled={!!newPasswordError || !!confirmPasswordError}
					onClick={handleUpdateProfile}
				>
					Update Profile
				</Button>
			</Stack>
		</SectionCard>
	);
};

const buttonSx: SxProps = {
	width: "fit-content",
};
