import { useCallback } from "react";
import { NavigateOptions, useNavigate, useParams } from "react-router-dom";

const useNavigateWithAccount = () => {
	const { userAccountIndex } = useParams();
	const navigateReactRouter = useNavigate();

	const prependAccount = useCallback(
		(url: string) => {
			/** remove leading '/' if passed in as url */
			const urlRegex = new RegExp(/^\//);
			const cleanUrl = url.replace(urlRegex, "");

			if (userAccountIndex) {
				/** return the url maintaining the user account (u/#) */
				return `/u/${userAccountIndex}/${cleanUrl}`;
			} else {
				/** return the url as absolute path */
				return `/${cleanUrl}`;
			}
		},
		[userAccountIndex]
	);

	/**
	 * a wrapper on the react router navigate function that:
	 * 1. maintains the /u/# in the url
	 * 2. forces a / infront of the url for absolute routing
	 *
	 * @param url the url to navigate to (accepts leading / or no leading / - they're both forced to have a leading /)
	 * @param opts the options available for react router's navigate function
	 */
	const navigate = useCallback(
		(url: string, opts?: NavigateOptions) => {
			const userUrl = prependAccount(url);
			navigateReactRouter(userUrl, { ...opts });
		},
		[navigateReactRouter, prependAccount]
	);

	return navigate;
};

export default useNavigateWithAccount;
