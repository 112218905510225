import { ExpandLess, ExpandMore } from "@mui/icons-material";
import {
	Checkbox,
	Collapse,
	FormControlLabel,
	Stack,
	SxProps,
	TextField,
	Typography,
} from "@mui/material";
import { AccountPasswordPolicy } from "gql/graphql";
import { Dispatch, useState } from "react";
import { PasswordCriteriaList } from "ui-library/PasswordCriteria";
import { sectionTitleSx } from "./common.styles";

interface Props {
	oldPassword: string;
	confirmPassword: string;
	confirmPasswordError: string;
	newPassword: string;
	newPasswordError: string;
	passwordPolicy: AccountPasswordPolicy | undefined;
	passwordValidationDetails: Record<string, boolean> | null;
	setConfirmPassword: Dispatch<string>;
	setNewPassword: Dispatch<string>;
	setOldPassword: Dispatch<string>;
}

export const ChangePassword = ({
	oldPassword,
	confirmPassword,
	confirmPasswordError,
	newPassword,
	newPasswordError,
	passwordPolicy,
	passwordValidationDetails,
	setConfirmPassword,
	setNewPassword,
	setOldPassword,
}: Props) => {
	const [showPassword, setShowPassword] = useState(false);

	const [showCriteria, setShowCriteria] = useState(false);

	return (
		<Stack spacing={2}>
			<Typography sx={sectionTitleSx}>Password</Typography>

			<Stack
				direction="row"
				spacing={1.5}
				onClick={() => setShowCriteria(!showCriteria)}
				sx={passwordCriteriaSx}
			>
				<Typography>Password Criteria</Typography>
				{showCriteria ?
					<ExpandLess />
				:	<ExpandMore />}
			</Stack>
			<Collapse in={showCriteria} timeout="auto" unmountOnExit>
				<PasswordCriteriaList
					newPassword={newPassword}
					passwordPolicy={passwordPolicy}
					passwordValidationDetails={passwordValidationDetails}
				/>
			</Collapse>

			<Stack spacing={2}>
				<TextField
					autoComplete="password"
					fullWidth
					label="Old Password"
					onChange={(e) => setOldPassword(e.target.value)}
					type={showPassword ? "text" : "password"}
					value={oldPassword}
					variant="outlined"
					size="small"
				/>

				<TextField
					autoComplete="password"
					error={!!newPasswordError}
					fullWidth
					helperText={newPasswordError}
					label="Password"
					onChange={(e) => setNewPassword(e.target.value)}
					type={showPassword ? "text" : "password"}
					value={newPassword}
					variant="outlined"
					size="small"
				/>

				<TextField
					error={!!confirmPasswordError}
					fullWidth
					helperText={confirmPasswordError}
					label="Confirm Password"
					onChange={(e) => setConfirmPassword(e.target.value)}
					type={showPassword ? "text" : "password"}
					value={confirmPassword}
					variant="outlined"
					size="small"
				/>
			</Stack>

			<FormControlLabel
				control={
					<Checkbox
						onChange={() => setShowPassword((prev) => !prev)}
						value={showPassword}
						size="small"
					/>
				}
				label="Show passwords"
				componentsProps={{
					typography: {
						sx: showPasswordSx,
					},
				}}
			/>
		</Stack>
	);
};

const passwordCriteriaSx: SxProps = {
	color: "text.secondary",

	"&:hover": {
		cursor: "pointer",
	},
};

const showPasswordSx: SxProps = {
	color: "text.secondary",
	fontSize: "0.875rem",
};
