import constants from "common/constants.json";

export const rollbarConfig = {
	accessToken: window.SAM._env_.ROLLBAR_TOKEN,
	captureUncaught: true,
	captureUnhandledRejections: true,
	environment: window.SAM._env_.REACT_APP_ENVIRONMENT,
	payload: {
		client: {
			javascript: {
				code_version: constants.version,
				source_map_enabled: true,
				guess_uncaught_frames: true,
			},
		},
	},
};
