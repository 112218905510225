import useGetDashboard from "api/queries/useGetDashboard";
import { SubSource } from "types/common";
import useNavigateWithAccount from "./useNavigateWithAccount";
import { DashboardTabType } from "gql/graphql";
import { useSearchStore } from "stores/searchStore";
import useCreateDashboardTab from "api/mutations/useCreateDashboardTab";
import useLogIncidentInteraction from "api/mutations/useLogIncidentInteraction";
import useSnackbar from "utils/useSnackbar";
import { useCallback } from "react";
import { useIncidentViewStore } from "stores/IncidentViewStore";

/**
 *  The following options are available for opening an incident. Intents are as follows:
 *   auto - if there is a tab open it, else open a modal
 *   modal - even if a tab is open for the incident open in a modal
 *   tab - open a new tab or existing tab for the incident
 *
 *  NOTE: we use zustand to help ensure there is a single state. Google Maps react components
 *  (ie MapNotes.tsx) also use the store directly as they dont have a React context.
 */
interface OpenIncidentOptions {
	incidentId: string;
	intent?: "auto" | "tab" | "modal";
	subSource: SubSource;
	closeSearch?: boolean;
}

export function useOpenIncident() {
	const { data: dashboard } = useGetDashboard();
	const navigate = useNavigateWithAccount();
	const { setModalOpen: setSearchModalOpen } = useSearchStore();
	const { modalState, setModalState } = useIncidentViewStore();
	const { mutateAsync: createDashboardTab } = useCreateDashboardTab();
	const { mutateAsync: logIncidentInteraction } = useLogIncidentInteraction();
	const { handleError } = useSnackbar();
	const closeIncidentModal = () => {
		setModalState({ open: false });
	};

	const isOpenInTab = useCallback(
		(incidentId: string) => {
			return dashboard?.tabs?.some(
				(tab) =>
					tab.__typename === "IncidentTab" &&
					tab.incident_tab_data.incident_id === incidentId
			);
		},
		[dashboard?.tabs]
	);

	const openModal = useCallback(
		(incidentId: string, subSource: SubSource) => {
			setModalState({ open: true, incidentId, subSource });
		},
		[setModalState]
	);

	const openTab = useCallback(
		(incidentId: string, subSource: SubSource | undefined) => {
			setSearchModalOpen(false);

			if (isOpenInTab(incidentId)) {
				navigate(`incident/${incidentId}?sub_source=${subSource}`);
			} else {
				createDashboardTab({
					params: {
						tab: {
							incident_tab_data: { incident_id: incidentId },
							type: DashboardTabType.INCIDENT,
						},
					},
				})
					.then((variables) => {
						if (
							variables.success &&
							variables.tab?.__typename === "IncidentTab"
						) {
							logIncidentInteraction({
								incident_id: incidentId,
								interaction_type: "track",
								source: "dashboard",
							});

							navigate(
								`incident/${variables.tab.incident_tab_data.incident_id}?sub_source=${subSource}`
							);
						}
					})
					.catch((error) => handleError(error));
			}
		},
		[
			createDashboardTab,
			handleError,
			isOpenInTab,
			logIncidentInteraction,
			navigate,
			setSearchModalOpen,
		]
	);

	const openIncident = useCallback(
		({ incidentId, intent = "auto", subSource }: OpenIncidentOptions) => {
			switch (intent) {
				case "modal": {
					openModal(incidentId, subSource);
					break;
				}
				case "tab": {
					openTab(incidentId, subSource);
					break;
				}
				case "auto": {
					if (isOpenInTab(incidentId)) {
						openTab(incidentId, subSource);
					} else {
						openModal(incidentId, subSource);
					}
					break;
				}
			}
		},
		[isOpenInTab, openModal, openTab]
	);

	return {
		openIncident,
		modalState,
		closeIncidentModal,
	};
}
