/**
 * IMPORTANT:
 * normally we don't want to put tab creating mutations or navigation calls in a useEffect
 * because it's too easy to have them run at unintended times
 * for deep linking to legacy routes, this code only runs if there's a hash in the url
 * once it's redirected there is no hash, and we never use hashes, so these will only run once as needed
 */
import useGetDashboard from "api/queries/useGetDashboard";
import { AlertsTab, Tab } from "autoGenSubTypes";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

/**
 * Custom hook to handle all redirects from legacy links
 */
const useLegacyRedirect = () => {
	const navigate = useNavigate();
	const { data: dashboard } = useGetDashboard();

	/** Redirect from legacy '#alerts' paths to the same path without the hash and append the hash as the rest of the pathname */
	useEffect(() => {
		const alertsTabs = dashboard?.tabs.filter(isAlertsTab);
		if (!alertsTabs) return;

		const { hash, pathname } = location;
		const rootPath = pathname.slice(-1) === "/" ? pathname : `${pathname}/`;

		if (hash.includes("#alerts")) {
			const hashArr = hash.split("/").filter(Boolean);
			/** Assumes that the second position in the hash array will always be the incidentId */
			const containsIncidentId = hashArr[0] === "#alerts" && hashArr[1];
			const notificationsUrl =
				hashArr[1] === "tab" &&
				hashArr[3] === "edit" &&
				hashArr[4] === "notifications";
			const feedback = hashArr[2] === "feedback";

			if (notificationsUrl) {
				/** legacy deep link to change notifications for a stream */
				navigate(`${rootPath}streams/${hashArr[2]}?notifications=true`, {
					replace: true,
				});
			} else if (feedback) {
				/** Legacy incident tab/modal -> incident tab with feedback param */
				navigate(
					`${rootPath}${hash
						.replace("#alerts", "incident")
						.replace("/feedback", "?feedback=true")}`,
					{ replace: true }
				);
			} else if (containsIncidentId) {
				/** Legacy incident tab/modal -> incident tab */
				navigate(`${rootPath}${hash.replace("#alerts", "incident")}`, {
					replace: true,
				});
			} else if (!containsIncidentId && alertsTabs.length > 0) {
				/** Legacy #alerts tab, at least one AlertsTab available --> first AlertsTab in array */
				navigate(`${rootPath}alerts/${alertsTabs[0].key}`, { replace: true });
			} else if (!containsIncidentId && alertsTabs.length === 0) {
				/** Legacy #alerts tab, no AlertsTabs available --> root route (useManageTabs handles any further necessary routing on root) */
				navigate(rootPath, { replace: true });
			}
		}
	}, [dashboard, navigate]);

	/** Legacy settings redirect. match when possible, else send them to the new /settings page */
	useEffect(() => {
		const { hash, pathname } = location;

		if (pathname.includes("settings")) {
			if (hash.includes("profile") || hash.includes("privacy")) {
				navigate(`${pathname.replace(/\/$/, "")}/profile`, { replace: true });
			} else if (hash.includes("account-settings")) {
				navigate(`${pathname.replace(/\/$/, "")}/account/security`, {
					replace: true,
				});
			} else if (
				hash.includes("members") ||
				hash.includes("assets") ||
				hash.includes("api")
			) {
				navigate(
					`${pathname.replace(/\/$/, "")}/account${hash.replace("#", "/")}`,
					{ replace: true }
				);
			} else if (hash.includes("everbridge")) {
				navigate(
					`${pathname.replace(/\/$/, "")}/account/integrations/everbridge`,
					{ replace: true }
				);
			} else if (hash) {
				navigate(pathname, { replace: true });
			}
		}
	}, [navigate]);
};

export default useLegacyRedirect;

const isAlertsTab = (tab: Tab): tab is AlertsTab =>
	tab.__typename === "AlertsTab";
