import useListAlerts from "api/queries/useListAlerts";
import { Tab } from "autoGenSubTypes";
import { getCombinedAlerts, getFlatAlerts } from "utils/alertUtils";

import useLastActiveTime from "./useLastActiveTime";

/**
 * A custom hook for getting a count of how many alerts have come in since you were last on the tab.
 *
 * It's doing so in a way that fully queries and populates caches for all tabs (both panes).
 * The upside to doing it this way is that the data is pre-loaded when you go to the tab.
 * The downside is that it's a lot of traffic and isn't very performant or scalable.
 *
 * @param tab the related tab data for this tab
 * @returns the number of alerts that came in since the tab was last active destructured as { missedAlertsCount }
 */
const useMissedAlertsCount = (tab: Tab) => {
	const { lastActiveTime } = useLastActiveTime(tab);

	const alertsTabData = tab?.__typename === "AlertsTab" && tab?.alerts_tab_data;

	/** query for alerts in top pane */
	const streamIdsTop =
		alertsTabData ?
			alertsTabData.top.map((tab) => tab.stream_id).filter(Boolean)
		:	[];
	const alertsParamsTop = {
		backfill: true,
		before: new Date().toISOString(),
		count: 100,
		stream_ids: streamIdsTop,
	};
	const { data: alertsTop } = useListAlerts(alertsParamsTop);

	/** query for alerts in bottom pane */
	const streamIdsBottom =
		alertsTabData ?
			alertsTabData.bottom.map((tab) => tab.stream_id).filter(Boolean)
		:	[];
	const alertsParamsBottom = {
		backfill: true,
		before: new Date().toISOString(),
		count: 100,
		stream_ids: streamIdsBottom,
	};
	const { data: alertsBottom } = useListAlerts(alertsParamsBottom);

	/** get a count of all the alerts that are created after the lastActiveTime */
	const allAlerts = getCombinedAlerts(alertsTop, alertsBottom);
	const totalMissedAlerts = allAlerts.filter(
		(alert) =>
			"created" in alert && new Date(alert.created).getTime() > lastActiveTime
	).length;

	const topAlerts = getFlatAlerts(alertsTop);
	const topMissedAlerts = topAlerts.filter(
		(alert) =>
			"created" in alert && new Date(alert.created).getTime() > lastActiveTime
	).length;

	const bottomAlerts = getFlatAlerts(alertsBottom);
	const bottomMissedAlerts = bottomAlerts.filter(
		(alert) =>
			"created" in alert && new Date(alert.created).getTime() > lastActiveTime
	).length;

	return { bottomMissedAlerts, topMissedAlerts, totalMissedAlerts };
};

export default useMissedAlertsCount;
