/**
 * IMPORTANT:
 * normally we don't want to put tab creating mutations or navigation calls in a useEffect
 * because it's too easy to have them run at unintended times
 * for deep linking we need to wait for initial getDashboard query data to run before creating tabs or navigating
 * so this is a special case where we're tightly controlling that each use effect only runs once
 * and only after the necessary data is loaded
 * it's a similar situation for a deep linked incident tab
 */
import useCreateDashboardTab from "api/mutations/useCreateDashboardTab";
import useGetDashboard from "api/queries/useGetDashboard";
import useGetIncident from "api/queries/useGetIncident";
import { Tab } from "autoGenSubTypes";
import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { tabTypeNameIndex } from "utils/tabUtils";
import useCurrentAlertsTab from "utils/useCurrentAlertsTab";
import useSnackbar from "utils/useSnackbar";

import useNavigateToExistingTab from "./useNavigateToExistingTab";

const useManageDeepLinkedTabs = () => {
	const { mutateAsync: createDashboardTab } = useCreateDashboardTab();

	const { pathname } = useLocation();
	const { handleError } = useSnackbar();
	const { navigateToExistingTab } = useNavigateToExistingTab();

	const { incidentId, tabKey } = useParams();
	const { currentAlertsTab } = useCurrentAlertsTab(tabKey);

	/** START: logic to know when we have data and only run useEffects once */
	const [initialDashboardLoadSuccessful, setInitialDashboardLoadSuccessful] =
		useState<boolean>();
	const [initialIncidentLoadSuccessful, setInitialIncidentLoadSuccessful] =
		useState<boolean>();

	const {
		data: incident,
		status: statusIncident,
		isLoading: isLoadingIncident,
	} = useGetIncident({ _id: incidentId || "", follow_merges: true });
	const { data: dashboard, status: statusDashboard } = useGetDashboard();

	useEffect(() => {
		if (
			initialDashboardLoadSuccessful === undefined &&
			statusDashboard === "success"
		) {
			setInitialDashboardLoadSuccessful(true);
		}
	}, [
		statusDashboard,
		initialDashboardLoadSuccessful,
		setInitialDashboardLoadSuccessful,
	]);

	useEffect(() => {
		if (initialIncidentLoadSuccessful === undefined) {
			if (statusIncident === "success") setInitialIncidentLoadSuccessful(true);
			else if (statusIncident === "error") {
				setInitialIncidentLoadSuccessful(false);
			}
		}
	}, [initialIncidentLoadSuccessful, statusIncident]);
	/** END: logic to know when we have data and only run useEffects once */

	/** START: handling deep linked routes */
	/** deep linked to an incident tab */
	useEffect(() => {
		if (
			initialDashboardLoadSuccessful &&
			pathname.includes("/incident/") &&
			incidentId
		) {
			const incidentTabExists = dashboard?.tabs.reduce(
				(acc: boolean, cur: Tab) => {
					if (
						cur.__typename === "IncidentTab" &&
						cur.incident_tab_data.incident_id === incidentId
					) {
						return true;
					} else return acc;
				},
				false
			);

			if (initialIncidentLoadSuccessful && incident && !incidentTabExists) {
				/** incident exists, but we don't have a tab for it, so make one */
				createDashboardTab({
					params: {
						tab: {
							incident_tab_data: { incident_id: incidentId },
							type: tabTypeNameIndex["IncidentTab"],
						},
					},
				}).catch((error) => handleError(error));
			} else if (
				initialIncidentLoadSuccessful === false ||
				(!isLoadingIncident && !incident)
			) {
				/** incident doesn't exist so send user back to another tab, or to the home '/' route and show an error */
				navigateToExistingTab();
				handleError("This incident doesn't exist.");
			}
		}
		/**
		 * correctly ignored:
		 * we don't want this to run more than once
		 * but we need one thing in the array to make sure data is loaded
		 */
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [initialDashboardLoadSuccessful, initialIncidentLoadSuccessful]);

	/** deep linked to alerts tab */
	useEffect(() => {
		if (initialDashboardLoadSuccessful) {
			if (!currentAlertsTab && tabKey) {
				/** alerts tab doesn't exist so send user back to another tab, or to the home '/' route and show an error */
				navigateToExistingTab();
				handleError("The alerts tab that you tried to go to doesn't exist.");
			}
		}
		/**
		 * correctly ignored:
		 * we don't want this to run more than once
		 * but we need one thing in the array to make sure data is loaded
		 */
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [initialDashboardLoadSuccessful]);
	/** END: handling deep linked routes */
};

export default useManageDeepLinkedTabs;
