import { ScheduleSendRounded } from "@mui/icons-material";
import {
	Box,
	Button,
	Divider,
	Menu as MUIMenu,
	MenuItem,
	Typography,
} from "@mui/material";
import { Icon } from "@samdesk/components/Icon";
import { Modal } from "@samdesk/components/Modal";
import useCreateDashboardTab from "api/mutations/useCreateDashboardTab";
import useSignout from "api/mutations/useSignout";
import { useGetPerson } from "api/queries/useGetPerson";
import { DashboardTabType } from "gql/graphql";
import AddTabIcon from "icons/addTab.svg";
import AnalyticsIcon from "icons/analytics.svg";
import HamburgerIcon from "icons/hamburger.svg";
import HelpIcon from "icons/help.svg";
import LogoutIcon from "icons/logout.svg";
import SearchIcon from "icons/search.svg";
import SettingsIcon from "icons/settings.svg";
import StreamsIcon from "icons/streams.svg";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useIntercom } from "react-use-intercom";
import { useIntercomStore } from "stores/intercomStore";
import { useSearchStore } from "stores/searchStore";
import theme from "theme";
import { HasFeature } from "ui-library/HasFeature";
import { IncidentResponseHistory } from "ui-library/IncidentResponseHistory";
import useAccountSettings from "utils/useAccountSettings";
import useLogToMixPanel from "utils/useLogToMixpanel";
import useNavigateWithAccount from "utils/useNavigateWithAccount";
import useSnackbar from "utils/useSnackbar";
import MenuAccountOptions from "./MenuAccountOptions";
import useHotKeys from "./useHotkeys";

const Menu = () => {
	const [primaryAnchorEl, setPrimaryAnchorEl] = useState<HTMLElement | null>(
		null
	);
	const [secondaryAnchorEl, setSecondaryAnchorEl] =
		useState<HTMLElement | null>(null);
	const [incidentResponseModalOpen, setIncidentResponseModalOpen] =
		useState(false);

	const { showIntercom, setShowIntercom } = useIntercomStore();
	const { setModalOpen } = useSearchStore();

	const { hasSamalyticsTab } = useAccountSettings();
	const { logToMixpanel } = useLogToMixPanel();
	const { show, hide } = useIntercom();
	const { handleError } = useSnackbar();
	const navigate = useNavigate();
	const navigateWithAccount = useNavigateWithAccount();

	const { mutateAsync: signout } = useSignout();
	const { mutateAsync: createDashboardTab } = useCreateDashboardTab();

	const { data: person, isError, isInitialLoading, isSuccess } = useGetPerson();

	const handleCloseMenu = () => {
		setPrimaryAnchorEl(null);
		setSecondaryAnchorEl(null);
	};

	useHotKeys(handleCloseMenu);

	const primaryOpen = Boolean(primaryAnchorEl);
	const secondaryOpen = Boolean(secondaryAnchorEl);

	const currentURL = useLocation();

	const handleAnalytics = () => {
		const alreadyAtAnalytics = currentURL.pathname.includes("/analytics");
		if (!alreadyAtAnalytics) {
			navigateWithAccount(`analytics`);
			logToMixpanel("analytics_tab_open", { source: "navbar" });
		}

		handleCloseMenu();
	};

	const handleHelp = () => {
		setShowIntercom(!showIntercom);

		if (!showIntercom) show();
		else hide();

		handleCloseMenu();
	};

	const handleLogOut = () => {
		signout().then((variables) => {
			if (variables.success) {
				navigate("/signin");
			}
		});

		handleCloseMenu();
	};

	const handleNewTab = () => {
		const newAlertsTab = {
			alerts_tab_data: {
				bottom: [],
				name: "New Alerts Tab",
				top: [],
			},
			type: DashboardTabType.ALERTS,
		};

		createDashboardTab({ params: { tab: newAlertsTab } })
			.then((variables) => {
				if (variables.success && variables.tab) {
					navigateWithAccount(`alerts/${variables.tab.key}`);
				}
			})
			.catch((error) => handleError(error))
			.finally(() => handleCloseMenu());
	};

	const handleSearch = () => {
		setModalOpen(true);
		logToMixpanel("search_incident_open", { source: "navbar" });

		handleCloseMenu();
	};

	const handleSettings = () => {
		const alreadyAtBaseSettings = currentURL.pathname.endsWith("/settings");
		if (!alreadyAtBaseSettings) {
			navigateWithAccount(`settings`);
			logToMixpanel("settings_tab_open", { source: "navbar" });
		}

		handleCloseMenu();
	};

	const handleStreams = () => {
		const alreadyAtStreams = currentURL.pathname.endsWith("/streams");
		if (!alreadyAtStreams) {
			navigateWithAccount(`streams`);
			logToMixpanel("stream_manager_tab_open", { source: "navbar" });
		}

		handleCloseMenu();
	};

	const handlePrivateIncidents = () => {
		const alreadyAtPrivateIncidents =
			currentURL.pathname.endsWith("/private_incidents");
		if (!alreadyAtPrivateIncidents) {
			navigateWithAccount(`private_incidents`);
		}

		handleCloseMenu();
	};

	const osIsMac = navigator.userAgent.includes("Mac");
	const shiftAlt = osIsMac ? "SHIFT + OPTION" : "SHIFT + ALT";

	return (
		<>
			<Button
				onClick={(e) => setPrimaryAnchorEl(e.currentTarget)}
				startIcon={<HamburgerIcon />}
				sx={menuButtonSx}
				aria-label="Menu"
			/>

			{/* Primary Menu */}
			<MUIMenu
				anchorEl={primaryAnchorEl}
				onClose={handleCloseMenu}
				open={primaryOpen}
				aria-label="Menu Items"
			>
				<MenuItem onClick={handleStreams} sx={menuItemSx({ hasHotkeys: true })}>
					<Box sx={itemLabelSx}>
						<StreamsIcon />
						<Typography noWrap sx={menuItemTextSx}>
							Streams
						</Typography>
					</Box>
					<Typography noWrap variant="caption1">
						{shiftAlt} + S
					</Typography>
				</MenuItem>

				<MenuItem onClick={handleSearch} sx={menuItemSx({ hasHotkeys: true })}>
					<Box sx={itemLabelSx}>
						<SearchIcon />
						<Typography noWrap sx={menuItemTextSx}>
							Search
						</Typography>
					</Box>
					<Typography noWrap variant="caption1">
						{shiftAlt} + K
					</Typography>
				</MenuItem>

				{hasSamalyticsTab && (
					<MenuItem
						onClick={handleAnalytics}
						sx={menuItemSx({ hasHotkeys: true })}
					>
						<Box sx={itemLabelSx}>
							<AnalyticsIcon />
							<Typography noWrap sx={menuItemTextSx}>
								Analytics
							</Typography>
						</Box>
						<Typography noWrap variant="caption1">
							{shiftAlt} + A
						</Typography>
					</MenuItem>
				)}

				<HasFeature flag="private_incidents">
					<MenuItem
						onClick={handlePrivateIncidents}
						sx={menuItemSx({ hasHotkeys: false })}
					>
						<Box sx={itemLabelSx}>
							<Icon name="privateIncident" />
							<Typography noWrap sx={menuItemTextSx}>
								Create Incident
							</Typography>
						</Box>
					</MenuItem>
				</HasFeature>

				<HasFeature flag="incident_response">
					<MenuItem
						onClick={() => {
							setIncidentResponseModalOpen(true);
							handleCloseMenu();
						}}
						sx={menuItemSx({ hasHotkeys: false })}
					>
						<Box sx={itemLabelSx}>
							<ScheduleSendRounded />
							<Typography noWrap sx={menuItemTextSx}>
								Incident Responses
							</Typography>
						</Box>
					</MenuItem>
				</HasFeature>

				<Divider sx={dividerSx} />

				<MenuItem onClick={handleNewTab} sx={menuItemSx({ hasHotkeys: true })}>
					<Box sx={itemLabelSx}>
						<AddTabIcon />
						<Typography noWrap sx={menuItemTextSx}>
							New Tab
						</Typography>
					</Box>
					<Typography noWrap variant="caption1">
						{shiftAlt} + T
					</Typography>
				</MenuItem>

				<Divider sx={dividerSx} />

				<MenuItem onClick={handleSettings} sx={menuItemSx({})}>
					<SettingsIcon />
					<Typography noWrap sx={menuItemTextSx}>
						Settings
					</Typography>
				</MenuItem>

				<MenuItem onClick={handleHelp} sx={menuItemSx({})}>
					<HelpIcon />
					<Typography noWrap sx={menuItemTextSx}>
						Help
					</Typography>
				</MenuItem>

				<MenuItem onClick={handleLogOut} sx={menuItemSx({})}>
					<LogoutIcon />
					<Typography noWrap sx={menuItemTextSx}>
						Log out
					</Typography>
				</MenuItem>

				<Divider sx={dividerSx} />

				<MenuAccountOptions
					handleCloseMenu={handleCloseMenu}
					isError={isError}
					isInitialLoading={isInitialLoading}
					isSuccess={isSuccess}
					person={person}
					secondaryAnchorEl={secondaryAnchorEl}
					secondaryOpen={secondaryOpen}
					setSecondaryAnchorEl={setSecondaryAnchorEl}
				/>
			</MUIMenu>

			<Modal
				title="Incident Responses"
				open={incidentResponseModalOpen}
				onRequestClose={() => setIncidentResponseModalOpen(false)}
			>
				<IncidentResponseHistory
					onRequestClose={() => setIncidentResponseModalOpen(false)}
				/>
			</Modal>
		</>
	);
};

export default Menu;

/** STYLE */

const dividerSx = {
	borderColor: "border.main",
	margin: "0 !important",
};

const itemLabelSx = {
	display: "flex",
};

const menuButtonSx = {
	":hover": {
		backgroundColor: "unset",
		path: {
			fill: theme.palette.text.primary,
		},
	},
	minWidth: "unset !important",
	paddingLeft: "10px",
	path: {
		fill: theme.palette.text.secondary,
	},
};

interface menuItemSxProps {
	canClickMenuItem?: boolean;
	hasHotkeys?: boolean;
}

export const menuItemSx = ({
	canClickMenuItem = true,
	hasHotkeys = false,
}: menuItemSxProps) => {
	return {
		":hover": {
			backgroundColor: canClickMenuItem ? "primary.main" : "unset",
			color: canClickMenuItem ? "text.primary" : "text.secondary",
			cursor: canClickMenuItem ? "pointer" : "default",
			path: {
				fill:
					canClickMenuItem ?
						theme.palette.text.primary
					:	theme.palette.text.secondary,
			},
		},
		borderRadius: "4px",
		color: "text.secondary",
		height: "60px",
		justifyContent: hasHotkeys ? "space-between" : "start",
		width: "240px",
	};
};

const menuItemTextSx = {
	marginLeft: "5px",
};
