import { PropsWithChildren } from "react";
import { useHasFeature } from "./useHasFeature";
import type { Flags } from "./types";

interface HasFeatureProps {
	flag: Flags;
	not?: boolean;
}

export function HasFeature({
	flag,
	not = false,
	children,
}: PropsWithChildren<HasFeatureProps>) {
	const hasFeatureFlag = useHasFeature(flag);

	if (not) {
		if (!hasFeatureFlag) {
			return <>{children}</>;
		}

		return null;
	}

	if (hasFeatureFlag) {
		return <>{children}</>;
	}

	return null;
}
