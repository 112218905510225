import { Tooltip, Typography } from "@mui/material";
import theme from "theme";
import { EventTypeColors } from "types/common";
import { isEventTypeColor } from "utils/isEventTypeColor";
import useDecorateEventType from "utils/useDecorateEventType";

interface Props {
	eventTypes: string[] | undefined;
}

const IncidentSubLabel = ({ eventTypes }: Props) => {
	const decorateEventType = useDecorateEventType();

	const firstAlertType = decorateEventType(eventTypes?.[0] || "");
	/** Creates new array with truncated event types and re-assigns proper event names to each value */
	const truncatedEvents = () => {
		const formattedTruncatedArray = eventTypes
			?.slice(1)
			.map((type) => decorateEventType(type)?.name);
		return formattedTruncatedArray || [];
	};

	const firstAlertTypeCategory =
		firstAlertType && isEventTypeColor(firstAlertType.category) ?
			firstAlertType.category
		:	("white" as const);

	return (
		<span style={incidentSubLabelStyle}>
			<Typography
				sx={alertTypeNameSx(firstAlertTypeCategory)}
				variant="caption2"
			>
				{firstAlertType?.name}
			</Typography>

			{truncatedEvents().length > 0 && (
				<Typography sx={restOfEventTypesSx} variant="caption2">
					...
					<Tooltip
						arrow={true}
						placement={"right"}
						title={truncatedEvents().join(", ")}
					>
						<Typography
							aria-label="all-events"
							sx={restOfEventTypesSx}
							variant="caption2"
						>
							+ {truncatedEvents().length}
						</Typography>
					</Tooltip>
				</Typography>
			)}
		</span>
	);
};

export default IncidentSubLabel;

/** Styles */

const incidentSubLabelStyle = {
	display: "flex",
};

const alertTypeNameSx = (eventTypeColors: EventTypeColors) => ({
	color: theme.palette.eventType[eventTypeColors],
	textTransform: "capitalize",
});

const restOfEventTypesSx = {
	color: "text.secondary",
	marginLeft: "0.5rem",
};
