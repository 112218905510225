import { Box, Button, Tooltip, Typography } from "@mui/material";
import Breadcrumbs from "routes/settings/Breadcrumbs";
import CenteredCircularProgress from "ui-library/CenteredCircularProgress";
import SettingsPageTitle from "ui-library/SettingsPageTitle";

import CollectionsTable from "./CollectionsTable";
import useController from "./useController";

const Index = () => {
	const {
		account,
		atMaxAssetSets,
		hasProximity,
		isInitialLoading,
		navigate,
		proximityAssetSets,
	} = useController();

	if (isInitialLoading) {
		return <CenteredCircularProgress />;
	}

	/** account doesn't have option enabled, so we want a call to action to pay for it */
	if (!hasProximity) {
		return (
			<>
				<Breadcrumbs />
				<SettingsPageTitle>Proximity Assets</SettingsPageTitle>
				<Box sx={wrapperSx}>
					<Typography sx={informationTextSx}>
						Assets are people, places, and areas of interest. Please contact
						customer support to enable assets on your account.
					</Typography>
				</Box>
			</>
		);
	}

	return (
		<>
			<Breadcrumbs />
			<SettingsPageTitle>Proximity Assets</SettingsPageTitle>
			<Box sx={wrapperSx}>
				<Typography sx={informationTextSx}>
					Assets are people, places, and areas of interest. Here you can upload
					a CSV containing a group of assets you'd like to monitor via samdesk
					alerts.
				</Typography>

				<Tooltip
					disableHoverListener={atMaxAssetSets !== true}
					disableFocusListener={atMaxAssetSets !== true}
					disableTouchListener={atMaxAssetSets !== true}
					title={`You've reached your max number of asset sets (${account?.settings.max_asset_sets}). Please contact customer support to increase your max.`}
				>
					<span>
						<Button
							color="secondary"
							disabled={atMaxAssetSets === true}
							onClick={() => navigate("settings/account/assets/collections")}
							size="large"
							sx={createCollectionButtonSx}
							variant="outlined"
						>
							Create Asset Collection
						</Button>
					</span>
				</Tooltip>
			</Box>
			{proximityAssetSets && (
				<CollectionsTable proximityAssetSets={proximityAssetSets} />
			)}
		</>
	);
};

export default Index;

/** Styles */

const createCollectionButtonSx = {
	maxHeight: "36px",
	minWidth: "fit-content",
};

const informationTextSx = {
	color: "text.secondary",
	marginBottom: "1.5rem",
	width: "70%",
};

const wrapperSx = {
	alignItems: "center",
	display: "flex",
	justifyContent: "space-between",
	marginBottom: "1rem",
};
