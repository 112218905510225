import useGetUser from "api/queries/useGetUser";

const useAccountPermissions = () => {
	const { data: user } = useGetUser();

	const isAdmin = user?.role === "ADMIN";
	const isMember = user?.role === "MEMBER";

	return { isAdmin, isMember };
};

export default useAccountPermissions;
