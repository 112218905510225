import useGetDashboard from "api/queries/useGetDashboard";
import { getTabPath } from "utils/tabUtils";
import useNavigateWithAccount from "utils/useNavigateWithAccount";

const useNavigateToExistingTab = () => {
	const { data: dashboard } = useGetDashboard();

	const navigate = useNavigateWithAccount();

	const navigateToExistingTab = () => {
		if (!dashboard) return;

		if (dashboard.tabs[0]) {
			/** go to first tab if currently expected tab doesn't exist */
			navigate(getTabPath(dashboard.tabs[0]), { replace: true });
		} else {
			/** go back to home (to the no tabs screen) */
			navigate("/", { replace: true });
		}
	};

	return { navigateToExistingTab };
};

export default useNavigateToExistingTab;
