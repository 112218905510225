/**
 * These hooks re-implement the now removed useBlocker and usePrompt hooks in 'react-router-dom'.
 * Source: https://github.com/remix-run/react-router/issues/8139
 */
import type { Blocker, History, Transition } from "history";

import { ContextType, useCallback, useContext, useEffect } from "react";
import {
	Navigator as BaseNavigator,
	UNSAFE_NavigationContext as NavigationContext,
} from "react-router-dom";

interface Navigator extends BaseNavigator {
	block: History["block"];
}

type NavigationContextWithBlock = ContextType<typeof NavigationContext> & {
	navigator: Navigator;
};

/**
 * Blocks all navigation attempts. This is useful for preventing the page from
 * changing until some condition is met, like saving form data.
 *
 * @param  blocker
 * @param  when Determines when alert should be shown
 * @source https://github.com/remix-run/react-router/commit/256cad70d3fd4500b1abcfea66f3ee622fb90874
 */
const useBlocker = (blocker: Blocker, when = true) => {
	const { navigator } = useContext(
		NavigationContext
	) as NavigationContextWithBlock;

	useEffect(() => {
		if (!when) return;

		const unblock = navigator.block((tx: Transition) => {
			const autoUnblockingTx = {
				...tx,
				retry() {
					// Automatically unblock the transition so it can play all the way
					// through before retrying it. TODO: https://samdesk.atlassian.net/browse/DEV-5050 Figure out how to re-enable
					// this block if the transition is cancelled for some reason.
					unblock();
					tx.retry();
				},
			};

			blocker(autoUnblockingTx);
		});

		return unblock;
	}, [navigator, blocker, when]);
};

/**
 * Prompts the user with an Alert before they leave the current screen.
 *
 * @param  message The string to be displayed within the alert
 * @param  when Determines when alert should be shown
 * @source https://github.com/remix-run/react-router/issues/8139#issuecomment-1021457943
 */
const useNavigationPrompt = (
	message:
		| ((
				location: Transition["location"],
				action: Transition["action"]
		  ) => string)
		| string,
	when = true
) => {
	const blocker = useCallback(
		(tx: Transition) => {
			let response;

			if (typeof message === "function") {
				response = message(tx.location, tx.action);
				if (typeof response === "string") {
					response = window.confirm(response);
				}
			} else {
				response = window.confirm(message);
			}

			if (response) tx.retry();
		},
		[message]
	);
	return useBlocker(blocker, when);
};

export default useNavigationPrompt;
